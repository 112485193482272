import 'bootstrap/dist/css/bootstrap.css';

interface Props {
  text: string;
  onClick: () => void;
  disabled?: boolean;
  style?: React.CSSProperties;
  id?: any;
}

const DoneButton = ({ text, onClick, disabled, style }: Props) => {
  return (
    <button
      onClick={onClick}
      type='button'
      className='btn btn-success'
      style={style}
      disabled={disabled ? true : false}
    >
      {' '}
      {text}
    </button>
  );
};

export default DoneButton;
