import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import './QuestionCreate.css';
import { Emoji } from 'emoji-picker-react';
import { addNewEmojiIconLarge, crossIcon } from '../../assets/Icons';
import EmojiPicker from 'emoji-picker-react';

interface Props {
  title: string;
  emoji: string;
  edit: boolean;
  toggleChangedTitle: (title: string) => void;
  toggleChangedEmoji: (title: string) => void;
}

const QuestionCreateTitle = ({
  title,
  emoji,
  edit,
  toggleChangedTitle,
  toggleChangedEmoji,
}: Props) => {
  const [questionTitle, setQuestionTitle] = useState(title);
  const [pickEmoji, setPickEmoji] = useState(false);
  useEffect(() => {
    if (questionTitle.length === 0 && edit === false) {
      setQuestionTitle('Untitled Question');
      toggleChangedTitle('Untitled Question');
    } else {
      toggleChangedTitle(questionTitle);
    }
  }, [edit]);

  //unused but keep this, its for future use of enter
  const enterHandler = (event: any) => {
    if (event.key === 'Enter') {
      toggleChangedTitle(questionTitle);
    }
  };

  return (
    <>
      <Container className={!edit ? 'questTitleDiv' : 'questTitleDivEdit'}>
        {!edit ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginBottom: '20px',
            }}
          >
            <p>{questionTitle}</p>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginLeft: '15px',
              }}
            >
              <Emoji unified={emoji} size={40} />
            </div>
          </div>
        ) : (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginBottom: '20px',
            }}
          >
            <input
              placeholder='Question title'
              type='text'
              value={questionTitle}
              className='questionTitleInput'
              onChange={(e) => setQuestionTitle(e.target.value)}
            />
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <button
                onClick={() => {
                  setPickEmoji(!pickEmoji);
                }}
                className='emojiButton'
              >
                {emoji.length != 0 ? (
                  <Emoji unified={emoji} size={40} />
                ) : (
                  <>{addNewEmojiIconLarge}</>
                )}
              </button>
              {emoji.length != 0 && (
                <button
                  onClick={() => {
                    toggleChangedEmoji('');
                  }}
                  className='crossButton'
                >
                  {crossIcon}
                </button>
              )}
            </div>
            <div className={pickEmoji ? 'd-block' : 'd-none'}>
              <EmojiPicker
                onEmojiClick={(emoji) => {
                  setPickEmoji(false);
                  toggleChangedEmoji(emoji.unified);
                }}
              />{' '}
            </div>
          </div>
        )}
      </Container>
    </>
  );
};

export default QuestionCreateTitle;
