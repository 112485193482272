import React, { useState, useEffect } from 'react';
import './MySurveys.css';
import SurveySection from '../../components/SurveySection/SurveySection';
import { getSurveysByTeamMember } from '../../functions/Created_functions/surveyCreated';
import {
  getSurveysByOwnerCompleted,
  getSurveysByOwnerNotCompleted,
} from '../../functions/Created_functions/createdUtils';
import { useAuth0 } from '@auth0/auth0-react';
import { render } from '@testing-library/react';

function MySurveys() {
  const { isAuthenticated, user, isLoading } = useAuth0();

  const ownerId = user?.sub;

  // const [dataToSend, setDataToSend] = useState([]);
  const [surveysTeam, setSurveysTeam] = useState([]);
  const [completedDataToSend, setCompletedDataToSend] = useState([]);
  const [notCompletedDataToSend, setNotCompletedDataToSend] = useState([]);
  const [renderPage, setRenderPage] = useState<boolean>(false)

  const handleGetCompletedSurveys = () => {
    if (ownerId) {
      getSurveysByOwnerCompleted(ownerId).then((result: any) => {
        setCompletedDataToSend(result);
      });
    }
  };


  const handleGetSurveysTeam = () => {
    if (ownerId) {
      getSurveysByTeamMember(ownerId).then((result: any) => {
        setSurveysTeam(result);
      });
    }
  };

  const handleGetNotCompletedSurveys = () => {
    if (ownerId) {
      getSurveysByOwnerNotCompleted(ownerId).then((result: any) => {
        setNotCompletedDataToSend(result);
      });
    }
  };

  useEffect(() => {
    handleGetNotCompletedSurveys();
    handleGetCompletedSurveys();
  }, [renderPage]);

  useEffect(() => {
    // handleGetSurveys();
    handleGetNotCompletedSurveys();
    handleGetCompletedSurveys();
    handleGetSurveysTeam();
  }, []);


  // const handleGetSurveys = () => {
  //   if(ownerId){
  //   getSurveysByOwner(ownerId).then((result: any) => {
  //     setDataToSend(result);
  //   });}
  // };

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  if (!isAuthenticated || !user) {
    return <div>User is not logged in.</div>;
  }

  return (
    <div className='page'>
      <div className='mysurveys-container'>
        <div className='mysurveys-survey-container'>
          <SurveySection
            showSearchBar={true}
            firstColumn={'Title'}
            secondColumn={'Department'}
            thirdColumn={'No. Responds'}
            fourthColumn={'Date'}
            data={notCompletedDataToSend}
            itemsPerPage={10}
            title={'Sent Surveys'}
            isTemplate={false}
            isFavAble={true}
            setRenderPage={setRenderPage}
            renderPage={renderPage}
          />
        </div>
        <div className='mysurveys-survey-container'>
        <SurveySection
            showSearchBar={true}
            firstColumn={'Title'}
            secondColumn={'Department'}
            thirdColumn={'No. Responds'}
            fourthColumn={'Date'}
            data={surveysTeam} 
            itemsPerPage={10}
            title={'My Team Surveys'}
            isTemplate={false}
            isFavAble={true}
            teamSection = {true}
          />
          
        </div>
        <div className='mysurveys-survey-container'>
        <SurveySection
            showSearchBar={true}
            firstColumn={'Title'}
            secondColumn={'Department'}
            thirdColumn={'No. Responds'}
            fourthColumn={'Date'}
            data={completedDataToSend}
            itemsPerPage={10}
            title={'Completed Surveys'}
            isTemplate={false}
            isFavAble={true}
            batchCompleted={true}
          />
        </div>
      </div>
    </div>
  );
}

export default MySurveys;
