import ProgressBar from 'react-bootstrap/ProgressBar';
import './Progressbar.css';

interface Props {
  percentage: number;
}

//@param percentageNumber: number that should be visible on the progressbar
function Progressbar(percentageNumber: Props) {
  const { percentage } = percentageNumber;
  return (
    <ProgressBar
      className='bar'
      id='progress-bar-answerview'
      variant='success'
      now={percentage}
      label={`${percentage.toFixed(0)}%`}
    />
  );
};

export default Progressbar;
