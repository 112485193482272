import createClient from "../axios"
//Variable "data" is a JSON object - The object consists different things based on the function
//Variable "id" is an ID - The ID depends on either the id of the section you want to get or the survey you want to get.
export type questionTempCreate = {
    title: string;
    titleEmoji?: string;
    description: string;
    type: string;
    mandatory: boolean;
    qoptions: string[];
    emojiOpt: string[];
    bounds: number[];
    condition: string[];
    multipleChoice: boolean;
    parentQuestionId?: string;
    sectionId: string;
}

export type questionTempUpdate = {
    id: string;
    title?: string;
    titleEmoji?: string;
    description?: string;
    type?: string;
    mandatory?: boolean;
    qoptions?: string[];
    emojiOpt?: string[];
    bounds?: number[];
    multipleChoice?: boolean;
    condition?: string[];
}

export type questionTempExtract = {
    id: string;
    title: string;
    titleEmoji?: string;
    description: string;
    type: string;
    mandatory: boolean;
    qoptions: string[];
    emojiOpt: string[];
    bounds: number[];
    condition: string[];
    multipleChoice: boolean;
    parentQuestionId?: string;
    sectionId: string;
}

/**
 * Create a template question
 * @param data containing all information needed to create a template question
 * @returns -string, id of the created question
 */
export async function createQuestionTemp(data: questionTempCreate):Promise<string> {
    const client = await createClient();
    return client.post('/questiontemp/', data)
    .then((response) => {
        return response.data.id
    })
    .catch((error) => console.log(error));
}

/**
 * get a template question by id
 * @param id id of a template question
 * @returns -questionTempExtract, the question
 */
export async function getQuestionTempById(id: string):Promise<questionTempExtract> {
    const client = await createClient();
    return client.get('/questiontemp/'+ id)
    .then((response) => {
        return response.data
    })
    .catch((error) => console.log(error));
}

/**
 * Get the template questions based on the sectionId
 * @param sectionId id of the section
 * @returns -questionTempExtract[], an array of all questions
 */
export async function getQuestionTempBySectionId(sectionId: string):Promise<questionTempExtract[]> {
    const client = await createClient();
    return client.get('/questiontemp/section/'+ sectionId,  {params: {
        id: sectionId
    }})
    .then((response) => {
        return response.data
    })
    .catch((error) => console.log(error));
}

/**
 * Update a template question
 * @param data containing all informationto update a template question
 * @returns -string, id of the updated question
 */
export async function updateQuestionTemp(data: questionTempUpdate):Promise<string> {
    const client = await createClient();
    return client.put('/questiontemp/' + data.id, data)
    .then((response) => {
        return response.data.id;
    })
    .catch((error) => console.log(error)); 
}

/**
 * Delete a template question
 * @param id id of the template question
 * @returns -string, id of the deleted question
 */
export async function deleteQuestionTemp(id: string):Promise<string> {
    const client = await createClient();
    return client.delete('/questiontemp/' + id)
    .then((response) => {
        return response.data.id
    })
    .catch((error) => console.log(error));
}

/**
 * Gets all child questions based on parent question ID
 * @param parentId id of parent question
 * @returns questionTempExtract[], array of all children to a question.
 */
export async function getChildQuestionsTempById(parentId: string):Promise<questionTempExtract[]>{
    const client = await createClient();
    return client.get('/questiontemp/child/' + parentId,  {params: {
        id: parentId
    }})
    .then((response)=> {
        return response.data.id;
    })
    .catch((error)=> console.log(error));
}
