import Card from 'react-bootstrap/Card';
import AnswerViewListAnswers from './AnswerViewListAnswers';
import { Answer, followUpConditions } from '../../assets/types';
import { Emoji } from 'emoji-picker-react';
import { questionSentExtract } from '../../functions/Sent_functions/questionSent';
import { useEffect, useState, useRef } from 'react';
import './AnswerViewQuestion.css';

type followUpConditionsMet = followUpConditions & { conditionsMet: string[] };

interface Props {
  question: questionSentExtract;
  register: any;
  errors: any;
  control: any;
  toggleClear: boolean;
  setClear: (clear: boolean) => void;
  setQuestionIconAnswers: (id: string, answers: string[]) => void;
  addFollowUp: (ids: string[], parentId: string) => void;
  removeFollowUp: (ids: string[], parentId: string) => void;
  followUpConditions: followUpConditions[];
  clearFollowUps: boolean;
  setClearFollowUps: (clear: boolean, id: string) => void;
  missingIcon: boolean;
  addRemoveFollowUp: (add: string[], remove: string[], id: string) => void;
  setBackUpMultipleAnswers: (id: string, answers: string[]) => void;
}

const AnswerViewQuestion = ({
  question,
  register,
  errors,
  toggleClear,
  setClear,
  setQuestionIconAnswers,
  addFollowUp,
  removeFollowUp,
  addRemoveFollowUp,
  followUpConditions,
  clearFollowUps,
  setClearFollowUps,
  setBackUpMultipleAnswers,
  missingIcon,
}: Props) => {
  const {
    id,
    title,
    titleEmoji,
    description,
    type,
    mandatory,
    qoptions,
    emojiOpt,
    bounds,
    multipleChoice,
    sectionId,
    parentQuestionId,
    answer,
  } = question;
  const questionIdString = id as string;
  //meta data

  const missingRef = useRef<HTMLDivElement>(null);

  function reWriteAnswers(qoptions?: string[], emojiOpt?: string[]) {
    if (qoptions?.length !== 0 || qoptions !== undefined) {
      const answers: Answer[] = qoptions
        ? qoptions.map((qoption: any, index: any) => ({
            answer: qoption as string,
            emoji: (emojiOpt ? emojiOpt[index] : undefined) as string,
          }))
        : [];
      return answers;
    }
  }

  const [followUpConditionsMet, setFollowUpConditionsMet] = useState<
    followUpConditionsMet[]
  >(
    followUpConditions.map((q) => {
      return { id: q.id, conditions: q.conditions, conditionsMet: [] };
    })
  ); //TODO

  const toggleRemoveFollowUpOld = (remove: string) => {
    //keep this just in case for now
    const newList = followUpConditionsMet;
    const followUpsToRemove: string[] = [];
    followUpConditionsMet.forEach((q, index) => {
      if (q.conditions.includes(remove)) {
        const oldConditions = q.conditionsMet;
        const newConditions = oldConditions.filter((a) => a !== remove);
        if (newConditions.length === 0) {
          followUpsToRemove.push(q.id);
        }
        newList[index].conditionsMet = newConditions;
      }
    });
    setFollowUpConditionsMet(newList);
    removeFollowUp(followUpsToRemove, question.id);
  };

  const toggleFollowUp = (
    add: string | undefined,
    remove: string | undefined
  ) => {
    const newList = followUpConditionsMet;
    const followUpsToRemove: string[] = [];
    const followUpsToAdd: string[] = [];
    followUpConditionsMet.forEach((q, index) => {
      //loop trough all follow up questions
      if (
        add !== undefined &&
        !q.conditionsMet.includes(add) &&
        q.conditions.includes(add)
      ) {
        //if the answer is a condition for the follow up question and the answer was not already met
        if (q.conditionsMet.length === 0) {
          //if the follow up questions had no previous contitions met, add it to the list of follow ups to add
          followUpsToAdd.push(q.id);
        }
        newList[index].conditionsMet.push(add); //add the answer to the conditions met
      }
      if (remove !== undefined && q.conditions.includes(remove)) {
        const oldConditions = q.conditionsMet;
        const newConditions = oldConditions.filter((a) => a !== remove);
        if (newConditions.length === 0) {
          followUpsToRemove.push(q.id);
        }
        newList[index].conditionsMet = newConditions;
      }
    });
    setFollowUpConditionsMet(newList);
    addRemoveFollowUp(followUpsToAdd, followUpsToRemove, question.id);
  };

  const toggleFollowUpOld = (answer: string) => {
    //keep this just in case for now
    const newList = followUpConditionsMet;
    const followUpsToAdd: string[] = [];
    followUpConditionsMet.forEach((q, index) => {
      //loop trough all follow up questions
      if (!q.conditionsMet.includes(answer) && q.conditions.includes(answer)) {
        //if the answer is a condition for the follow up question and the answer was not already met
        if (q.conditionsMet.length === 0) {
          //if the follow up questions had no previous contitions met, add it to the list of follow ups to add
          followUpsToAdd.push(q.id);
        }
        newList[index].conditionsMet.push(answer); //add the answer to the conditions met
      }
    });
    setFollowUpConditionsMet(newList); //else add the answer to the list of met answers
    addFollowUp(followUpsToAdd, question.id);
  };

  const answers: Answer[] = reWriteAnswers(qoptions, emojiOpt) as Answer[];
  const [selectedIconAnswers, setSelectedIconAnswers] = useState<string[]>(
    answer || []
  );

  const handleIconAnswerClick = (answer: string) => {
    if (!multipleChoice) {
      setSelectedIconAnswers([answer]);
      setQuestionIconAnswers(question.id, [answer]);
    } else if (selectedIconAnswers.includes(answer)) {
      setSelectedIconAnswers(selectedIconAnswers.filter((a) => a !== answer));
      setQuestionIconAnswers(
        question.id,
        selectedIconAnswers.filter((a) => a !== answer)
      );
    } else {
      setSelectedIconAnswers([...selectedIconAnswers, answer]);
      setQuestionIconAnswers(question.id, [...selectedIconAnswers, answer]);
    }
  };

  useEffect(() => {
    if (toggleClear) {
      setFollowUpConditionsMet(
        followUpConditions.map((q) => {
          return { id: q.id, conditions: q.conditions, conditionsMet: [] };
        })
      );
    }
  }, [toggleClear]);

  useEffect(() => {
    if (missingIcon && missingRef.current) {
      missingRef.current.focus();
    }
  }, [missingIcon]);

  return (
    <div className='answerQuestionContainer'>
      <Card>
        <Card.Header>
          <div>
            <div
              className='answerViewQuestTitleDiv'
              style={
                titleEmoji
                  ? { gridTemplateColumns: '.5fr 4fr 1fr' }
                  : { gridTemplateColumns: '4fr 1fr' }
              }
            >
              {titleEmoji && (
                <div className='answerViewQuestTitleEmojiDiv'>
                  <Emoji unified={titleEmoji as string} size={40} />
                </div>
              )}

              <div>{title}</div>
              <div style={{ textAlign: 'right' }}>
                <i style={{ fontSize: 'large', color: 'red' }}>
                  {mandatory ? '*' : ''}
                </i>
              </div>
            </div>
            <div className='questDescriptionDiv'>
              <em>{description}</em>
            </div>
          </div>
        </Card.Header>
        <Card.Body>
          <div>
            <AnswerViewListAnswers
              setBackUpMultipleAnswers={setBackUpMultipleAnswers}
              clearFollowUps={clearFollowUps}
              setClearFollowUps={setClearFollowUps}
              toggleFollowUp={toggleFollowUp}
              condition={followUpConditions.map((q) => q.conditions).flat()}
              setSelectedIconAnswers={setSelectedIconAnswers}
              handleIconAnswerClick={handleIconAnswerClick}
              selectedIconAnswers={selectedIconAnswers}
              toggleClear={toggleClear}
              setClear={setClear}
              key={id}
              answers={answers}
              type={type}
              multipleChoice={multipleChoice}
              register={register}
              questionId={id}
              required={mandatory}
              errors={errors}
              savedAnswers={answer}
              bounds={bounds}
            />
            <p className='errorRequiredQuestion'>
              {errors[questionIdString] && errors[questionIdString].message}{' '}
            </p>
            <div tabIndex={0} ref={missingRef}>
              {missingIcon && (
                <p className='errorRequiredQuestion'>
                  this question is required
                </p>
              )}
            </div>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default AnswerViewQuestion;
