import createClient from '../axios'

//The type used when creating a keyword. Id is created automatically 
export type keyword = {
    value: String;
}

//The argument type taken in when updating a keyword. id field mandatory, the other optional
export type keywordUpdate = {
    id: String;
    value?: String;
}

//The type returned when fetching a keyword. 
export type keywordExtract = {
    id: String;
    value: String;
}

/**
 * Gets all keywords.
 * @returns KeywordExtract[], an array of keywords with value.
 */
export async function getKeywords():Promise<keywordExtract[]> {
    const client = await createClient();
    return client.get('/keywords')
        .then((response) => {
            return response.data //This returns all the keywords information of all keywords
        })
        .catch((error) => console.log(error));
};

/**
 * Gets a keyword by id
 * @param id The id of the keyword
 * @returns keywordExtract, the keyword with its value and id
 */
export async function getKeywordById(id: string):Promise<keywordExtract> {
    const client = await createClient();
    return client.get('/keywords/' + id)
        .then((response) => {
            return response.data //This returns the specific keyword indicated by the ID
        })
        .catch((error) => console.log(error));
};

/**
 * created a keyword.
 * @param data an object with the keyword value.
 * @returns string, the id of the created keyword.
 */
export async function createKeyword(data: keyword):Promise<string> {
    const client = await createClient();
    return client.post('/keywords', data)
        .then((response) => {
            return response.data.id //This returns the data of the newly created keyword
        })
        .catch((error) => console.log(error));
};

/**
 * Updates a keywords fields.
 * @param data the information to be updated
 * @returns string, the id of the updated keyword.
 */
export async function updateKeyword(data: keywordUpdate):Promise<string> {
    const client = await createClient();
    return client.put('/keywords/' + data.id, data)
        .then((response) => {
            return response.data.id //This returns the data of the updated keyword 
        })
        .catch((error) => console.log(error));
};

/**
 * deletes a keyword.
 * @param id The id of the keyword
 * @returns string, the id of the deleted keyword.
 */
export async function deleteKeyword(id: string):Promise<string> {
    const client = await createClient();
    return client.delete('/keywords/'+ id)
        .then((response) => {
            return response.data //This returns the data of the keyword to be deleted 
        })
        .catch((error) => console.log(error));
};
