import './AnswerView.css';
import { useState } from 'react';
import { useEffect } from 'react';
import { answerGetSectionQuestions } from '../../functions/Sent_functions/sentUtils';
import { questionSentExtract } from '../../functions/Sent_functions/questionSent';
import { sectionType } from '../../assets/types';
import DoneButton from '../Buttons/DoneButton';
import {
  surveySentUpdate,
  updateSurveySent,
} from '../../functions/Sent_functions/surveySent';

interface Props {
  surveyTitle: string;
  surveyId: string;
  sectionOrder: string[];
  sections: sectionType[];
  setCompleted: (completed: boolean) => void;
  handleSendIn: () => void;
}

export type SectionWithQuestion = sectionType & {
  questions: questionSentExtract[];
};

const AnswerViewInspectAnswers = ({
  surveyTitle,
  surveyId,
  sectionOrder,
  sections,
  setCompleted,
  handleSendIn,
}: Props) => {
  const [orderedSections, setOrderedSections] =
    useState<SectionWithQuestion[]>();

  useEffect(() => {
    const sectionsOrdered: sectionType[] = sectionOrder.map((sectionId) => {
      return sections.find(
        (section) => section.id === sectionId
      ) as sectionType;
    });
    const getSectionQuestionsPromises = sectionsOrdered.map((section) => {
      return answerGetSectionQuestions(section.id).then((res) => {
        const orderedQuestions: questionSentExtract[] = res.length
          ? section.questionOrder.map(
              (id) => res.find((obj) => obj.id === id) as questionSentExtract
            )
          : [];
        return { ...section, questions: orderedQuestions };
      });
    });
    Promise.all(getSectionQuestionsPromises).then((sectionsWithQuestions) => {
      setOrderedSections(sectionsWithQuestions);
    });
  }, []);

  return (
    <div className='answer-section-container'>
      <div className='answer-section-title-container-inspect'>
        <h3 className='answer-section-title'>{surveyTitle}</h3>
      </div>

      <div className='section-list-questions-inspect'>
        <p>
          {' '}
          <i>Please inspect answers</i>{' '}
        </p>
        {orderedSections &&
          orderedSections?.length !== 0 &&
          orderedSections.map((section) => {
            return (
              <div
                key={section.id}
                className='survey-inspect-section-container'
              >
                <h5>{section.title}</h5>
                <>
                  {section.questions.map((question) => {
                    return (
                      <div
                        key={question.id}
                        className='survey-inspect-question-container'
                      >
                        <div id='survey-inspect-question-title'>
                          <span>{question.title}</span>
                        </div>
                        <div id='survey-inspect-question-answer'>
                          {question.answer.map((ans) => {
                            return <span key={ans + question.id}> {ans} </span>;
                          })}
                        </div>
                      </div>
                    );
                  })}
                </>
                <hr />
              </div>
            );
          })}
      </div>
      <div className='section-button-send'>
        <DoneButton text='Send in survey' onClick={handleSendIn} />
      </div>
    </div>
  );
};

export default AnswerViewInspectAnswers;
