import './PatientSearch.css';
import { useState, useEffect } from 'react';
import SurveyInstanceSection from '../../components/SurveySection/SurveyInstancesSection';
import {getSurveysByPatientId} from '../../functions/Sent_functions/surveySent'
import {crossIcon} from '../../assets/Icons'

function PatientSearch() {
  const [typedData, setTypedData] = useState('');
  const [finishedSearchWord, setFinishedSearchWord] = useState('');
  const [data, setData] = useState([]);
  const [currentData, setCurrentData] = useState(data);

  const onChangeHandler = (event: any) => {
    const typedData = event.target.value;
    setTypedData(typedData);
  };

  const enterHandler = (event: any) => {
    if (event.key === 'Enter') {
      setFinishedSearchWord(typedData);
      setTypedData('');
    }
  };

  const handleGetInstances = () => {
    getSurveysByPatientId(finishedSearchWord).then((result: any) => {
      setData(result);
    });

  };
  
  const clearSearch = () => {
    setFinishedSearchWord('')
    setData([])
  };

  useEffect(() => {
    setCurrentData(data)
  }, [data])

  useEffect(() => {
    if (finishedSearchWord !== ''){
      handleGetInstances()
    }
  }, [finishedSearchWord])

   return (
    <div className='page'>
      <div className='view-survey-instances-top-patientsearch'>
      <input id= {'searchbar-in-surveysection-instances'}
              type='search'
              placeholder={
                finishedSearchWord !== '' ? finishedSearchWord : 'Search Patient Id...'
              }
              className='search-bar'
              aria-label='Search'
              onChange={onChangeHandler}
              onKeyUp={enterHandler}
              value={typedData}
            />
              {finishedSearchWord != '' && (
            
            <button
              className={'clear-search-button-surveysection'}
              id='clear-instances-patientsearch'
              onClick={clearSearch}
            >
              {crossIcon}
            </button>
        )}
        </div>
        <SurveyInstanceSection surveyInstanceList={currentData} isPatientSearch={true} recPerPage={10}/> 
    </div>
  );
}

export default PatientSearch;
