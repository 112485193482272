import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import ListAnswerChoice from '../QuestionCreate/ListAnswerChoices';
import { Emoji } from 'emoji-picker-react';
import '../QuestionCreate/QuestionCreate.css';
import { questionInQBType } from '../../assets/types';

interface Props {
  question: questionInQBType;
}

const ShowQuestionInQuestionBank = ({ question }: Props) => {
  return (
    <Card>
      <Card.Header>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <Col md={10}>
            <>
              <Container className='questTitleDiv'>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginBottom: '20px',
                  }}
                >
                  <p>{question.title}</p>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginLeft: '15px',
                    }}
                  >
                    <Emoji unified={question.titleEmoji as string} size={40} />
                  </div>
                </div>
              </Container>
            </>
          </Col>
          <Col md={2}></Col>
        </div>
        <Container className={'questDescriptionDiv'}>
          <p>{question.description}</p>
        </Container>
      </Card.Header>
      <Card.Body>
        <Container fluid>
          <Row>
            {question.type !== 'multiple choice' && (
              <i className='type-text'>
                input {question.type}{' '}
                {question.type === 'number' &&
                  question.bounds !== undefined &&
                  question.bounds.length > 0 && (
                    <>
                      between {question.bounds[0]} and {question.bounds[1]}
                    </>
                  )}
                :
              </i>
            )}
            <ListAnswerChoice
              answers={question.answers}
              edit={false}
              handleRemove={() => {}}
              type={question.type}
              multipleChoice={question.multipleChoice}
            />
          </Row>
        </Container>
      </Card.Body>
    </Card>
  );
};

export default ShowQuestionInQuestionBank;
