import { Answer } from "../../assets/types";
import createClient from "../axios";

//For creating a question use this type, you do not need to set id it will be set automatically.
export type questionCreatedCreate = {
    title: string;
    titleEmoji?: string;
    description: string;
    type: string;
    mandatory: boolean;
    answers: Answer[];
    bounds: number[];
    condition: string[];
    multipleChoice: boolean;
    sectionId: string;
    parentQuestionId?: string;
}

export type questionCreateDB = {
    title: string;
    titleEmoji?: string;
    description: string;
    type: string;
    mandatory: boolean;
    qoptions?: string[];
    emojiOpt?: string[];
    bounds: number[];
    condition: string[];
    multipleChoice: boolean;
    sectionId: string;
    parentQuestionId?: string;
}

//This is the type taken in when updating a question. Id is mandatory the rest depends on what you are updating
export type questionCreatedUpdate = {
    id: string;
    title?: string;
    titleEmoji?: string;
    description?: string;
    type?: string;
    mandatory?: boolean;
    answers: Answer[];
    bounds?: number[];
    condition?: string[];
    multipleChoice?: boolean;
}

//The type returned when you ask for a complete question. ID is the param you send in
export type questionCreatedExtract = {
    id: string;
    title: string;
    titleEmoji?: string;
    description: string;
    type: string;
    mandatory: boolean;
    qoptions: string[];
    emojiOpt:string[];
    bounds: number[];
    condition: string[];
    multipleChoice:   boolean;
    sectionId: string;
    parentQuestionId?: string;
}

/**
 * Created a question belonging to a created section
 * @param data type questionCreatedCreate (data necessary for creation)
 * @returns string, the id of the created question
 */
export async function createQuestionCreated(data: questionCreateDB):Promise<string> {
    const updatedData = {
        title: data.title,
        titleEmoji: data.titleEmoji,
        description: data.description,
        type: data.type,
        mandatory: data.mandatory,
        qoptions: data?.qoptions,
        emojiOpt: data?.emojiOpt,
        bounds: data.bounds,
        condition: data.condition,
        multipleChoice: data.multipleChoice,
        sectionId: data.sectionId,
        parentQuestionId: data.parentQuestionId
    }
    const client = await createClient();
    return client.post('/questioncreated', updatedData)
    .then((response) => {
        return response.data.id;
    })
    .catch((error) => console.log(error));
}

/**
 * Gets a question by ID
 * @param id Id of question created
 * @returns the type questionCreatedExtract, the question asked for.
 */
export async function getQuestionCreatedById(id: string):Promise<questionCreatedExtract> {
    const client = await createClient();
    return client.get('/questioncreated/'+ id)
    .then((response) => {
        return response.data;
    })
    .catch((error) => console.log(error));
}

/**
 * Gets all questions belonging to a section.
 * @param sectionId , id of the section
 * @returns type questionCreatedExtract[] an array of questions belonging to the section.
 */
export async function getQuestionCreatedBySectionId(sectionId: string):Promise<questionCreatedExtract[]> {
    const client = await createClient();
    return client.get('/questioncreated/section/' + sectionId,  {params: {
        id: sectionId
    }})
    .then((response) => {
        return response.data
    })
    .catch((error) => console.log(error));
}

/**
 * will update a question based on what fields are sent in data.
 * @param data questionCreatedUpdate
 * @returns string, the id of the updated question.
 */
export async function updateQuestionCreatedById(data: questionCreatedUpdate):Promise<string> {
    const qoptions = data.answers.map((answer) => answer.answer);
    const emojiOpt = data.answers.map((answer) => answer.emoji);
    const client = await createClient();
    const updatedData  = {
        id: data.id,
        title: data.title,
        titleEmoji: data.titleEmoji,
        description: data.description,
        type: data.type,
        mandatory: data.mandatory,
        qoptions: qoptions,
        emojiOpt: emojiOpt,
        bounds: data.bounds,
        condition: data.condition,
        multipleChoice: data.multipleChoice,
    }
    return client.put('/questioncreated/'+ updatedData.id, updatedData)
    .then((response) => {
        return response.data.id;
    })
    .catch((error) => console.log(error)); 
}

/**
 * Deletes a question created (not children)
 * @param id id of question
 * @returns string, the id of the deleted question.
 */
export async function deleteQuestionCreated(id: string):Promise<string> {
    const client = await createClient();
    return client.delete('/questioncreated/' + id)
    .then((response) => {
        return response.data.id;
    })
    .catch((error) => console.log(error));
}

/**
 * Gets all child questions based on parent question ID
 * @param parentId id of parent question
 * @returns questionCreatedExtract[], array of all children to a question.
 */
export async function getChildQuestionsCreatedById(parentId: string):Promise<questionCreatedExtract[]>{
    const client = await createClient();
    return client.get('/questioncreated/child/' + parentId,  {params: {
        id: parentId
    }})
    .then((response)=> {
        return response.data.id;
    })
    .catch((error)=> console.log(error));
}
