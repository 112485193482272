import React, { useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import './SectionCreate.css';

interface Props {
  description: string;
  edit: boolean;
  toggleChangedDescription: (description: string) => void;
}

const SectionDescriptionCreate = ({
  description,
  edit,
  toggleChangedDescription,
}: Props) => {
  const [sectionDescription, setSectionDescription] = useState(description);
  useEffect(() => {
    toggleChangedDescription(sectionDescription);
  }, [edit]);
  const enterHandler = (event: any) => {
    if (event.key === 'Enter') {
      toggleChangedDescription(sectionDescription);
    }
  };

  return (
    <Row className={!edit ? 'sectionTitleDiv' : 'sectionTitleDivEdit'}>
      {!edit ? (
        <div>
          {' '}
          {sectionDescription.split('\n').map((line, index) => (
            <p key={index} className='SectionDescription'>
              {line}
            </p>
          ))}
        </div>
      ) : (
        <textarea
          value={sectionDescription}
          onChange={(e) => setSectionDescription(e.target.value)}
          className='SectionDescriptionEdit'
          onKeyUp={enterHandler}
        />
      )}
    </Row>
  );
};

export default SectionDescriptionCreate;
