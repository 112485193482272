import React from 'react';
import { useState } from 'react';
import './SendSurvey.css';

interface Props {
  id: string;
  register: any;
  errors: any;
}

const CollectInfo = ({ id, register, errors }: Props) => {
  const [email, setEmail] = useState('');
  const [phoneNum, setPhoneNum] = useState('');

  return (
    <div className='id-inputs-container'>
      <p className='id-title'>patient {id}</p>
      <div className='id-inputs'>
        <div className='email-address'>
          <p>email address:</p>
          <input
            {...register(id + ' email', { required: true })}
            type='mail'
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
          {errors[id + ' email'] && <p>This field is required</p>}
        </div>
        <div className='phone-number'>
          <p>phone number:</p>
          <input
            {...register(id + ' phone', { required: true })}
            type='tel'
            value={phoneNum}
            onChange={(e) => {
              setPhoneNum(e.target.value);
            }}
          />
          {errors[id + ' phone'] && <p>This field is required</p>}
        </div>
      </div>
    </div>
  );
};

export default CollectInfo;
