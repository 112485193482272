import { Accordion, Button } from 'react-bootstrap';
import { Answer, childEditing, questionType } from '../../assets/types';
import './QuestionCreate.css';
import QuestionCreate from './QuestionCreate';
import FollowUpConditions from './FollowUpConditions';
import { useEffect, useState } from 'react';
import FollowUpQuestion from './FollowUpQuestion';

interface Props {
  questions: questionType[];
  questionsList: questionType[];
  updateQuestion: (question: any) => void;
  addNewQuestion: (parentId?: string) => void;
  newQuestion: boolean;
  answers: Answer[];
  parent: { name: string; id: string };
  removeQuestion: (question: questionType) => void;
  childCount: number;
  handleFetchQuestionBank: () => void;
  setChildIsEditing: (id: string, isEditing: boolean) => void;
  childrenEditing: childEditing[];
  handleToggle: (eventKey: string) => void;
  toggleFollowUpIsEditing: (id: string, edit: boolean) => void;
  setFollowUpConditions: (conditions: string[]) => void;
  followUpConditions: string[];
  addNewFollowUp: boolean;
  setAddNewFollowUp: (addNewFollowUp: boolean) => void;
}

const FollowUpQuestionList = ({
  setAddNewFollowUp,
  setChildIsEditing,
  handleFetchQuestionBank,
  questions,
  childrenEditing,
  questionsList,
  updateQuestion,
  addNewQuestion,
  newQuestion,
  answers,
  parent,
  removeQuestion,
  childCount,
  handleToggle,
  toggleFollowUpIsEditing,
  setFollowUpConditions,
  followUpConditions,
  addNewFollowUp,
}: Props) => {
  const [conditions, setConditions] = useState<
    { conditions: string[]; questionId: string }[]
  >(questions.map((question) => ({ conditions: [], questionId: question.id })));

  const handleAddConditions = (
    newConditions: string[],
    remove: string[],
    question: string
  ) => {
    const conditionsUpdate = conditions.filter(
      (condition) => condition.questionId !== question
    ); //list without the question that is being updated

    conditionsUpdate.push({
      conditions: [...newConditions],
      questionId: question,
    }); //adding the updated question to the list

    let addConditions = false;
    let removeConditions = false;
    const conditionsToAdd: string[] = [];
    const conditionsToRemove: string[] = [];
    newConditions.forEach((condition) => {
      if (!followUpConditions.includes(condition)) {
        conditionsToAdd.push(condition);
        addConditions = true;
      }
    }); //adding the conditions to add to a list if they are not already in the followUpConditions list
    remove.forEach((condition) => {
      if (followUpConditions.includes(condition)) {
        let conditionInOther = false;
        conditions.forEach((condObj) => {
          if (
            condObj.questionId !== question &&
            condObj.conditions.includes(condition)
          ) {
            conditionInOther = true;
          }
        });
        if (!conditionInOther) {
          conditionsToRemove.push(condition);
          removeConditions = true;
        }
      }
    }); // checking if any of the conditions to remove are in the followUpConditions list and adding them to a list to remove from the followUpConditions list
    const filteredConditions: string[] = followUpConditions.filter(
      (condition) => !conditionsToRemove.includes(condition)
    ); //filtering out the conditions to remove from the followUpConditions list
    setFollowUpConditions([...filteredConditions, ...conditionsToAdd]);
    setConditions(conditionsUpdate);
  };

  useEffect(() => {}, [conditions]);

  return (
    <Accordion.Item eventKey={parent.id}>
      <Accordion.Header
        bsPrefix='accordion-header-new'
        onClick={() => {
          handleToggle(parent.id);
        }}
      >
        Follow Up Questions - {parent.name}
      </Accordion.Header>

      <Accordion.Body>
        {questions.map((question, index) => (
          <div key={question.id}>
            {index !== 0 && <hr />}
            <FollowUpQuestion
              setAddNewFollowUp={setAddNewFollowUp}
              addNewFollowUp={addNewFollowUp}
              questionsList={questionsList}
              updateQuestion={updateQuestion}
              addNewQuestion={addNewQuestion}
              newQuestion={newQuestion}
              answers={answers}
              removeQuestion={removeQuestion}
              childCount={childCount}
              handleFetchQuestionBank={handleFetchQuestionBank}
              setChildIsEditing={setChildIsEditing}
              childrenEditing={childrenEditing}
              question={question}
              toggleFollowUpIsEditing={toggleFollowUpIsEditing}
              handleAddConditions={handleAddConditions}
              conditionsFromStart={question.condition}
            />
          </div>
        ))}
      </Accordion.Body>
    </Accordion.Item>
  );
};

export default FollowUpQuestionList;
