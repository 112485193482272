import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import { useEffect, useRef, useState } from 'react';
import { useCollapse } from 'react-collapsed';
import Form from 'react-bootstrap/Form';
import ListAnswerChoice from './ListAnswerChoices';
import DoneButton from '../Buttons/DoneButton';
import CustomButton from '../Buttons/CustomButton';
import Select from 'react-select';
import {
  DataTypesAllowedType,
  Answer,
  SelectOptions,
  questionType,
  childEditing,
} from '../../assets/types';

import './QuestionCreate.css';
import QuestionCreateTitle from './QuestionCreateTitle';
import QuestionCreateDescription from './QuestionCreateDescription';
import QuestionCreateAddNewAnswer from './QuestionCreateAddNewAnswer';

import AddQuestionToQuestionBank from './AddQuestionToQuestionBank';
import { Accordion, Button, Modal } from 'react-bootstrap';
import FollowUpQuestionList from './FollowUpQuestionList';

interface Props {
  conditionsFromParent: string[];
  question: questionType;
  followUp: questionType[];
  followUpConditionsFromTemp: string[];
  updateQuestion: (question: questionType) => void;
  newQuestion: boolean;
  addNewQuestion: (parentId?: string) => void;
  questionsList: questionType[];
  removeQuestion: (question: questionType) => void;
  childrenEditing: childEditing[];
  childCount: number;
  handleFetchQuestionBank: () => void;
  followUpConditionEditing: boolean;
  toggleFollowUpEditingProp: (id: string, edit: boolean) => void;
  setMissingConditions: (missing: boolean) => void;
  setChildIsEditing: (id: string, isEditing: boolean) => void;
}

const QuestionCreate = ({
  childrenEditing,
  setChildIsEditing,
  setMissingConditions,
  followUpConditionEditing,
  toggleFollowUpEditingProp,
  conditionsFromParent,
  question,
  updateQuestion,
  newQuestion,
  handleFetchQuestionBank,
  followUp,
  followUpConditionsFromTemp,
  addNewQuestion,
  questionsList,
  removeQuestion,
  childCount,
}: Props) => {
  //DATA FROM DATABASE

  const dataTypesSelect: SelectOptions[] = [
    { value: 'multiple choice', label: 'multiple choice' },
    { value: 'text', label: 'text' },
    { value: 'number', label: 'number' },
    { value: 'date', label: 'date' },
    { value: 'time', label: 'time' },
    { value: 'icon', label: 'icon' },
  ];

  //states
  const [editMode, setEditMode] = useState(newQuestion);
  const [isExpanded, setExpanded] = useState(newQuestion);
  const [multipleAnswers, setMultipleAnswers] = useState(
    question.multipleChoice
  );
  const [addNewQuestionFromBank, setAddNewQuestionFromBank] = useState(false);
  const [cantRemove, setCantRemove] = useState(false);

  const [activeKeys, setActiveKeys] = useState<string[]>([]);

  const [followUpEditing, setFollowUpEditing] = useState<childEditing[]>([]);

  const [showModalChangeDataType, setShowModalChangeDataType] = useState(false);
  const [addFollowUp, setAddFollowUp] = useState(false);
  const [followUpConditions, setFollowUpConditions] = useState<string[]>(
    followUpConditionsFromTemp ? followUpConditionsFromTemp : []
  );
  //title & description
  const questionId = question.id;
  const [emojiTitle, setEmojiTitle] = useState(question?.titleEmoji || '');
  const [questionTitle, setQuestionTitle] = useState(question.title);
  const [questionDescription, setQuestionDescription] = useState(
    question.description
  );
  const [questionBounds, setQuestionBounds] = useState(question.bounds); //TODOD
  //meta data
  const [answerDataType, setAnswerDataType] = useState<DataTypesAllowedType>(
    question.type as DataTypesAllowedType
  );

  const [mandatoryQuestion, setMandatoryQuestion] = useState(
    question.mandatory
  );

  const [min, setMin] = useState<number>();
  const [max, setMax] = useState<number>();
  const [boundsError, setBoundsError] = useState(false);

  //answer choices
  const [answers, setAnswers] = useState<Answer[]>(question.answers);
  const [duplicateAnswer, setDuplicateAnswer] = useState(false);

  //collapsible
  const { getCollapseProps } = useCollapse({ isExpanded });

  //functions

  function addNewAnswer(add: Answer) {
    if (add.answer.length === 0) return;
    if (answers.filter((answer) => answer.answer === add.answer).length > 0) {
      setDuplicateAnswer(true);
      return;
    }
    setDuplicateAnswer(false);
    setAnswers(answers.concat(add));
  }

  function removeAnswer(remove: string) {
    if (followUpConditions.includes(remove)) {
      setCantRemove(true);
    } else {
      setAnswers(answers.filter((answer) => answer.answer !== remove));
      setCantRemove(false);
    }
  }

  function editQuestion() {
    setEditMode(true);
    setChildIsEditing(questionId, true);
    setExpanded(true);
  }

  function editQuestionDone() {
    if (followUpConditionEditing) {
      setMissingConditions(true);
      return false;
    }
    if (max !== undefined || min !== undefined) {
      if (max === undefined && min === undefined) {
        setBoundsError(true);
        return false;
      }
      const newBounds = [min, max]
        .map((val) => (val !== undefined ? val : null))
        .filter((val) => val !== null) as number[];
      if (newBounds.length === 2 && newBounds[1] < newBounds[0]) {
        setBoundsError(true);
        return false;
      }
      setBoundsError(false);
      setQuestionBounds(newBounds);
    }
    setEditMode(false);
    setChildIsEditing(questionId, false);
    setExpanded(false);
    setCantRemove(false);
    if (childCount !== 0) toggleFollowUpEditingProp(questionId, false);
    return true;
  }
  //UPDATE QUESTION

  const handleSelectDatatypeChange = (value: any) => {
    setAnswerDataType(value.value);
    if (value.value !== 'multiple choice' && followUp?.length > 0) {
      setShowModalChangeDataType(true);
    }
  };

  const handleSelectMultipleAnswer = (value: any) => {
    setMultipleAnswers(value.value);
  };

  function createOptionsList(
    strings: string[]
  ): { label: string; value: string }[] {
    if (!strings) return [] as { label: string; value: string }[];
    return strings.map((string) => ({ label: string, value: string }));
  }

  const handleCloseModal = () => {
    setAddNewQuestionFromBank(false);
  };

  const handleAddFollowUpQuestion = () => {
    if (!editQuestionDone()) {
      return;
    }
    setChildIsEditing(questionId, false);
    setAddFollowUp(true);
  };

  const handleDeleteFollowUpQuestions = () => {
    followUp.forEach((question) => {
      removeQuestion(question);
    });
    setShowModalChangeDataType(false);
  };

  const handleInputChangeMin = (e: React.ChangeEvent<HTMLInputElement>) => {
    const numericValue = parseFloat(e.target.value);
    setMin(Number(numericValue));
  };
  const handleInputChangeMax = (e: React.ChangeEvent<HTMLInputElement>) => {
    const numericValue = parseFloat(e.target.value);
    setMax(Number(numericValue));
  };

  const handleToggle = (eventKey: string) => {
    if (followUpEditing.some((e) => e.editing)) {
      return;
    }
    if (activeKeys.includes(eventKey)) {
      setActiveKeys([]);
    } else {
      setActiveKeys([eventKey]);
    }
  };

  const toggleFollowUpEditing = (questionId: string, edit: boolean) => {
    let exists: boolean = false;
    const newFollow: childEditing[] = followUpEditing.map((child) => {
      if (child.id === questionId) {
        exists = true;
        return { ...child, editing: edit };
      } else return child;
    });
    if (!exists) {
      setFollowUpEditing([
        ...followUpEditing,
        { id: questionId, editing: edit },
      ]);
    } else {
      setFollowUpEditing(newFollow);
    }
  };

  //code block functions

  const EditButtons = () => {
    return !editMode ? (
      <div className='edit-buttons-question-create'>
        <CustomButton
          onClick={() => {
            setAddNewQuestionFromBank(true);
          }}
          text='Add to Question Bank'
        />
        <CustomButton onClick={editQuestion} text='Edit' />
      </div>
    ) : (
      <div className='edit-buttons-question-create'>
        {window.innerWidth > 800 && (
          <CustomButton
            onClick={handleAddFollowUpQuestion}
            disabled={answerDataType !== 'multiple choice' || childCount > 2}
            text='Add follow up question'
          />
        )}
        <DoneButton onClick={editQuestionDone} text='Done' />
      </div>
    );
  };

  const customStyles = {
    control: (base: any, state: any) => ({
      ...base,
      minWidth: '200px', // Set the width to your desired value
      width: '100%',
    }),
  };

  const customStylesType = {
    control: (base: any, state: any) => ({
      ...base,
      minWidth: '100px', // Set the width to your desired value
    }),
  };

  const previousValues = useRef({
    questionTitle,
    questionDescription,
    emojiTitle,
    answerDataType,
    mandatoryQuestion,
    multipleAnswers,
    answers,
    questionBounds,
  });

  useEffect(() => {
    //todo vet inte riktigt om den här updaterar när den ska? Lite oklart, skulle behöva se db
    if (
      previousValues.current.questionTitle !== questionTitle ||
      previousValues.current.questionDescription !== questionDescription ||
      previousValues.current.emojiTitle !== emojiTitle ||
      previousValues.current.answerDataType !== answerDataType ||
      previousValues.current.mandatoryQuestion !== mandatoryQuestion ||
      previousValues.current.multipleAnswers !== multipleAnswers ||
      previousValues.current.answers !== answers ||
      previousValues.current.questionBounds !== questionBounds
    ) {
      let newAnswers: Answer[];
      let newQuestionTitle: string;
      if (answers.length === 0) {
        addNewAnswer({ answer: 'Option 1', emoji: '' });
        newAnswers = [{ answer: 'Option 1', emoji: '' }];
      } else {
        newAnswers = answers;
      }
      if (questionTitle.length === 0) {
        setQuestionTitle('Untitled question');
        newQuestionTitle = 'Untitled question';
      } else {
        newQuestionTitle = questionTitle;
      }
      const tempUpdate: questionType = {
        sectionId: question.sectionId,
        id: questionId,
        title: newQuestionTitle,
        titleEmoji: emojiTitle,
        description: questionDescription,
        type: answerDataType,
        mandatory: mandatoryQuestion,
        multipleChoice: multipleAnswers,
        answers: newAnswers,
        condition: conditionsFromParent,
        bounds: questionBounds,
        parentQuestionId: question.parentQuestionId,
      };
      updateQuestion(tempUpdate);
      previousValues.current = {
        questionTitle,
        questionDescription,
        emojiTitle,
        answerDataType,
        mandatoryQuestion,
        multipleAnswers,
        answers,
        questionBounds,
      };
    }
  }, [
    questionTitle,
    questionDescription,
    emojiTitle,
    answerDataType,
    mandatoryQuestion,
    multipleAnswers,
    answers,
  ]);

  useEffect(() => {}, [answerDataType, questionTitle, followUpConditions]);

  useEffect(() => {
    if (
      childrenEditing.find(
        (child) => child.id === questionId && !child.editing
      ) &&
      addFollowUp
    ) {
      addNewQuestion(question.id);
      setActiveKeys([question.id]);
    }
  }, [childrenEditing]);
  return (
    <Card id='question-card'>
      <Card.Header>
        <div>
          <div className='create-question-title-and-mandatory-div'>
            <div className='question-create-title-div'>
              <QuestionCreateTitle
                title={questionTitle}
                emoji={emojiTitle}
                edit={editMode}
                toggleChangedTitle={setQuestionTitle}
                toggleChangedEmoji={setEmojiTitle}
              />
            </div>
            <div className='mandatory-div'>
              {editMode && (
                <Form.Check
                  defaultChecked={mandatoryQuestion}
                  type='switch'
                  id='mandatory'
                  label='Mandatory question'
                  onChange={() => setMandatoryQuestion(!mandatoryQuestion)}
                />
              )}
            </div>
          </div>
          <div>
            <QuestionCreateDescription
              description={questionDescription}
              toggleChangedDescription={setQuestionDescription}
              edit={editMode}
            />
          </div>
          <div className='tagsDiv' {...getCollapseProps()}>
            <div className='custom-select-question'>
              <b>Answer type</b>
              <Select
                classNamePrefix={'react-select-question'}
                placeholder='Datatype'
                value={createOptionsList([answerDataType])}
                options={dataTypesSelect}
                onChange={(value) => {
                  handleSelectDatatypeChange(value);
                }}
              />
            </div>
            <div className='custom-select-question'>
              <b>Allow multiple answers?</b>
              <Select
                classNamePrefix={'react-select-question'}
                defaultValue={
                  multipleAnswers
                    ? [{ value: true, label: 'allow multiple answers' }]
                    : [{ value: false, label: 'only allow one answer' }]
                }
                placeholder='allow multiple answers'
                options={[
                  { value: true, label: 'allow multiple answers' },
                  { value: false, label: 'only allow one answer' },
                ]}
                onChange={(value) => {
                  handleSelectMultipleAnswer(value);
                }}
                isDisabled={
                  answerDataType !== 'multiple choice' &&
                  answerDataType !== 'icon'
                }
              />
            </div>
          </div>
        </div>
        <Modal show={showModalChangeDataType}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm change datatype</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Changing from multiple choice will delete the follow up questions.
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant='secondary'
              onClick={() => {
                setShowModalChangeDataType(false);
                setAnswerDataType('multiple choice');
              }}
            >
              Cancel
            </Button>
            <Button variant='danger' onClick={handleDeleteFollowUpQuestions}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </Card.Header>
      <Card.Body>
        <Container fluid>
          <Row>
            <ListAnswerChoice
              answers={answers}
              edit={editMode}
              handleRemove={removeAnswer}
              type={answerDataType}
              multipleChoice={multipleAnswers}
            />
            {cantRemove && (
              <p className='alert-text'>
                you cannot remove an answer that a conditional question depends
                on
              </p>
            )}
          </Row>
          <Row>
            {editMode &&
              (answerDataType === 'multiple choice' ||
                answerDataType === 'icon') && (
                <>
                  <QuestionCreateAddNewAnswer
                    toggleChangedAnswer={addNewAnswer}
                    answerDataType={answerDataType}
                  />
                  <div className='duplicateAnswer'>
                    {duplicateAnswer && <p>Answer already exists</p>}
                  </div>
                </>
              )}
            {editMode && answerDataType === 'number' && (
              <div
                style={{
                  display: 'flex',
                  gap: '10px',
                  flexDirection: 'row',
                  alignItems: 'center',
                  textAlign: 'center',
                }}
              >
                Set the bounds for the answer
                <input
                  defaultValue={
                    questionBounds?.[0] ? questionBounds[0].toString() : ''
                  }
                  style={{ width: '50px' }}
                  type='number'
                  placeholder='min'
                  onChange={handleInputChangeMin}
                  value={min}
                />
                <input
                  defaultValue={
                    questionBounds?.[1] ? questionBounds[1].toString() : ''
                  }
                  style={{ width: '50px' }}
                  type='number'
                  placeholder='max'
                  value={max}
                  onChange={handleInputChangeMax}
                />
                {boundsError && (
                  <p className='alert-text'>
                    max and min bounds are both needed and max needs to be
                    larger than min
                  </p>
                )}
              </div>
            )}
          </Row>

          <div className='buttonsAlign'>
            <EditButtons />
            {addNewQuestionFromBank && (
              <AddQuestionToQuestionBank
                question={{
                  id: question.id,
                  condition: question.condition,
                  sectionId: question.sectionId,
                  title: questionTitle,
                  titleEmoji: emojiTitle,
                  bounds: questionBounds,
                  multipleChoice: multipleAnswers,
                  description: questionDescription,
                  type: answerDataType,
                  mandatory: mandatoryQuestion,
                  answers: answers,
                }}
                showModal={addNewQuestionFromBank}
                handleCloseModal={handleCloseModal}
                handleFetchQuestionBank={handleFetchQuestionBank}
              />
            )}
          </div>

          {followUp.length > 0 && (
            <Accordion alwaysOpen activeKey={activeKeys}>
              <FollowUpQuestionList
                setAddNewFollowUp={setAddFollowUp}
                addNewFollowUp={addFollowUp}
                setFollowUpConditions={setFollowUpConditions}
                followUpConditions={followUpConditions}
                toggleFollowUpIsEditing={toggleFollowUpEditing}
                handleToggle={handleToggle}
                childrenEditing={childrenEditing}
                setChildIsEditing={setChildIsEditing}
                handleFetchQuestionBank={handleFetchQuestionBank}
                removeQuestion={removeQuestion}
                parent={{
                  name: questionTitle,
                  id: question.id as string,
                }}
                answers={answers}
                questions={followUp}
                addNewQuestion={addNewQuestion}
                questionsList={questionsList}
                updateQuestion={updateQuestion}
                newQuestion={newQuestion}
                childCount={childCount + 1}
              />
            </Accordion>
          )}
        </Container>
      </Card.Body>
    </Card>
  );
};

export default QuestionCreate;
