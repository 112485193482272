import React, { useEffect, useState } from 'react';
import './SendSurvey.css';
import { Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Creatable from 'react-select/creatable';
import { getPatientIds } from '../../functions/Sent_functions/sentUtils';
import { useAuth0 } from '@auth0/auth0-react';

interface Props {
  ids: string[];
  setIds: (ids: string[]) => void;
  answerSelf: boolean;
  setAnswerSelf: (answerSelf: boolean) => void;
  anonymous: boolean;
  setAnonymous: (anonymous: boolean) => void;
  multiple: boolean;
  setMultiple: (multiple: boolean) => void;
  single: boolean;
  setSingle: (single: boolean) => void;
  startDisabled: boolean;
  setStartDisabled: (startDisabled: boolean) => void;
  handleSelectPatientIds: (value: any) => void;
  handleSelectPatientId: (value: any) => void;
  idMissing: boolean;
}

const SendSurveyChoices = ({
  ids,
  setIds,
  answerSelf,
  setAnswerSelf,
  anonymous,
  setAnonymous,
  multiple,
  setMultiple,
  single,
  setSingle,
  startDisabled,
  setStartDisabled,
  handleSelectPatientId,
  handleSelectPatientIds,
  idMissing,
}: Props) => {
  const { isAuthenticated, user, isLoading } = useAuth0();
  const ownerId = user?.sub;

  const handleSingle = () => {
    if (startDisabled) setStartDisabled(false);
    setSingle(true);
    setMultiple(false);
    setAnswerSelf(false);
    setIds([]);
  };
  const handleMultiple = () => {
    if (startDisabled) setStartDisabled(false);
    setSingle(false);
    setMultiple(true);
    setAnswerSelf(false);
    setIds([]);
  };

  function createOptionsList(
    strings: string[]
  ): { label: string; value: string }[] {
    if (!strings) return [] as { label: string; value: string }[];
    return strings.map((string) => ({ label: string, value: string }));
  }

  const customStyles = {
    control: (base: any, state: any) => ({
      ...base,
      minWidth: '200px', // Set the width to your desired value
      width: '100%',
    }),
  };
  const [patientIds, setPatiendIds] = useState<string[]>([]);

  useEffect(() => {
    if (ownerId) {
      getPatientIds(ownerId).then((res) => {
        setPatiendIds(res);
      });
    }
  }, []);

  useEffect(() => {}, [patientIds]);

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  if (!isAuthenticated || !user) {
    return <div>User is not logged in.</div>;
  }

  return (
    <>
      <div className='send-survey-inner-container'>
        <div
          className={
            single ? 'send-survey single' : 'send-survey single passive'
          }
        >
          <Button
            size='lg'
            onClick={handleSingle}
            className={single ? 'active-button' : 'passive-button'}
          >
            Send one instance
          </Button>
          <Form.Check
            type='checkbox'
            label='Make anonymous'
            checked={single && anonymous }
            onChange={() => setAnonymous(!anonymous)}
          />
          <Form.Check
            type='checkbox'
            label='Answered by me'
            checked={answerSelf && single}
            onChange={() => {if (single) {setAnswerSelf(!answerSelf)}}}
          />
          <Creatable
            placeholder='Select patient id'
            options={createOptionsList(patientIds)}
            onChange={(value) => {
              handleSelectPatientId(value);
            }}
            value={single ? createOptionsList(ids) : []}
            isClearable
            isSearchable
            isDisabled={
              startDisabled ? true : multiple || anonymous ? true : false
            }
            maxMenuHeight={200}
            styles={customStyles}
          />
          {idMissing && single && <p>please provide patiend ID</p>}
        </div>
        <div
          className={
            multiple ? 'send-survey multiple' : 'send-survey multiple passive'
          }
        >
          <Button
            size='lg'
            onClick={handleMultiple}
            className={multiple ? 'active-button' : 'passive-button'}
          >
            Send multiple instances
          </Button>
          <Form.Check
            type='checkbox'
            label='Make anonymous'
            checked={multiple && anonymous}
            onChange={() => {if (multiple) { setAnonymous(!anonymous)}}}
          />
          <Creatable
            options={createOptionsList(patientIds)}
            placeholder='Select patient ids'
            onChange={(value) => {
              handleSelectPatientIds(value);
            }}
            value={multiple ? createOptionsList(ids) : []}
            isClearable
            isSearchable
            isMulti
            isDisabled={
              startDisabled ? true : single || anonymous ? true : false
            }
            maxMenuHeight={200}
            styles={customStyles}
          />
          {idMissing && multiple && <p>please provide patiend IDs</p>}
        </div>
      </div>
    </>
  );
};

export default SendSurveyChoices;
