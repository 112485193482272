import axios from 'axios'
import { AxiosInstance } from "axios";
import Cookies from 'js-cookie'

const baseUrl = process.env.REACT_APP_BACKEND_URL + '/api'

export default async function createClient(): Promise<AxiosInstance> {
  const token = await Cookies.get('access_token')
  const headers = token ? { Authorization: 'Bearer ' + token } : {}
  return axios.create({
    headers: headers,
    baseURL: baseUrl
  })
}


