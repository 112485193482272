import './AnswerView.css';
import { useState } from 'react';
import CustomButton from '../Buttons/CustomButton';
import { FormProvider, useForm } from 'react-hook-form';
import SubmitButton from '../Buttons/SubmitButton';
import AnswerViewQuestion from './AnswerViewQuestion';
import { useEffect } from 'react';
import {
  answerGetSectionQuestions,
  answerUpdateSectionQuestions,
} from '../../functions/Sent_functions/sentUtils';
import {
  questionSentExtract,
  questionSentUpdate,
} from '../../functions/Sent_functions/questionSent';
import {
  sectionSentUpdate,
  updateSectionSent,
} from '../../functions/Sent_functions/sectionSent';
import { followUpConditions } from '../../assets/types';

interface Props {
  lastSection: boolean;
  sectionName: string;
  sectionDesc: string;
  sectionId: string;
  toggleNextSection: (id: string, back: boolean) => void;
  startPage: boolean;
  fetchFinishedSections: () => void;
  questionOrder: string[];
  toggleRefetchSections: () => void;
  setNoServerResponse: (noResponse: boolean) => void;
  handleInspect: () => void;
  handleSendInSurvey: () => void;
}

const AnswerViewSection = ({
  lastSection,
  sectionName,
  sectionDesc,
  startPage,
  sectionId,
  toggleNextSection,
  fetchFinishedSections,
  toggleRefetchSections,
  setNoServerResponse,
  questionOrder,
  handleInspect,
  handleSendInSurvey,
}: Props) => {
  const [sendIn, setSendIn] = useState<boolean>(false);
  const [questions, setQuestions] = useState<questionSentExtract[]>([]);
  const [savedAnswers, setSavedAnswers] = useState<boolean>(false);
  const [clear, setClear] = useState<boolean>(false);
  const [questionIconAnswers, setQuestionIconAnswers] = useState<
    { questionId: string; answers: string[] }[]
  >([]);
  const [backUpMultipleAnswers, setBackUpMultipleAnswers] = useState<
    { questionId: string; answers: string[] }[]
  >([]);

  const [clearFollowUps, setClearFollowUps] = useState<
    { id: string; clear: boolean }[]
  >([]);

  const [missingIconIds, setMissingIconIds] = useState<{
    missing: boolean;
    ids: string[];
  }>({ missing: false, ids: [] });

  const methods = useForm();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = methods;

  const onSubmit = (data: any) => {
    const answerObjects: questionSentUpdate[] = [];
    const missingIcons: string[] = [];

    // Iterate through questionIconAnswers and create objects
    questionIconAnswers.forEach((iconAnswer) => {
      const answerObject = {
        id: iconAnswer.questionId,
        answer: iconAnswer.answers,
      };
      answerObjects.push(answerObject);
    });

    // Loop through the questions and add objects if questionId is not already in answerObjects
    orderedQuestions.forEach((question) => {
      //gå igenom ordered
      if (question.type === 'icon' && question.mandatory) {
        const answer = answerObjects.find(
          (answer) => answer.id === question.id
        );
        if (answer === undefined || answer?.answer?.length === 0) {
          missingIcons.push(question.id);
        }
      }

      const existingAnswerIndex = answerObjects.findIndex(
        (answer) => answer.id === question.id
      );

      if (existingAnswerIndex === -1) {
        let answer = data[question.id]
          ? data[question.id] !== null
            ? data[question.id]
            : []
          : [];
        if (question.type === 'multiple choice') {
          answer = backUpMultipleAnswers.find(
            (saved) => saved.questionId === question.id
          )?.answers;
        }

        const answerObject = {
          id: question.id,
          answer: answer,
        };
        answerObjects.push(answerObject);
      }
    });
    if (missingIcons.length !== 0) {
      setMissingIconIds({ missing: true, ids: missingIcons });
      return;
    }
    setMissingIconIds({ missing: false, ids: [] });
    answerUpdateSectionQuestions(answerObjects, false, sectionId).then(
      (res) => {
        if (res === undefined) {
          setNoServerResponse(true);
          return;
        }
        fetchFinishedSections();
        setSavedAnswers(true);
        setBackUpMultipleAnswers([]);
      }
    );
    const updateSection: sectionSentUpdate = {
      id: sectionId,
      questionOrder: orderedQuestions.map((q) => q.id),
    };
    updateSectionSent(updateSection).then((res) => {
      if (res === undefined) {
        setNoServerResponse(true);
        return;
      }
      toggleRefetchSections();
    });
  };

  const [orderedQuestions, setOrderedQuestions] = useState<
    questionSentExtract[]
  >([]);

  const getFollowUpQuestionConditions = (questionId: string) => {
    const list: followUpConditions[] = [];
    questions.forEach((q) => {
      if (q?.parentQuestionId === questionId) {
        if (q?.condition !== undefined) {
          list.push({ conditions: q.condition, id: q.id });
        }
      }
    });
    return list;
  };

  const handleSetQuestionIconAnswers = (id: string, answers: string[]) => {
    const existingIndex = questionIconAnswers.findIndex(
      (item) => item.questionId === id
    );

    if (existingIndex !== -1) {
      const updatedAnswers = [...questionIconAnswers];
      updatedAnswers[existingIndex] = { questionId: id, answers };
      setQuestionIconAnswers(updatedAnswers);
    } else {
      setQuestionIconAnswers([
        ...questionIconAnswers,
        { questionId: id, answers },
      ]);
    }
  };

  const handleSetBackUpMultipleAnswers = (id: string, answers: string[]) => {
    const existingIndex = backUpMultipleAnswers.findIndex(
      (item) => item.questionId === id
    );

    if (existingIndex !== -1) {
      const updatedAnswers = [...backUpMultipleAnswers];
      updatedAnswers[existingIndex] = { questionId: id, answers };
      setBackUpMultipleAnswers(updatedAnswers);
    } else {
      setBackUpMultipleAnswers([
        ...backUpMultipleAnswers,
        { questionId: id, answers },
      ]);
    }
  };

  const addRemoveFollowUp = (
    add: string[],
    remove: string[],
    questionId: string
  ) => {
    let loop = true;
    let removedIds: string[] = [];
    let newList: questionSentExtract[] = [];
    const allRemovedIds: string[] = [];
    orderedQuestions.forEach((q) => {
      remove.includes(q.id as string) ? removedIds.push(q.id) : newList.push(q);
    });
    let removedIdsTemp = [...removedIds];
    let newListTemp = [...newList];
    while (loop) {
      allRemovedIds.push(...removedIds);
      removedIds = [];
      newList = [];
      newListTemp.forEach((q) => {
        removedIdsTemp.includes(q.parentQuestionId as string)
          ? removedIds.push(q.id)
          : newList.push(q);
      });
      if (removedIds.length === 0) {
        loop = false;
      } else {
        removedIdsTemp = [...removedIds];
        newListTemp = [...newList];
      }
    }
    const newClear = [...clearFollowUps];
    newClear.forEach((c) => {
      if (allRemovedIds.includes(c.id)) {
        c.clear = true;
      }
    });
    setClearFollowUps(newClear);
    const newQuestions: questionSentExtract[] = questions; //TODO DOES NOT WORK?
    newQuestions.forEach((q) => {
      if (remove.includes(q.id)) {
        q.answer = [];
      }
    });

    const questionsToAdd: questionSentExtract[] = newQuestions.filter((q) => {
      return add.includes(q.id as string);
    });
    const index = orderedQuestions.findIndex((q) => q.id === questionId);
    newList.splice(index + 1, 0, ...questionsToAdd);

    setOrderedQuestions(newList);
    setQuestions(newQuestions);
  };

  const addFollowUp = (ids: string[], parentId: string) => {
    const questionsToAdd: questionSentExtract[] = questions.filter((q) => {
      return ids.includes(q.id as string);
    });
    const newList = [...orderedQuestions];
    const index = orderedQuestions.findIndex((q) => q.id === parentId);
    newList.splice(index + 1, 0, ...questionsToAdd);
    setOrderedQuestions(newList);
  };

  const removeFollowUp = (ids: string[], parentId: string) => {
    let loop = true;
    let removedIds: string[] = [];
    let newList: questionSentExtract[] = [];
    const allRemovedIds: string[] = [];
    orderedQuestions.forEach((q) => {
      ids.includes(q.id as string) ? removedIds.push(q.id) : newList.push(q);
    });
    let removedIdsTemp = [...removedIds];
    let newListTemp = [...newList];
    while (loop) {
      allRemovedIds.push(...removedIds);
      removedIds = [];
      newList = [];
      newListTemp.forEach((q) => {
        removedIdsTemp.includes(q.parentQuestionId as string)
          ? removedIds.push(q.id)
          : newList.push(q);
      });
      if (removedIds.length === 0) {
        loop = false;
      } else {
        removedIdsTemp = [...removedIds];
        newListTemp = [...newList];
      }
    }
    setOrderedQuestions(newList);
    const newClear = [...clearFollowUps];
    newClear.forEach((c) => {
      if (allRemovedIds.includes(c.id)) {
        c.clear = true;
      }
    });
    setClearFollowUps(newClear);
    const newQuestions: questionSentExtract[] = questions; //TODO DOES NOT WORK?
    newQuestions.forEach((q) => {
      if (ids.includes(q.id)) {
        q.answer = [];
      }
    });
    setQuestions(newQuestions);
  };

  const toggleClear = () => {
    setClear(true);
    const answerObjects: questionSentUpdate[] = [];
    questions.forEach((question) => {
      const answerObject = {
        id: question.id,
      };
      answerObjects.push(answerObject);
    });
    answerUpdateSectionQuestions(answerObjects, true, sectionId).then((res) => {
      if (res === undefined) {
        setNoServerResponse(true);
        return;
      }
      fetchFinishedSections();
      const newQuestions: questionSentExtract[] = questions;
      newQuestions.forEach((q) => {
        q.answer = [];
      });
      setQuestions(newQuestions);
      reset();
      setSavedAnswers(false);
      setOrderedQuestions(orderedQuestionsStart);
      setMissingIconIds({ missing: false, ids: [] });
      setQuestionIconAnswers([]);
    });
  };

  const toggleSetClearFollowUps = (clear: boolean, id: string) => {
    const newClear = [...clearFollowUps];
    newClear.forEach((c) => {
      if (c.id === id) {
        c.clear = clear;
      }
    });
    setClearFollowUps(newClear);
  };

  const [orderedQuestionsStart, setOrderedQuestionsStart] = useState<
    questionSentExtract[]
  >([]);

  useEffect(() => {
    if (startPage) return;
    answerGetSectionQuestions(sectionId).then((res) => {
      if (res === undefined) {
        setNoServerResponse(true);
        return;
      }
      setQuestions(res);
      const orderedQuestionsStart: questionSentExtract[] = res.length
        ? questionOrder.map(
            (id) => res.find((obj) => obj.id === id) as questionSentExtract
          )
        : [];

      setOrderedQuestions(orderedQuestionsStart);
      setOrderedQuestionsStart(orderedQuestionsStart);
      setClearFollowUps(
        res
          .filter((q) => q.parentQuestionId !== undefined)
          .map((q) => {
            return { id: q.id, clear: false };
          })
      );
    });
  }, []);

  useEffect(() => {}, [questions, orderedQuestions]);

  useEffect(() => {
    if (sendIn && savedAnswers) {
      handleSendInSurvey();
    } else if (lastSection && savedAnswers) {
      handleInspect();
    } else if (savedAnswers) {
      toggleNextSection(sectionId, false);
    }
  }, [savedAnswers]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='answer-section-container'>
          <div className='answer-section-title-container'>
            <h4 className='answer-section-title'>{sectionName}</h4>
            <div className='answer-section-description-container'>
              {sectionDesc.split('\n').map((line, index) => (
                <p className='answer-section-description' key={index}>
                  {line}
                </p>
              ))}
            </div>
          </div>

          <div className='section-list-questions'>
            {orderedQuestions.map((question: questionSentExtract) => {
              if (question.sectionId === sectionId)
                return (
                  <AnswerViewQuestion
                    addRemoveFollowUp={addRemoveFollowUp}
                    missingIcon={missingIconIds.ids.includes(question.id)}
                    clearFollowUps={
                      clearFollowUps.find((q) => q.id === question.id)?.clear ||
                      false
                    }
                    setClearFollowUps={toggleSetClearFollowUps}
                    followUpConditions={getFollowUpQuestionConditions(
                      question.id
                    )}
                    control={control}
                    removeFollowUp={removeFollowUp}
                    addFollowUp={addFollowUp}
                    setQuestionIconAnswers={handleSetQuestionIconAnswers}
                    setBackUpMultipleAnswers={handleSetBackUpMultipleAnswers}
                    toggleClear={clear}
                    setClear={setClear}
                    errors={errors}
                    key={question.id}
                    question={question}
                    register={register}
                  />
                );
            })}{' '}
            <div className='section-buttons'>
              {startPage && (
                <CustomButton
                  text={'Answer Survey'}
                  onClick={() => {
                    toggleNextSection(sectionId, false);
                  }}
                />
              )}
              {!lastSection && !startPage && (
                <>
                  <CustomButton
                    text={'Back'}
                    onClick={() => { toggleNextSection(sectionId, true); }}
                  />
                  <SubmitButton text={'Next section'} />
                </>
              )}

              {lastSection && (
                <>
                  <CustomButton
                    text={'Back'}
                    onClick={() => { toggleNextSection(sectionId, true); }}
                  />
                  <div>
                    <SubmitButton id={'review-btn'} text={'Review answers'} />
                    <SubmitButton
                      text={'Send in'}
                      onClick={() => {
                        setSendIn(true);
                      }}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

export default AnswerViewSection;
