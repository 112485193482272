import React, { useState } from 'react';
import {
  questionSurveyCreated,
  returnedSurveyCreated,
  sectionSurveyCreated,
  surveyFromSurveyBank,
} from '../../assets/types';
import './ViewSurvey.css';
import Modal from 'react-bootstrap/Modal';
import ViewSurveyQuestion from './ViewSurveyQuestion';
import { Accordion, Form } from 'react-bootstrap';

interface Props {
  survey: surveyFromSurveyBank | returnedSurveyCreated;
  showModal: boolean;
  handleCloseModal: () => void;
}

const ViewSurvey = ({ survey, showModal, handleCloseModal }: Props) => {
  const surveyNewType: returnedSurveyCreated = survey as returnedSurveyCreated;
  const [accordion, setAccordion] = useState(false);
  const orderedSections: sectionSurveyCreated[] = surveyNewType.sections.length
    ? survey.sectionOrder.map((id) => {
        const section = survey.sections.find(
          (obj) => obj.id === id
        ) as sectionSurveyCreated;
        const orderedQuestions = section.questionOrder.map(
          (questionId) =>
            section.questions.find(
              (question) => question.id === questionId
            ) as questionSurveyCreated
        );
        return {
          ...section,
          questions: orderedQuestions,
        };
      })
    : [];

  const getFollowUpQuestions = (
    questions: questionSurveyCreated[],
    questionId: string
  ) => {
    const list: questionSurveyCreated[] = [];
    questions.forEach((q) => {
      if (q?.parentQuestionId === questionId) {
        if (q?.condition !== undefined) {
          list.push(q);
        }
      }
    });
    return list;
  };

  const allQuestions = survey.sections
    .map((section) => {
      return section.questions;
    })
    .flat();

  return (
    <Modal
      show={showModal}
      onHide={handleCloseModal}
      backdrop={true}
      keyboard={true}
      size='lg'
    >
      <Modal.Header closeButton>
        <div className='show-survey-header-container'>
          <div className='show-survey-title'>
            <Modal.Title>{survey.title}</Modal.Title>
          </div>
          {orderedSections.length > 0 && (
            <div className='show-survey-toggle'>
              <Form.Check
                id='toggle-accordion'
                type='switch'
                onClick={() => setAccordion(!accordion)}
              />
              {accordion ? 'List sections' : 'Group sections'}
            </div>
          )}
        </div>
      </Modal.Header>
      <Modal.Body className='scrollable-modal-body'>
        {!accordion ? (
          orderedSections.map((section) => {
            return (
              <div key={section.id} className='show-section-sections'>
                <h5>{section.title}</h5>
                <div className='show-section-questions'>
                  {section.questions.map((question) => {
                    return (
                      <ViewSurveyQuestion
                        allQuestions={allQuestions}
                        key={question.id}
                        question={question}
                        followUp={getFollowUpQuestions(
                          allQuestions,
                          question.id
                        )}
                      />
                    );
                  })}
                </div>
              </div>
            );
          })
        ) : (
          <Accordion alwaysOpen>
            {orderedSections.map((section) => {
              return (
                <Accordion.Item eventKey={section.id} key={section.id}>
                  <Accordion.Header>{section.title}</Accordion.Header>
                  <Accordion.Body>
                    <div className='show-section-questions'>
                      {section.questions.map((question) => {
                        return (
                          <ViewSurveyQuestion
                            allQuestions={allQuestions}
                            key={question.id}
                            question={question}
                            followUp={getFollowUpQuestions(
                              section.questions,
                              question.id
                            )}
                          />
                        );
                      })}
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              );
            })}
          </Accordion>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ViewSurvey;
