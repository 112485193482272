import React, { useState, useEffect } from 'react';
import './SurveyRow.css';
import 'bootstrap/dist/css/bootstrap.css';
import './SurveyInstanceRow.css';
import { sectionType as Section, sectionType } from '../../assets/types';
import { Modal, OverlayTrigger, Popover } from 'react-bootstrap';
import { surveySentExtract } from '../../functions/Sent_functions/surveySent';
import { getSectionSentBySurveyId } from '../../functions/Sent_functions/sectionSent';
import {
  answerGetFinishedSections,
  answerGetSectionQuestions,
  answerGetSurvey,
  typeReturnSurvey,
} from '../../functions/Sent_functions/sentUtils';
import { SectionWithQuestion } from '../answerView/AnswerViewInspectAnswers';
import { questionSentExtract } from '../../functions/Sent_functions/questionSent';
import { linkIcon } from '../../assets/Icons';
import hashString from '../../functions/scripts/urlScript';

export type SurveyInstanceProps = {
  id: string;
  title: string;
  //titleEmoji?: string;
  desc: string;
  creationdate: string;
  department: string;
  team: string[];
  taggar: string[];
  process: string;
  anonymous: boolean;
  patientId?: string;
  sentDate: string;
  completedDate: string;
  completed: boolean;
  viewedby: string[];
  //sections?: Section[];
  //parentSurvey?: any;
  parentSurveyId: string;
};

const SurveyInstanceRow = ({
  surveyInstance,
  idx,
  isPatientSearch,
  isMySurvey,
  middleUrl,
  completedSection,
}: {
  surveyInstance: surveySentExtract;
  idx: number;
  isPatientSearch?: boolean;
  isMySurvey: boolean;
  middleUrl?: string;
  completedSection?: boolean;
}) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [orderedSections, setOrderedSections] =
    useState<SectionWithQuestion[]>();
  const handleCloseModal = () => setShowModal(false);
  const [url, setUrl] = useState<string>();

  const [surveyData, setSurveyData] = useState<typeReturnSurvey>();

  const handleGetUrl = () => {
    if (!surveyInstance.anonymous) {
      hashString(surveyInstance.id).then((url) => {
        setUrl(url);
      });
    } else {
      setUrl(middleUrl ? middleUrl : 'knas?');
    }
  };

  useEffect(() => {
    handleGetUrl();
    getSectionSentBySurveyId(surveyInstance.id).then((sections) => {
      const sectionsOrdered: sectionType[] = surveyInstance.sectionOrder.map(
        (sectionId) => {
          return sections.find(
            (section) => section.id === sectionId
          ) as sectionType;
        }
      );
      const getSectionQuestionsPromises = sectionsOrdered.map((section) => {
        return answerGetSectionQuestions(section?.id).then((res) => {
          const orderedQuestions: questionSentExtract[] = res.length
            ? section.questionOrder.map(
                (id) => res.find((obj) => obj.id === id) as questionSentExtract
              )
            : [];
          return { ...section, questions: orderedQuestions };
        });
      });
      Promise.all(getSectionQuestionsPromises).then((sectionsWithQuestions) => {
        setOrderedSections(sectionsWithQuestions);
      });
    });
  }, [surveyInstance]); //om surveyrow visar samma som sidan innan är det pga detta mst göras när datan byts

  return (
    <div className='survey-instance' onClick={() => setShowModal(!showModal)}>
      <div className='col-instance'>
        {isPatientSearch
          ? surveyInstance.title
          : surveyInstance.anonymous
          ? 'anon'
          : surveyInstance.patientId}
      </div>
      <div className='col-instance'>
        {surveyInstance.process && surveyInstance.process !== ''
          ? surveyInstance.process
          : 'None'}
      </div>
      <div className='col-instance'>
        {surveyInstance.sentDate.toString().slice(0, 10)}
      </div>
      <div className='col-instance'>
        {surveyInstance.completedDate ? surveyInstance.completedDate?.toString().slice(0, 10) : 'Not completed'}
      </div>
      <div className='col-instance'>
        <div
          className='circle'
          id={surveyInstance.completed ? 'green-circle' : 'orange-circle'}
        ></div>
      </div>

      {!completedSection && (
        <OverlayTrigger
          rootClose
          trigger='click'
          key={'top'}
          placement={'top'}
          overlay={
            <Popover id={`popover-positioned-${'top'}`}>
              <Popover.Body>
                {' '}
                https://forms.datatovalue.se/empty/{url}
              </Popover.Body>
            </Popover>
          }
        >
          <button
            id='instance-url-btn'
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            {linkIcon}
          </button>
        </OverlayTrigger>
      )}

      <>
        <Modal
          show={showModal}
          onHide={handleCloseModal}
          backdrop='static'
          keyboard={false}
          size='lg'
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {surveyInstance.title}
              <br></br>
              <em>
                <h6>Patient: {surveyInstance.patientId || 'Anonymous'}</h6>
              </em>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='section-list-questions'>
              {orderedSections &&
                orderedSections?.length !== 0 &&
                orderedSections.map((section) => {
                  return (
                    <div
                      key={section.id}
                      className='survey-inspect-section-container'
                    >
                      <h4>{section.title}</h4>
                      <>
                        {section.questions.map((question) => {
                          return (
                            <div
                              key={question.id}
                              className='survey-inspect-question-container row'
                            >
                              <div id='survey-inspect-question-title'>
                                <span>{question.title}</span>
                              </div>
                              <div id='survey-inspect-question-answer'>
                                {question.answer.map((ans) => {
                                  return (
                                    <span key={ans + question.id}> {ans} </span>
                                  );
                                })}
                              </div>
                            </div>
                          );
                        })}
                      </>
                      <hr />
                    </div>
                  );
                })}
            </div>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      </>
    </div>
  );
};

export default SurveyInstanceRow;
