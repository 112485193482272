import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

interface Props {
  text: string;
  onClick: () => void;
}

const DeleteButton = ({ text, onClick }: Props) => {
  const [showModal, setShowModal] = useState(false);

  const handleDelete = () => {
    // Delete the item here
    setShowModal(false);
    onClick();
  };

  return (
    <>
      <Button variant='danger' onClick={() => setShowModal(true)}>
        {text}
      </Button>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this section?</Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button variant='danger' onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeleteButton;
