import * as uRLrelation from './URLrelation'

/**
 * Gets the id of a survey based on the URL
 * @param url the url to search for
 * @returns string, the id of the survey
 */
export async function getSurveyIdByURL(url: string):Promise<string>{
    try{
        const URLobj: uRLrelation.URLrelation = await uRLrelation.getURLrelationById(url);
        const surveyId: string = URLobj.sentId;
        return surveyId;
    }
    catch(error){
        console.log(error)
        throw(error)
    }
};

/**
 * checks if an url is an anonymous survey.
 * @param url, the url
 * @returns boolean, true if it is anonymous otherwise false.
 */
export async function getAnonymByURL(url: string):Promise<boolean>{
    try{
        const URLobj: uRLrelation.URLrelation = await uRLrelation.getURLrelationById(url);
        const anonym = URLobj.anonymous; 
        return anonym;
    }
    catch(error){
        console.log(error)
        throw(error)
    }
};
