import React, { useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import CustomButton from '../Buttons/CustomButton';
import Modal from 'react-bootstrap/Modal';
import './QuestionInQB.css';
import ShowQuestionInQuestionBank from './ShowQuestionInQuestionBank';
import { questionInQBType } from '../../assets/types';

const QuestionInQuestionBank = ({
  question,
  idx,
  onChecked,
}: {
  question: questionInQBType;
  idx: number;
  onChecked: (question: questionInQBType, checked: boolean) => void;
}) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    setIsChecked(checked);
    onChecked(question, checked);
  };

  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  return (
    <div className='question-in-qb'>
      <div className='accordion-question-in-qb'>
        <Accordion.Item eventKey={idx.toString()}>
          <Accordion.Header>
            <input
              id='radio-select'
              type='checkbox'
              checked={isChecked}
              onChange={handleCheckboxChange}
              onClick={(event) => event.stopPropagation()}
            ></input>
            <div>{question.title} </div>
          </Accordion.Header>

          <Accordion.Body>
            <div className='body-top'>
              <u>Process: {question.process} </u>
              <u>Mandatory: {question.mandatory ? 'Yes' : 'No'} </u>
            </div>

            <div className='info-row'>
              <u>
                Tags:
                {question.keywords &&
                  question.keywords.map((keyword: string, index: number) => {
                    return (index ? ', ' : ' ') + keyword;
                  })}
              </u>
            </div>

            <em>{question.description}</em>

            <div className='button-section-qb'>
              <div className='button'>
                <CustomButton
                  text={'Show question'}
                  onClick={handleShowModal}
                />
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </div>

      {showModal && (
        <Modal
          show={showModal}
          onHide={handleCloseModal}
          backdrop={true}
          keyboard={true}
          centered
          size='lg'
          style={{ zIndex: '10001' }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Chosen question</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ShowQuestionInQuestionBank question={question} />
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
};

export default QuestionInQuestionBank;
