import '../SideNavBar/SideNavBar.css';
import Logo from '../../assets/images/d2vlogo.png';
import './TopBar.css';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import { useAuth0 } from '@auth0/auth0-react';
import { logOutIcon } from '../../assets/Icons';

interface Props {
  breadcrumbItems: { text: string; link?: string }[];
}

const TopBar = ({ breadcrumbItems }: Props) => {
  const { logout } = useAuth0();
  const handleLogout = () => {
    logout(); //auth0 built in logout function
  };

  return (
    <div className='topbar'>
      <div className='white'>
        <img id='logo' src={Logo} alt='Logo' />
        <span className='topbar-text'>
          {' '}
          <span id={'actdata'}>ACTDATA</span> BY DATA2VALUE
        </span>
      </div>
      <div className='blue'>
        <Breadcrumbs breadcrumbItems={breadcrumbItems} />
        <span id='logout-button' onClick={handleLogout}>
          Logout {logOutIcon}
        </span>
      </div>
    </div>
  );
};

export default TopBar;
