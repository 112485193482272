import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './SurveyInstancesSection.css';
import SurveyInstanceRow from './SurveyInstanceRow';
import Pagination from '../Pagination/Pagination';
import { surveySentExtract } from '../../functions/Sent_functions/surveySent';

const SurveyInstanceSection = ({
  surveyInstanceList,
  isPatientSearch,
  middleUrl,
  completedSection,
  recPerPage

}: {
  surveyInstanceList: surveySentExtract[];
  isPatientSearch?: boolean;
  middleUrl?: string;
  completedSection?: boolean;
  recPerPage?: number;

}) => {
  const [currentData, setCurrentData] = useState(surveyInstanceList);
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = recPerPage ? recPerPage : 5;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  let currentRecords: any[] = [];
  let nPages = 0;
  if (currentData !== undefined) {
    currentRecords = currentData.slice(firstIndex, lastIndex);
    nPages = Math.ceil(currentData.length / recordsPerPage);
  }
  const [maxPageNumbers, setMaxPageNumbers] = useState(
    window.innerWidth < 400 ? 3 : 4
  );

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 800) {
        setMaxPageNumbers(4);
      }
      if (window.innerWidth < 400) {
        setMaxPageNumbers(3);
      }
      if (window.innerWidth < 340) {
        setMaxPageNumbers(1);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  });

  useEffect(() => {
    setCurrentData(surveyInstanceList);
  }, [surveyInstanceList]);

  return (
    <div className='survey-instance-section'>
      <div className='survey-instance-section-search'>
      </div>

      <div className='survey-instance-section-titles '>
        <div className='col-instance-title'>
          <b>{isPatientSearch ? 'Title' : 'Patient ID'}</b>
        </div>
        <div className='col-instance-title'>
          <b>Process</b>
        </div>
        <div className='col-instance-title'>
          <b>Date Sent</b>
        </div>
        <div className='col-instance-title'>
          <b>Date Completed</b> 
        </div>
        <div className='col-instance-title'>
          <b>Status</b>
        </div>
        {!completedSection &&
          <div className='col-instance-title'> 
          
          <b>Link</b>
        </div>}
      </div>
      {currentRecords.map(
        (surveyInstance: surveySentExtract, index: number) => (
          <SurveyInstanceRow
            key={index}
            surveyInstance={surveyInstance}
            idx={index}
            isMySurvey={true}
            isPatientSearch={isPatientSearch}
            middleUrl={middleUrl}
            completedSection={completedSection}
          />
        )
      )}

      <div className='survey-instance-section-pagination'>
        {
        <Pagination
          maxPageNumbers={maxPageNumbers}
          nPages={nPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />}
      </div>
    </div>
  );
};

export default SurveyInstanceSection;
