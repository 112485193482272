import React, { useState, useEffect } from 'react';
import './SurveyCreate.css';

interface Props {
  title: string;
  edit: boolean;
  toggleChangedTitle: (title: string) => void;
}

const SurveyTitleCreate = ({ title, edit, toggleChangedTitle }: Props) => {
  const [sectionTitle, setSurveyTitle] = useState(title);
  useEffect(() => {
    if (sectionTitle.length === 0 && edit === false) {
      setSurveyTitle('Untitled Survey');
      toggleChangedTitle('Untitled Survey');
    } else {
      toggleChangedTitle(sectionTitle);
    }
  }, [edit]);

  const enterHandler = (event: any) => {
    if (event.key === 'Enter') {
      toggleChangedTitle(sectionTitle);
    }
  };

  return (
    <div className='SurveyTitle'>
      {!edit ? (
        <h1>{title}</h1>
      ) : (
        <input
          type='text'
          placeholder='Survey title'
          value={sectionTitle}
          onChange={(e) => setSurveyTitle(e.target.value)}
          onKeyUp={enterHandler}
        />
      )}
    </div>
  );
};

export default SurveyTitleCreate;
