import React, { useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import '../SectionCreate/SectionCreate.css';
import { Container } from 'react-bootstrap';

interface Props {
  description: string;
  edit: boolean;
  toggleChangedDescription: (description: string) => void;
}

const QuestionCreateDescription = ({
  description,
  edit,
  toggleChangedDescription,
}: Props) => {
  const [questionDescription, setQuestionDescription] = useState(description);
  useEffect(() => {
    toggleChangedDescription(questionDescription);
  }, [edit]);

  return (
    <Container className={'questDescriptionDiv'}>
      {!edit ? (
        <div>
          {' '}
          {questionDescription.split('\n').map((line, index) => (
            <p key={index}>{line}</p>
          ))}
        </div>
      ) : (
        <textarea
          value={questionDescription}
          onChange={(e) => setQuestionDescription(e.target.value)}
          className='questionDescInput'
        />
      )}
    </Container>
  );
};

export default QuestionCreateDescription;
