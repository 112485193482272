import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { useAuth0 } from '@auth0/auth0-react';
import './LoginButton.css';

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();
  const handleLogin = async () => {
    try {
      await loginWithRedirect();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className='center'>
      <button
        type='button'
        className='btn btn-outline-secondary largerButton'
        onClick={handleLogin}
      >
        Login
      </button>
    </div>
  );
};

export default LoginButton;
