import React, { useState, useEffect } from 'react';
import SurveyBank from '../SurveryBank/SurveyBank';
import SurveyCreate from '../../components/SurveyCreate/SurveyCreate';
import {
  questionTypeRetrive,
  sectionFromSurveyBank,
  surveyFromSurveyBank,
} from '../../assets/types';
import { getSurveyFromSurveyBank } from '../../functions/Template_functions/tempUtils';
import { useLocation } from 'react-router-dom';

function CreateSurveyFromTemplate() {
  const location = useLocation();
  const [survey, setSurvey] = useState<surveyFromSurveyBank>();
  const [surveyBankPage, setSurveyBankPage] = useState<boolean>(true);
  const [started, setStarted] = useState<boolean>(false);

  const handleSetSurveyTemplate = (id: string) => {
    getSurveyFromSurveyBank(id).then((result: any) => {
      const orderedSections: sectionFromSurveyBank[] = result.sections.length
        ? result.sectionOrder.map((id: string) => {
            return result.sections.find(
              (obj: sectionFromSurveyBank) => obj.id === id
            ) as sectionFromSurveyBank;
          })
        : [];
      result.sections = orderedSections;
      setSurvey(result);
      setSurveyBankPage(false);
      setStarted(true);
    });
  };

  useEffect(() => {
    if (location.state && !started) {
      handleSetSurveyTemplate(location.state);
    }
  }, []);

  return (
    <>
      {surveyBankPage ? (
        <SurveyBank
          createSurvey={true}
          setSurveyTemplate={handleSetSurveyTemplate}
        />
      ) : (
        <div className='page'>
          <SurveyCreate survey={survey} />
        </div>
      )}
    </>
  );
}

export default CreateSurveyFromTemplate;
