import createClient from '../axios';
import { Answer } from '../../assets/types';
import { keywordExtract } from './keywords';

export type questionInfoQopt = {
    qoptions: string[];
    emojiOpt: string[];
    id: string; 
    title: string;
    titleEmoji?: string;
    description: string;
    type: string;
    mandatory: boolean;
    bounds: number[];
    condition: string[];
    multipleChoice: boolean;
    keywords?: string[];
}; 

export type questionInfo = {
    id: string; 
    title: string;
    titleEmoji?: string;
    description: string;
    type: string;
    mandatory: boolean;
    answers: Answer[];
    bounds: number[];
    condition: string[];
    multipleChoice: boolean;
    keywords?: string[];
}; 

export type questionQBCreated = {
    title: string;
    titleEmoji?: string;
    description: string;
    type: string;
    mandatory: boolean;
    answers: Answer[];
    bounds: number[];
    condition: string[];
    multipleChoice: boolean;
    keywords?: string[];
};

export type questionQBCreatedQopt = {
    title: string;
    titleEmoji?: string;
    description: string;
    type: string;
    mandatory: boolean;
    bounds: number[];
    condition: string[];
    multipleChoice: boolean;
    keywords?: string[];
    qoptions: string[];
    emojiOpt: string[];
};

export type questionUpdate = { //incomplete type!!!
    id: string;
    title?: string;
    titleEmoji?: string;
    description?: string;
    type?: string;
    qoptions: string[];
    emojiOpt: string[];
    bounds?: number[];
    condition?: string[];
    mandatory?: boolean;
    multipleChoice?: boolean;
    keywords?: string[];
    parentQuestionId?: string;
};

export type questionTitles = {
    title: string;
};

/**
 * takes a question and rewrites the answers 
 * @param questions questionInfoQopt
 * @returns 
 */
export function reWriteAnswers(questions : questionInfoQopt[]){
    let newType : questionInfo[] =[]
    questions.map((question) => {
    if(question.qoptions.length !== 0|| question.qoptions !== undefined){
        const answers: Answer[]= question.qoptions.map((qoption, index) => ({
            answer: qoption as string,
            emoji: (question.emojiOpt ? question.emojiOpt[index] : undefined ) as string,
        }));
    if(answers){
        const updatedData : questionInfo  = {
            id: question.id,
            title: question.title,
            titleEmoji: question.titleEmoji,
            description: question.description,
            type: question.type,
            mandatory: question.mandatory,
            answers: answers,
            bounds: question.bounds,
            condition: question.condition,
            multipleChoice: question.multipleChoice,
            keywords: question.keywords,
        }
        newType.push(updatedData)
    }
    else{
        const updatedData : questionInfo  = {
            id: question.id,
            title: question.title,
            titleEmoji: question.titleEmoji,
            description: question.description,
            type: question.type,
            mandatory: question.mandatory,
            answers: [],
            bounds: question.bounds,
            condition: question.condition,
            multipleChoice: question.multipleChoice,
            keywords: question.keywords,
        }
        newType.push(updatedData)
    }
    }})
    return newType
}

/**
 * Rewrites questionOptions
 * @param question questionInfoQopt
 * @returns 
 */
export function reWriteQops(question : questionQBCreated){
        const qoptions = question.answers.map((answer) => answer.answer);
        const emojiOpt = question.answers.map((answer) => answer.emoji);
        const updatedData : questionQBCreatedQopt  = {
            title: question.title,
            titleEmoji: question.titleEmoji,
            description: question.description,
            type: question.type,
            mandatory: question.mandatory,
            qoptions: qoptions,
            emojiOpt: emojiOpt,
            bounds: question.bounds,
            condition: question.condition,
            multipleChoice: question.multipleChoice,
            keywords: question.keywords,
        }
    return updatedData;
}

/**
 * Gets all questions in question bank
 * @returns questionInfo[], an array containing all questions.
 */
export async function getQuestionBank():Promise<questionInfo[]> {
    const client = await createClient();
    return client.get('/questionbank')
        .then((response) => {
            const data : questionInfoQopt[] = response.data
            const newData = reWriteAnswers(data)
            return newData //This returns all data in Question bank

        })
        .catch((error) => {
            console.log(error);
            return []; // return empty array if there is an error
          });
};


/**
 * Gets a question from the questionbank by ID 
 * @param id if of the question
 * @returns questionInfo, the question and its information.
 */
export async function getQuestionBankById(id:string):Promise<questionInfo> { //incomplete type
    const client = await createClient();
    return client.get('/questionbank/' + id) 
    .then((response) => {
        return response.data
    })
    .catch((error) => console.log(error))
};

/**
 * Gets questions from the questionbank based on keyword.
 * @param id if of the keyword.
 * @returns questionInfo[], an array containing questions.
 */
export async function getQuestionBankByKeywordId(id:string):Promise<questionInfo[]> { //incomplete type
    const client = await createClient();
    return client.get('/questionbank/keywords/' + id)
    .then((response) => {
        return response.data
    })
    .catch((error) => console.log(error))
};

/**
 * Creates a question in the questionbank.
 * @param dataAns, the question information
 * @returns string, the id of the created question.
 */
export async function createQuestionBank(dataAns:questionQBCreated):Promise<string> { 
    const data = reWriteQops(dataAns);
    const client = await createClient();
    return client.post('/questionbank', data)
    .then((response) => {
        return response.data.id
    })
    .catch((error) => {
        console.log(error);
        return []; // return empty array if there is an error
      });
  };

  /**
 * Updates the questionbank.
 * @param data questionUpdate containing update information.
 * @returns string, the id of the updated question.
 */
export async function updateQuestionBank(data:questionUpdate):Promise<string> { 
    const client = await createClient();
    if (data.keywords) {
        const keywordIds: { id: string }[] = [];
        for (let i = 0; i < data.keywords.length; i++) {
            keywordIds.push({ id: data.keywords[i] });
        };
        const dataUpd = {
            id: data.id,
            title: data.title,
            titleEmoji: data.title,
            description: data.description,
            type: data.type,
            qoptions: data.qoptions,
            emojiOpt: data.emojiOpt,
            bounds: data.bounds,
            condition: data.condition,
            mandatory: data.mandatory,
            multipleChoice: data.multipleChoice,
            keywords: {
                connect: keywordIds,
            },
            parentquestionId: data.parentQuestionId
        }
        return client.put('/questionbank/' + dataUpd.id, dataUpd)
        .then((response) => {
            return response.data.id;
        })
        .catch((error) => console.log(error));
    }
    return client.put('/questionbank/' + data.id, data)
    .then((response) => {
        return response.data.id
    })
    .catch((error) => console.log(error))
};

/**
 * deletes a question from the questionbank. 
 * @param id id of the question.
 * @returns string, the id of the deleted question.
 */
export async function deleteQuestionBank(id:string):Promise<string> {
    const client = await createClient();
    return client.put('/questionbank/' + id)
    .then((response) => {
        return response.data.id
    })
    .catch((error) => console.log(error))
};

/**
 * Gets all titles of questions from the questionbank
 * @returns questionTitles[], an array of all titles
 */
export async function titleAllQuestionBank():Promise<questionTitles[]> {
    const client = await createClient();
    return client.get('/questionbank')
    .then((response) => {
        return response.data.title //This returns the Title of all Questions
    })
    .catch((error) => console.log(error))
};

export async function getQuestionBankByKeywords(data: string[]): Promise<questionInfoQopt>{
    const client = await createClient();
    return client.get('/questionbank/bykeywords/', {params: data})
        .then((response)=>{
        return response.data
        })
        .catch((error)=>console.log(error))
};

/**
 * Gets all keywords for a question in bank
 * @param id id of the question to get keywords for
 * @returns keywordExtract[], an array of all keywords
 */
export async function getKeywordsForQuestionBank(id: string): Promise<keywordExtract[]>{
    const client = await createClient();
    return client.get('/keywords/question/' + id)
        .then((response)=>{
        return response.data
        })
        .catch((error)=>console.log(error))
}
