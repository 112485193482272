import React from 'react';
import LoginButton from '../../components/LoginButton/LoginButton';

function Login() {
  return (
    <main>
      <LoginButton />
    </main>
  );
}

export default Login;
