import React from 'react';
import './SendSurvey.css';
import { useForm, SubmitHandler } from 'react-hook-form';
import CollectInfo from './CollectInfo';
import SubmitButton from '../Buttons/SubmitButton';

interface Props {
  ids: string[];
  handleSend: (data: any) => void;
  anonymous: boolean;
}

const SendSurveyInfo = ({ ids, handleSend, anonymous }: Props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<any>();

  const onSubmit: SubmitHandler<any> = (data) => {
    handleSend(data);
  };

  return (
    <div className='send-survey-info'>
      <form onSubmit={handleSubmit(onSubmit)}>
        {!anonymous ? (
          ids.map((id) => {
            return (
              <CollectInfo
                key={id}
                id={id}
                register={register}
                errors={errors}
              />
            );
          })
        ) : (
          <CollectInfo
            key={'anonymous'}
            id={'anonymous'}
            register={register}
            errors={errors}
          />
        )}
        <div className='send-survey-done'>
          <SubmitButton text='send' />
        </div>
      </form>
    </div>
  );
};

export default SendSurveyInfo;
