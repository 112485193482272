import Container from 'react-bootstrap/Container';
import Accordion from 'react-bootstrap/Accordion';
import { useState, useEffect, useRef } from 'react';
import './SectionCreate.css';
import CustomButton from '../Buttons/CustomButton';
import DoneButton from '../Buttons/DoneButton';
import SectionTitleCreate from './SectionTitleCreate';
import SectionDescriptionCreate from './SectionDescriptionCreate';
import ListQuestionsInSection from './ListQuestionsInSection';
import {
  sectionType,
  questionFromQuestionBank,
  questionTypeRetrive,
  childEditing,
} from '../../assets/types';
import DeleteButton from '../Buttons/DeleteButton';
import Select from 'react-select';

interface Props {
  surveyId: string;
  section: sectionType;
  currentSectionIndex: number;
  sectionsTotal: number;
  newSection: boolean;
  questionsFromTemplate?: questionTypeRetrive[];
  questionsFromBank?: questionFromQuestionBank;
  setChildIsEditing: (id: string, isEditing: boolean) => void;
  childrenEditing: childEditing[];
  handleUpdateSection: (section: sectionType) => void;
  moveSection: (sectionId: string, newIndex: number) => void;
  deleteMe?: (id: string) => void;
  handleToggle: (eventKey: string) => void;
  setAddNewQuestionFromBank: (addNew: boolean, id: string) => void;
  handleFetchQuestionBank: () => void;
  setNoServerResponse: (noResponse: boolean) => void;
}

const SectionCreate = ({
  surveyId,
  section,
  currentSectionIndex,
  sectionsTotal,
  newSection,
  questionsFromTemplate,
  questionsFromBank,
  childrenEditing,
  setChildIsEditing,
  handleUpdateSection,
  moveSection,
  deleteMe,
  handleToggle,
  setAddNewQuestionFromBank,
  handleFetchQuestionBank,
  setNoServerResponse,
}: Props) => {
  //DATA FROM DATABASE
  //states
  const [sectionId, setSectionId] = useState<string>(section.id);
  const [sectionTitle, setSectionTitle] = useState(section.title);
  const [sectionTitleEmoji, setSectionTitleEmoji] = useState(
    section.titleEmoji
  );
  const [sectionDescription, setSectionDescription] = useState(
    section.description
  );

  const [questionOrder, setQuestionOrder] = useState<string[]>(
    section.questionOrder
  );

  const [editMode, setEditMode] = useState(newSection);
  const [isCreated, setIsCreated] = useState<boolean>(false);
  const questionOrderStart = useRef<string[]>(section.questionOrder);
  //title & description

  //meta data

  //answer choices

  //collapsible

  //functions
  function createOptionsList(
    strings: string[]
  ): { label: string; value: string }[] {
    if (!strings || strings[0] === '')
      return [] as { label: string; value: string }[];
    return strings.map((string) => ({ label: string, value: string }));
  }

  const toggleEditMode = () => {
    setChildIsEditing(sectionId, true);
    setEditMode(true);
  };

  const deleteSection = () => {
    if (deleteMe !== undefined) {
      deleteMe(sectionId);
    }
  };

  const previousValues = useRef({
    isCreated,
    sectionTitle,
    sectionTitleEmoji,
    sectionDescription,
    questionOrder,
  });

  useEffect(() => {
    //todo vet inte riktigt om den här updaterar när den ska? Lite oklart, skulle behöva se db
    if (
      previousValues.current.sectionTitle !== sectionTitle ||
      previousValues.current.sectionDescription !== sectionDescription ||
      previousValues.current.questionOrder !== questionOrder
    ) {
      const section: sectionType = {
        id: sectionId,
        title: sectionTitle,
        titleEmoji: '',
        description: sectionDescription,
        questionOrder: questionOrder.some((q) => q === undefined)
          ? []
          : questionOrder,
        surveyId: surveyId,
      };
      handleUpdateSection(section);
      previousValues.current = {
        isCreated,
        sectionTitle,
        sectionTitleEmoji,
        sectionDescription,
        questionOrder,
      };
    }
  }, [sectionTitle, sectionDescription, questionOrder]);

  return (
    <>
      <Accordion.Item eventKey={section.id}>
        <Accordion.Header
          bsPrefix='accordion-header-new'
          onClick={() => {
            return !editMode ? handleToggle(section.id) : null;
          }}
        >
          <Container fluid>
            <SectionTitleCreate
              title={sectionTitle}
              edit={editMode}
              toggleChangedTitle={(newTitle) => {
                setSectionTitle(newTitle);
              }}
            />
          </Container>
        </Accordion.Header>
        <Accordion.Body>
          <Container fluid className='SectionMetaDataContainer'>
            <SectionDescriptionCreate
              description={sectionDescription}
              edit={editMode}
              toggleChangedDescription={(newDescription) => {
                setSectionDescription(newDescription);
              }}
            />
            {!editMode ? (
              <div className='SectionMetaData'>
                <CustomButton
                  onClick={() => {
                    toggleEditMode();
                  }}
                  text='Edit'
                />
              </div>
            ) : (
              <div className='sec-order-and-btns'>
                <div className='sec-order'>
                  <b>Section order:</b>
                  <Select
                    defaultValue={createOptionsList([
                      (currentSectionIndex + 1).toString(),
                    ])}
                    options={createOptionsList(
                      Array.from({ length: sectionsTotal }, (_, i) =>
                        (i + 1).toString()
                      )
                    )}
                    onChange={(value) => {
                      moveSection(sectionId, parseInt(value?.value ?? '0') - 1);
                    }}
                  />
                </div>
                <div className='section-create-btns'>
                  <DeleteButton
                    onClick={() => {
                      deleteSection();
                    }}
                    text='Delete'
                  />
                  <DoneButton
                    onClick={() => {
                      setIsCreated(true);
                      setEditMode(false);
                      setChildIsEditing(sectionId, false);
                    }}
                    text='Done'
                  />
                </div>
              </div>
            )}
          </Container>

          <hr />
          <ListQuestionsInSection
            questionOrderStart={questionOrderStart.current}
            setNoServerResponse={setNoServerResponse}
            setChildIsEditing={setChildIsEditing}
            childrenEditing={childrenEditing}
            handleFetchQuestionBank={handleFetchQuestionBank}
            sectionId={sectionId}
            questionsFromTemplate={questionsFromTemplate}
            questionOrder={questionOrder}
            setQuestionOrder={setQuestionOrder}
            setAddNewQuestionFromBank={setAddNewQuestionFromBank}
            questionsFromBank={questionsFromBank}
          />
        </Accordion.Body>
      </Accordion.Item>
    </>
  );
};

export default SectionCreate;
