import { Accordion, Button } from 'react-bootstrap';
import { Answer, childEditing, questionType } from '../../assets/types';
import './QuestionCreate.css';
import QuestionCreate from './QuestionCreate';
import FollowUpConditions from './FollowUpConditions';
import { useEffect, useState, useRef } from 'react';

interface Props {
  addNewQuestion: (parentId?: string) => void;
  newQuestion: boolean;
  handleFetchQuestionBank: () => void;
  childrenEditing: childEditing[];
  toggleFollowUpIsEditing: (id: string, edit: boolean) => void;
  setChildIsEditing: (id: string, isEditing: boolean) => void;
  question: questionType;
  conditionsFromStart: string[];
  handleAddConditions: (
    newConditions: string[],
    remove: string[],
    question: string
  ) => void;
  answers: Answer[];
  removeQuestion: (question: questionType) => void;
  questionsList: questionType[];
  updateQuestion: (question: any) => void;
  childCount: number;
  addNewFollowUp: boolean;
  setAddNewFollowUp: (addNewFollowUp: boolean) => void;
}

const FollowUpQuestion = ({
  setAddNewFollowUp,
  questionsList,
  updateQuestion,
  childCount,
  addNewQuestion,
  newQuestion,
  childrenEditing,
  handleFetchQuestionBank,
  toggleFollowUpIsEditing,
  setChildIsEditing,
  question,
  conditionsFromStart,
  handleAddConditions,
  addNewFollowUp,
  answers,
  removeQuestion,
}: Props) => {
  const [missingConditions, setMissingConditions] = useState<boolean>(false);

  const [conditions, setConditions] = useState<string[]>(
    conditionsFromStart ? conditionsFromStart : []
  );

  const [edit, setEdit] = useState<boolean>(addNewFollowUp ? true : false);

  const getFollowUpQuestions = (questionId: string) => {
    return questionsList.filter((q) => q?.parentQuestionId === questionId);
  };

  const getFollowUpQuestionsConditions = (questionId: string) => {
    const followUpQuestions = questionsList.filter(
      (q) => q?.parentQuestionId === questionId
    );
    return followUpQuestions.map((q) => q.condition).flat();
  };

  return (
    <div>
      <div className='follow-up-buttons-container'>
        <FollowUpConditions
          setAddNewFollowUp={setAddNewFollowUp}
          edit={edit}
          setEdit={setEdit}
          toggleFollowUpIsEditing={toggleFollowUpIsEditing}
          setChildIsEditing={setChildIsEditing}
          questionId={question.id as string}
          conditionsFromTemp={question.condition || []}
          handleAddConditions={handleAddConditions}
          setConditions={setConditions}
          choices={answers.map((ans) => ans.answer)}
          setMissingConditions={setMissingConditions}
        />
        <Button
          style={{ height: '40px' }}
          variant='danger'
          onClick={() => removeQuestion(question)}
        >
          Delete
        </Button>
      </div>
      <p className='missing-conditions'>
        {missingConditions &&
          'Please add conditions before saving or adding a follow up question'}
      </p>
      <QuestionCreate
        followUpConditionsFromTemp={getFollowUpQuestionsConditions(
          question.id as string
        )}
        childrenEditing={childrenEditing}
        toggleFollowUpEditingProp={toggleFollowUpIsEditing}
        followUpConditionEditing={edit}
        setChildIsEditing={setChildIsEditing}
        setMissingConditions={setMissingConditions}
        conditionsFromParent={conditions}
        handleFetchQuestionBank={handleFetchQuestionBank}
        removeQuestion={removeQuestion}
        key={question.id}
        question={question}
        addNewQuestion={addNewQuestion}
        newQuestion={newQuestion}
        followUp={getFollowUpQuestions(question.id as string)}
        updateQuestion={updateQuestion}
        questionsList={questionsList}
        childCount={childCount}
      />
    </div>
  );
};

export default FollowUpQuestion;
