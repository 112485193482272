import React from 'react';
import SurveyCreate from '../../components/SurveyCreate/SurveyCreate';

function CreateNewSurvey() {
  return (
    <div className='page'>
      <SurveyCreate />
    </div>
  );
}

export default CreateNewSurvey;
