import { Emoji } from 'emoji-picker-react';
import '../answerView/AnswerViewQuestion.css';
import { imageIcon } from '../../assets/Icons';
import { Answer } from '../../assets/types';

interface Props {
  answers?: Answer[];
  type: string;
  multipleChoice: boolean;
}

const ViewSurveyListAnswers = ({ answers, type, multipleChoice }: Props) => {
  if (type === 'image') {
    return (
      <div className='imageContainer'>
        {imageIcon} <p>Not yet implemented</p>
      </div>
    );
  }

  if (type === 'multiple choice') {
    const inputType = multipleChoice ? 'checkbox' : 'radio';
    return (
      <>
        {answers &&
          answers.map((answer: Answer) => (
            <div
              key={answer.answer + 'div container'}
              className='listAnswersDiv'
            >
              <div className='inputButtons'>
                <input
                  key={answer.answer}
                  type={inputType}
                  value={answer.answer}
                  disabled={true}
                />
              </div>
              <Emoji unified={answer.emoji} size={25} />
              <div className='answerTextDiv'>{answer.answer}</div>
            </div>
          ))}
      </>
    );
  }

  if (type === 'icon') {
    return (
      <>
        {answers &&
          answers.map((answer) => (
            <div
              key={answer.answer + 'div container'}
              className='listAnswersDiv'
            >
              <button className='iconButton'>
                <Emoji unified={answer.emoji} size={40} />
              </button>
              <div className='altTextRemoveContainer'>
                <div className='iconAltTextDiv'>alt text: {answer.answer}</div>
              </div>
            </div>
          ))}
      </>
    );
  }
  if (type === 'text') {
    return (
      <>
        <textarea disabled={true} />
      </>
    );
  }
  return (
    <>
      <input type={type} disabled={true} />
    </>
  );
};

export default ViewSurveyListAnswers;
