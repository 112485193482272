import React, { useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import './SurveyCreate.css';

interface Props {
  description: string;
  edit: boolean;
  toggleChangedDescription: (description: string) => void;
}

const SurveyDescriptionCreate = ({
  description,
  edit,
  toggleChangedDescription,
}: Props) => {
  const [surveyDescription, setSurveyDescription] = useState(description);
  useEffect(() => {
    if (surveyDescription.length === 0 && edit === false) {
      setSurveyDescription(' ');
      toggleChangedDescription(' ');
    } else {
      toggleChangedDescription(surveyDescription);
    }
  }, [edit]);

  const enterHandler = (event: any) => {
    if (event.key === 'Enter') {
      toggleChangedDescription(surveyDescription);
    }
  };

  return (
    <Row className={!edit ? 'surveyTitleDiv' : 'surveyTitleDivEdit'}>
      {!edit ? (
        <div className='surveyDescription'>
          {description.split('\n').map((line, index) => (
            <p key={index}>{line}</p>
          ))}
        </div>
      ) : (
        <textarea
          placeholder='Enter a description for the survey'
          value={surveyDescription}
          onChange={(e) => setSurveyDescription(e.target.value)}
          className='surveyDescriptionEdit'
          onKeyUp={enterHandler}
        />
      )}
    </Row>
  );
};

export default SurveyDescriptionCreate;
