import * as AiIcons from 'react-icons/ai';
import * as IoIcons from 'react-icons/io';
import * as RiIcons from 'react-icons/ri';
import * as BsIcons from 'react-icons/bs';
import * as FcIcons from 'react-icons/fc';
import * as MdIcons from 'react-icons/md';
import * as RxIcons from 'react-icons/rx';
import * as CiIcons from 'react-icons/ci';
import * as TbIcons from 'react-icons/tb';
import { IoLogOutOutline } from 'react-icons/io5';
import * as BiIcons from 'react-icons/bi';

const profile = <BsIcons.BsPersonCircle size={100} />;
const plusSign = <BsIcons.BsPlusLg />;
const backArrow = <BsIcons.BsArrowReturnLeft />;
const leftArrow = <AiIcons.AiOutlineArrowLeft />;
const rightArrow = <AiIcons.AiOutlineArrowRight />;
const searchIcon = <AiIcons.AiOutlineSearch />;
const trashIcon = <BsIcons.BsTrash3Fill />;
const bank = <RiIcons.RiBankLine />;
const removeIcon = <IoIcons.IoMdRemoveCircleOutline />;
const homeIcon = <AiIcons.AiOutlineHome />;
const surveyIcon = <RiIcons.RiSurveyLine />;
const surveyIconBox = <FcIcons.FcSurvey />;
const outlineForm = <AiIcons.AiOutlineForm />;
const collapseIcon = <FcIcons.FcCollapse />;
const hamburgerIcon = <RxIcons.RxHamburgerMenu />;
const crossIcon = <RxIcons.RxCross1 />;
const logOutIcon = <IoLogOutOutline />;
const logOutIconLeft = <CiIcons.CiLogout />;
const star = <AiIcons.AiOutlineStar />;
const patientSearch = <TbIcons.TbUserSearch />;
const checkIcon = <AiIcons.AiOutlineCheck style={{ color: 'green' }} />;
const linkIcon = <AiIcons.AiOutlineLink />;

const filledStar = <AiIcons.AiFillStar style={{ color: 'orange' }} />;
const addNewEmojiIcon = <MdIcons.MdOutlineAddReaction />;
const addNewEmojiIconLarge = (
  <MdIcons.MdOutlineAddReaction size={40} style={{ opacity: '0.5' }} />
);
const expandIcon = (
  <FcIcons.FcCollapse style={{ transform: 'rotate(180deg)' }} />
);
const expandIconDisabled = (
  <FcIcons.FcCollapse style={{ transform: 'rotate(180deg)', opacity: '0.3' }} />
);
const collapseIconDisabled = <FcIcons.FcCollapse style={{ opacity: '0.3' }} />;
const plusSignRound = <CiIcons.CiCirclePlus size={40} />;
const imageIcon = <BiIcons.BiImageAdd size={80} style={{ opacity: '0.5' }} />;
const bankAdd = (
  <>
    <RiIcons.RiBankLine />
    <BsIcons.BsPlusLg />
  </>
);
const manualComplete = <MdIcons.MdOutlineMoveDown />;

const exportIcon = <BiIcons.BiExport />;

export {
  imageIcon,
  exportIcon,
  homeIcon,
  profile,
  expandIcon,
  expandIconDisabled,
  collapseIcon,
  collapseIconDisabled,
  plusSign,
  plusSignRound,
  backArrow,
  leftArrow,
  rightArrow,
  searchIcon,
  bank,
  addNewEmojiIcon,
  addNewEmojiIconLarge,
  trashIcon,
  hamburgerIcon,
  crossIcon,
  star,
  filledStar,
  removeIcon,
  surveyIcon,
  surveyIconBox,
  outlineForm,
  logOutIcon,
  logOutIconLeft,
  patientSearch,
  checkIcon,
  bankAdd,
  linkIcon,
  manualComplete,
};
