import { create } from 'domain';
import * as question from './questionSent';
import { getQuestionSentBySectionId } from './questionSent';
import * as section from './sectionSent';
import * as survey from './surveySent';
import axios from 'axios';
import * as surveyscreated from '../Created_functions/surveyCreated';
import * as sectionscreated from '../Created_functions/sectionCreated';
import * as questionscreated from '../Created_functions/questionCreated';
import * as types from '../../assets/types';
import { keyword } from '../../assets/types'

export type surveySentInfo = {
    team: string[];
    patientId?: string;
    anonymous: boolean;
    middle?: boolean;
}

export type typeReturnSurvey = {
    title: string;
    titleEmoji?: string;
    surveyDescription: string;
    sectionOrder: string[];
    sectionArray: (section.sectionSentExtract[]);
    complete: boolean;
}

/** 
 * 
 * @param id - Id of survey you want to display
 * @returns - Returns a survey with title, description 
 *            and all section names + IDs for a survey
 */
export async function answerGetSurvey(id: string):Promise<typeReturnSurvey> {
   const surveyAnswer = survey.getSurveySentById(id);
   const sections = section.getSectionSentBySurveyId(id);
   const surveyReturn = {
        title: (await surveyAnswer).title,
        titleEmoji: (await surveyAnswer).titleEmoji,
        surveyDescription: (await surveyAnswer).description,
        sectionOrder: (await surveyAnswer).sectionOrder,
        sectionArray: (await sections),
        complete: (await surveyAnswer).completed,
    };
    return surveyReturn;
}

/**
 * 
 * @param surveyId Id of survey you want to display
 * @param sectionId Section the answers belong to
 * @returns The questions to answer in a section
 */
export async function answerGetSectionQuestions(sectionId: string):Promise<question.questionSentExtract[]> {
    const questionsGot = question.getQuestionSentBySectionId(sectionId);
    return await questionsGot;
}

/**
 * 
 * @param questions list of questions you want to update
 * @param clear - If true, clears the answers
 * @param sectionId - The section the questions belong to
 * @returns - Returns true if successful
 */
export async function answerUpdateSectionQuestions(questions: question.questionSentUpdate[], clear: boolean, sectionId: string):Promise<boolean> {
    try {
        if (clear){
            await section.updateSectionSent({id: sectionId, completed: !clear, completedDate: null});
            for (let i = 0; i < questions.length; i++) {
                questions[i].answer = [];
                const ans = await question.updateQuestionSent(questions[i]);
            }
        } else {
            await section.updateSectionSent({id: sectionId, completed: !clear}); //, completeDate: new Date()});
            for (let i = 0; i < questions.length; i++) {
                await question.updateQuestionSent(questions[i]);
            }
        }
        
        // If all updates succeed, return true
        return true;
      } catch (err) {
        // If an error occurs, return false
        return false;
      }
}


/**
 * Gets the ids of all finished sections in survey.
 * @param surveyid list of questions you want to update
 * @returns string[] the ids of the finished sections
 */
export async function answerGetFinishedSections(surveyid: string):Promise<string[]> {
    const sections = section.getSectionSentFinished(surveyid);
    const sectionids: string[] = [];
    for(let i = 0; i < (await sections).length; i++){
        sectionids.push((await sections)[i].id);
    }
    return sectionids;
}

/**
 * Get all patients connected to a staff member
 * @param staffId id of staff
 * @returns -string[], an array of all the patient ids.
 */
export async function getPatientIds(staffId: string):Promise<string[]> {
    try {
        const surveys: survey.surveySentExtract[] = await survey.getSurveySentByStaff(staffId)
        let patientId: string[] = [];
        for(let i = 0; i < surveys.length && surveys.length > 0; i++) {
            if (surveys[i].patientId !== undefined){
                patientId.push(surveys[i].patientId as string);
            }
        }
        patientId = patientId.filter((value, index, self) =>
            index === self.findIndex((t) => (
                t === value
            ))
        )
        return patientId;
    }
    catch (error) {
        console.error(`An error occurred while getting patients for ${staffId}:`, error);
        return [];
    }
}


/**
 * Creates an instance of sent questions. 
 * @param createdSectionId id of the section in created
 * @param sentSectionId - id of the section in sent
 * @returns - Returns an array of tuples, the created question id and the sent question id.
 */
export async function createSentQuestionInstance(createdSectionId: string, sentSectionId: string): Promise<[string, string][]> {
    
    //Tuple to keep track of which ids match eachother
    //This is to be able to change the questionOrder in the section.
    //sent, created
    const idTuple: [string, string][] = [];

    //All created questions in this created section.
    const createdQuestions: questionscreated.questionCreatedExtract[] = await questionscreated.getQuestionCreatedBySectionId(createdSectionId);

    //Place all the id's of created questions in a list
    const createdIds: string[] = []
    for(let i = 0; i<createdQuestions.length; i++){
        createdIds.push(createdQuestions[i].id);
    }

    //for loop for creating root questions! Aka questions without followupquestions
    for(let i = 0; i<createdQuestions.length; i++) {
        if(createdQuestions[i].parentQuestionId == undefined){
            const questionSent: question.questionSentCreate = {
                title: createdQuestions[i].title,
                titleEmoji: createdQuestions[i].titleEmoji,
                type: createdQuestions[i].type,
                mandatory: createdQuestions[i].mandatory,
                qoptions: createdQuestions[i].qoptions,
                emojiOpt: createdQuestions[i].emojiOpt,
                bounds: createdQuestions[i].bounds,
                condition: createdQuestions[i].condition,
                description: createdQuestions[i].description,
                multipleChoice: createdQuestions[i].multipleChoice,
                sectionId: sentSectionId
            };
            //When pushed sent is the id of the new question
            const sent:string = await question.createQuestionSent(questionSent); 
            //pushes the sentQuestion id and the created question id.
            idTuple.push([sent, createdQuestions[i].id]); 
            //Removes the created id from the list, so that all that is remaining after done for loop is the followup ids
            const index = createdIds.indexOf(createdQuestions[i].id);
            if(index>-1){
              createdIds.splice(index, 1)
            }; 
        }    
    }
    //for loop creating follow up questions!
    //First if is if there are followup questions.
    if(createdIds.length > 0 ){
        let questionsleft: boolean = true;

        while(questionsleft){
        for(let i = 0; i<createdQuestions.length; i++){
            if(createdIds.includes(createdQuestions[i].id)){
                //find questionsent matching id
                const searchId = createdQuestions[i].parentQuestionId;
                const matchingTuple = idTuple.find(([id1, id2]) => id2 === searchId);
                if(matchingTuple){
                    const questionParentId = matchingTuple[0];

                    const followUpSent: question.questionSentCreate = {
                        title: createdQuestions[i].title,
                        titleEmoji: createdQuestions[i].titleEmoji,
                        type: createdQuestions[i].type,
                        mandatory: createdQuestions[i].mandatory,
                        qoptions: createdQuestions[i].qoptions,
                        emojiOpt: createdQuestions[i].emojiOpt,
                        bounds: createdQuestions[i].bounds,
                        condition: createdQuestions[i].condition,
                        description: createdQuestions[i].description,
                        multipleChoice: createdQuestions[i].multipleChoice,
                        sectionId: sentSectionId,
                        parentQuestionId: questionParentId
                    };
                    
                    const sentFollowUp: string = await question.createQuestionSent(followUpSent);
                    idTuple.push([sentFollowUp, createdQuestions[i].id]); 
                    const index = createdIds.indexOf(createdQuestions[i].id);
                    if(index>-1){
                        createdIds.splice(index, 1)
                    }; 
                }
            }
        }
        if(createdIds.length == 0){
            questionsleft = false
        };
        }   
    }
    return idTuple;
}

 
/**
 * Creates an instance of sent sections. 
 * @param createdSurveyId id of the survey in created
 * @param sentSurveyId - id of the survey in sent
 * @returns - Returns an array of tuples, the created section id and the sent section id.
 */
export async function createSentSectionsInstance(createdSurveyId: string, sentSurveyId: string): Promise<[string, string][]>{
    //Gets all 
    const sectionsCreated: sectionscreated.sectionCreatedExtract[] = await sectionscreated.getSectionCreatedBySurveyId(createdSurveyId);
    const sectionSentTuple: [string, string][] = [];
    for(let i = 0; i < sectionsCreated.length; i++) {
        const createSentSection: section.sectionSent = {
            title: sectionsCreated[i].title,
            titleEmoji: sectionsCreated[i].titleEmoji,
            description: sectionsCreated[i].description,
            completed: false,
            questionOrder: sectionsCreated[i].questionOrder,
            surveyId: sentSurveyId
        }
        const sectionId: string = await section.createSectionSent(createSentSection);
        sectionSentTuple.push([sectionId, sectionsCreated[i].id]); //pusha båda ids här!! sent, created
        const questionsSent: [string, string][] = await createSentQuestionInstance(sectionsCreated[i].id, sectionId);
        const newQuestionOrder: string[] = [];
        for(let j = 0; j < sectionsCreated[i].questionOrder.length; j++){
            const searchId = sectionsCreated[i].questionOrder[j];
            const matchingTuple = questionsSent.find(([id1, id2]) => id2 === searchId);
            if(matchingTuple){
                newQuestionOrder.push(matchingTuple[0]);
            }
        }
        const updateSentSection: section.sectionSentUpdate = {
            id: sectionId,
            questionOrder: newQuestionOrder
        }
        const sentUpdated: string = await section.updateSectionSent(updateSentSection);
    };
    return sectionSentTuple;
}
    

/**
 * Creates an instance of a sent question. 
 * @param createdId id of the created survey to move.
 * @param addedinfo -additional information necessary.
 * @returns -the id of the new survey sent.
 */
export async function createInstanceSentSurvey(createdId: string, addedinfo: surveySentInfo): Promise<string> {
    try{
        const copyCreatedSurvey: surveyscreated.surveyCreatedExtract = await surveyscreated.getSurveyCreatedById(createdId);
        const createSentSurvey: survey.surveySentCreate = {
            title: copyCreatedSurvey.title,
            titleEmoji: copyCreatedSurvey.titleEmoji,
            description: copyCreatedSurvey.description,
            department: copyCreatedSurvey.department,
            team: addedinfo.team,
            keywords: copyCreatedSurvey.keywords,
            process: copyCreatedSurvey.process,
            anonymous: addedinfo.anonymous,
            patientId: addedinfo.patientId,
            middle: addedinfo.middle,
            sectionOrder: copyCreatedSurvey.sectionOrder,
            completed: false,
            forced: false,
            parentSurveyId: createdId
        };
        const surveySentId = await survey.createSurveySent(createSentSurvey);
        const sectionSentCreated: [string, string][] = await createSentSectionsInstance(createdId, surveySentId);
        const newSectionOrder: string[] = [];
        for(let j = 0; j < copyCreatedSurvey.sectionOrder.length; j++){
            const searchId = copyCreatedSurvey.sectionOrder[j];
            const matchingTuple = sectionSentCreated.find(([id1, id2]) => id2 === searchId);
            if(matchingTuple){
                newSectionOrder.push(matchingTuple[0]);
                }
        }
        const keywords_temp: keyword[] = await surveyscreated.getKeywordsBySurveyCreated(createdId);
        const keywordIds: string[] = [];
        for(let j = 0; j < keywords_temp.length; j++){
            keywordIds.push(keywords_temp[j].id);
        }
        const updateSentSurvey: survey.surveySentUpdate = {
            id: surveySentId,
            sectionOrder: newSectionOrder,
            keywords: keywordIds
        }
        await survey.updateSurveySent(updateSentSurvey);
        return surveySentId;
    }
    catch (error) {
        console.log(error + "404: create survey instance sent failed");
        throw(error);
    }
    ///update section order
}

/**
 * Gets the keywords for a survey sent and returns them with the id.
 * @param id The id of the survey
 * @returns surveyKeyword, an object containing survey id and an array of its keywords
 */
export async function getKeywordsForSurveySent(id: string): Promise<types.surveyKeyword> {
    const getKeywords: types.keyword[] = await survey.getKeywordsBySurveySent(id);
    const surveyKeywords: types.surveyKeyword = {
        id: id,
        keywords: getKeywords
    };
    return surveyKeywords;
}
