import Button from 'react-bootstrap/Button';
import { searchIcon } from '../../assets/Icons';
import './SearchBarQB.css';
import { useEffect, useState, useRef } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import Collapse from 'react-bootstrap/Collapse';
import Select, { MultiValue } from 'react-select';
import processes from '../../assets/process.json';
import { FilterQB } from '../../assets/types';
import { crossIcon } from '../../assets/Icons';
import CustomButton from '../Buttons/CustomButton';

interface Props {
  data: any;
  dataToSend: any;
  setDataToSend: (data: any) => void;
}

function SearchBarQB({ data, dataToSend, setDataToSend }: Props) {
  const [typedData, setTypedData] = useState('');
  const [filterClicked, setFilterClicked] = useState(false);
  const [finishedSearchWord, setFinishedSearchWord] = useState('');
  const [searchCategory, setSearchCategory] = useState('');

  const [testObject, setTestObject] = useState<FilterQB>({
    keywords: [],
    searchText: finishedSearchWord, //tror ej denna behövs?
    searchCategory: searchCategory, // eller denna?
    process: '',
  });

  const bigFilterFunc = () => {
    let tempDataToSend = data;
    if (testObject.keywords.length != 0) {
      tempDataToSend = filterByTags(tempDataToSend);
    }
    if (finishedSearchWord != '') {
      tempDataToSend = filterDataOnSearch(tempDataToSend);
    }
    if (testObject.process != '') {
      tempDataToSend = filterByProcess(tempDataToSend);
    }
    setDataToSend(tempDataToSend);
  };

  const onChangeHandler = (event: any) => {
    const typedData = event.target.value;
    setTypedData(typedData);
  };

  const enterHandler = (event: any) => {
    if (event.key === 'Enter') {
      setFinishedSearchWord(typedData);
      setTypedData('');
    }
  };

  const buttonEnterHandler = () => {
    setFinishedSearchWord(typedData);
    setTypedData('');
  };

  const handleClearClick = () => {
    setFinishedSearchWord('');
    setTestObject({ ...testObject, keywords: [], process: '' });
    setDataToSend(data);
    setSearchCategory('');
  };

  const filterDataOnSearch = (dataToFilter: any) => {
    const lowerCaseSearchTerm = finishedSearchWord.toLowerCase();
    const newDataToSend: any = dataToFilter.filter((data: any) => {
      if (searchCategory.toLowerCase() === '') {
        return data.title.toLowerCase().includes(lowerCaseSearchTerm);
      }
      if (searchCategory.toLowerCase() === 'title') {
        return data.title.toLowerCase().includes(lowerCaseSearchTerm);
      }

      if (searchCategory.toLowerCase() === 'description') {
        return data.description.toLowerCase().includes(lowerCaseSearchTerm);
      }
    });
    return newDataToSend;
  };

  const filterByTags = (dataToFilterByTags: any) => {
    if (testObject.keywords.length == 0) {
      return;
    }
    const newDataToSend: any = dataToFilterByTags.filter((data: any) => {
      const dataTags = data.keywords;
      return testObject.keywords.every((tag) => dataTags.includes(tag));
    });
    return newDataToSend;
  };

  const filterByProcess = (dataToFilterByProcess: any) => {
    if (testObject.process == '') {
      return;
    }
    const newDataToSend: any = dataToFilterByProcess.filter((data: any) => {
      return data.process == testObject.process;
    });
    return newDataToSend;
  };

  function createOptionsList(
    strings: string[]
  ): { label: string; value: string }[] {
    if (!strings || strings[0] === '')
      return [] as { label: string; value: string }[];
    return strings.map((string) => ({ label: string, value: string }));
  }

  const handleSelectTagsChange = (
    value: MultiValue<{ label: string; value: string }>
  ) => {
    let keywords: string[] = [];
    value.map((option: { label: string; value: string }) =>
      keywords.push(option.value)
    );
    setTestObject({ ...testObject, keywords: keywords });
  };

  const handleSelectProcessChange = (value: any) => {
    if (value !== null) {
      setTestObject({ ...testObject, process: value.value });
    } else {
      setTestObject({ ...testObject, process: '' });
    }
  };
  const keywords = ['test', 'test2', 'test3', 'test4', 'test5', 'test6']; //TODO BYT UT MOT RIKTIGA TAGS

  const customStyles = {
    control: (base: any, state: any) => ({
      ...base,
      minWidth: '200px',
      width: '100%',
    }),
  };

  useEffect(() => {
    bigFilterFunc();
  }, [
    testObject.keywords,
    testObject.process,
    finishedSearchWord,
    searchCategory,
  ]);

  return (
    <>
      <div className='filter-search-sort-qb'>
        <div id='filter-search-container-qb'>
          <div className='row no-padding' id='row-custom'>
            <div className='col no-padding'>
              <Dropdown>
                <Dropdown.Toggle id='dropdown-search-category-qb'>
                  {searchCategory[0]}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => setSearchCategory('Title')}>
                    Title
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => setSearchCategory('Description')}
                  >
                    Description
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>

            <div className='col no-padding'>
              <input
                type='search'
                placeholder={
                  finishedSearchWord !== '' ? finishedSearchWord : 'Search'
                }
                className='search-bar-qb'
                aria-label='Search'
                onChange={onChangeHandler}
                onKeyUp={enterHandler}
                value={typedData}
              />
            </div>

            <div className='col no-padding'>
              <CustomButton
                icon={finishedSearchWord != '' ? crossIcon : searchIcon}
                id='search-icon-button-qb'
                onClick={
                  finishedSearchWord != ''
                    ? () => setFinishedSearchWord('')
                    : buttonEnterHandler
                }
              />
            </div>

            <div className='no-padding'>
              <CustomButton id ='reset-btn' text='Reset' onClick={() => handleClearClick()} />
            </div>
          </div>

          <div className='col no-padding'>
            <Button
              id={'filter-btn-qb'}
              onClick={() => setFilterClicked(!filterClicked)}
              aria-controls='example-collapse-text-qb'
              aria-expanded={filterClicked}
            >
              Filter
            </Button>

            <Collapse in={filterClicked}>
              <div id='example-collapse-text-qb'>
                <Select
                  placeholder='Keywords'
                  value={createOptionsList(testObject.keywords)}
                  options={createOptionsList(keywords)}
                  isMulti
                  isClearable
                  isSearchable
                  onChange={(value) => {
                    handleSelectTagsChange(value);
                  }}
                  styles={customStyles}
                />
                <Select
                  placeholder='Process'
                  value={createOptionsList([testObject.process])}
                  options={createOptionsList(processes.process)}
                  isClearable
                  isSearchable
                  onChange={(value) => {
                    handleSelectProcessChange(value);
                  }}
                  styles={customStyles}
                />
              </div>
            </Collapse>
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchBarQB;
