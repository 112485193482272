import '../QuestionCreate/QuestionCreate.css';
import './AnswerView.css';
import { useState, useEffect, useRef } from 'react';
import { sectionType } from '../../assets/types';
import Progressbar from '../Progressbar/Progressbar';
import AnswerViewSection from './AnswerViewSection';
import DoneButton from '../Buttons/DoneButton';
import { checkIcon } from '../../assets/Icons';
import { answerGetFinishedSections } from '../../functions/Sent_functions/sentUtils';
import AnswerViewInspectAnswers from './AnswerViewInspectAnswers';
import { crossIcon, hamburgerIcon } from '../../assets/Icons';
import Logo from '../../assets/images/d2vlogo.png';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import {
  surveySentUpdate,
  updateSurveySent,
} from '../../functions/Sent_functions/surveySent';
import { Alert } from 'react-bootstrap';

interface Props {
  sections: sectionType[];
  surveyTitle: string;
  surveyDescription: string;
  surveyId: string;
  sectionOrder: string[];
  surveyCompleted: boolean;
  finishedSections: string[];
  toggleRefetchSections: () => void;
}

const AnswerView = ({
  sections,
  surveyTitle,
  surveyDescription,
  surveyId,
  sectionOrder,
  surveyCompleted,
  finishedSections,
  toggleRefetchSections,
}: Props) => {
  const startPage: sectionType = {
    surveyId: surveyId,
    id: surveyId + 'startPage',
    title: surveyTitle,
    titleEmoji: '',
    description: surveyDescription,
    questionOrder: [],
  };

  const [noServerResponse, setNoServerResponse] = useState<boolean>(false);
  const [currentSection, setCurrentSection] = useState<sectionType>(startPage);
  const [activeButton, setActiveButton] = useState<string>(
    startPage.id as string
  );
  const [answeredSectionIds, setAnsweredSectionIds] =
    useState<string[]>(finishedSections);
  const [status, setStatus] = useState<number>(
    (finishedSections.length / sections.length) * 100
  );

  const [inspect, setInspect] = useState<boolean>(false);
  const [completed, setCompleted] = useState<boolean>(surveyCompleted);
  const amountOfSections: number = sections.length;

  const orderedSections: sectionType[] = sections.length
    ? sectionOrder.map(
        (id) => sections.find((obj) => obj.id === id) as sectionType
      )
    : [];

  const lastSectionId = sectionOrder[sectionOrder.length - 1];

  const toggleNextSection = (id: string, back: boolean) => {

    let nextId = startPage.id;
    let section = startPage;

    if (id === startPage.id){
      nextId = sectionOrder[0]
      section = sections.find((section) => section.id === nextId) || startPage;
    } else if (id === sectionOrder[0] && back){
      nextId = startPage.id;
    } else {
      nextId = sectionOrder[back ? sectionOrder.indexOf(id) - 1 : sectionOrder.indexOf(id) + 1];
      section = sections.find((section) => section.id === nextId) || startPage;
    }

    setCurrentSection(section);
    setActiveButton(nextId);
    fetchFinishedSections();
  };

  const fetchFinishedSections = () => {
    answerGetFinishedSections(surveyId).then((sections) => {
      if (sections === undefined) {
        setNoServerResponse(true);
        return;
      }
      setAnsweredSectionIds(sections);
      setStatus((sections.length / amountOfSections) * 100);
    });
  };

  const handleSendInSurvey = () => {
    const data: surveySentUpdate = { id: surveyId, completed: true , completedDate: new Date()};
    updateSurveySent(data).then((res) => {});
    setCompleted(true);
  };

  const handleInspectAnswers = () => {
    setActiveButton('');
    setInspect(true);
  };

  const [isOpen, setIsOpen] = useState(
    window.innerWidth > 1024 ? false : false
  );

  const [showToggle, setShowToggle] = useState(
    window.innerWidth < 1024 ? true : false
  );

  const alertRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (noServerResponse && alertRef.current) {
      alertRef.current.focus();
    }
  }, [noServerResponse]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1024) {
        setIsOpen(true);
        setShowToggle(false);
      }

      if (window.innerWidth < 1024) {
        setIsOpen(false);
        setShowToggle(true);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isOpen]);

  useEffect(() => {}, [status, answeredSectionIds]);

  return (
    <>
      {completed ? (
        <>
          <div className='answer-survey-page sent-in'>
            <div className='answer-sent-in-container'>
              <>Thank you! </>
            </div>
          </div>{' '}
        </>
      ) : (
        <div className='answer-survey-page'>
          <div
            className={'side-section-nav'}
            id={isOpen ? 'side-section-nav-open' : 'closed-sidebar-answerview'}
          >
            <img src={Logo} alt='Logo' />
            <div className='toggle-sidebar-answerview'>
              <button
                onClick={() => setIsOpen(!isOpen)}
                className='toggle-sidebar-answerview-button'
              >
                {isOpen ? crossIcon : hamburgerIcon}
              </button>
            </div>

            {isOpen && (
              <div className='section-titles'>
                <b> {surveyTitle}</b>
                <hr />

                <button
                  key={surveyId}
                  className={`section-nav-button ${
                    activeButton === startPage.id ? 'active' : ''
                  }`}
                  onClick={() => {
                    setActiveButton(startPage.id as string);
                    setCurrentSection(startPage);
                    window.innerWidth < 1024
                      ? setIsOpen(!isOpen)
                      : setIsOpen(isOpen);
                    setInspect(false);
                  }}
                >
                  <em>Information</em>
                </button>

                {orderedSections.map((section: sectionType) => (
                  <button
                    key={section.id}
                    className={`section-nav-button ${
                      activeButton === section.id ? 'active' : ''
                    }`}
                    onClick={() => {
                      setActiveButton(section.id as string);
                      setCurrentSection(section);
                      window.innerWidth < 1024
                        ? setIsOpen(!isOpen)
                        : setIsOpen(isOpen);
                      setInspect(false);
                    }}
                  >
                    <div style={{ float: 'left', marginRight: '10px' }}>
                      {section.title}
                    </div>
                    {answeredSectionIds.includes(section.id) && (
                      <div style={{ float: 'right' }}>{checkIcon}</div>
                    )}
                  </button>
                ))}
              </div>
            )}

            {isOpen && (
              <OverlayTrigger
                overlay={
                  <Tooltip id='tooltip-disabled'>
                    You have to finish all sections to send the survey
                  </Tooltip>
                }
              >
                <span style={{ width: '100%' }}>
                  <DoneButton
                    text='Review answers'
                    onClick={() => {
                      handleInspectAnswers();
                      window.innerWidth < 1024
                        ? setIsOpen(!isOpen)
                        : setIsOpen(isOpen);
                    }}
                    // onClick={handleSendIn} // window.innerWidth < 1024 ?  setIsOpen(!isOpen) : setIsOpen(isOpen);
                    disabled={status !== 100}
                    style={{
                      width: '80%',
                      marginTop: '1rem',
                      borderRadius: '30px',
                    }}
                  />
                </span>
              </OverlayTrigger>
            )}
            {isOpen && (
              <div className='progress-sidebar'>
                Completion progress
                <Progressbar percentage={status} />
              </div>
            )}
          </div>

          <div
            className='answer-section'
            id={
              isOpen ? 'answer-section-open-side' : 'answer-section-closed-side'
            }
          >
            <div>
              {inspect ? (
                <AnswerViewInspectAnswers
                  handleSendIn={handleSendInSurvey}
                  setCompleted={setCompleted}
                  surveyTitle={surveyTitle}
                  sectionOrder={sectionOrder}
                  sections={sections}
                  surveyId={surveyId}
                />
              ) : (
                <>
                  {currentSection !== undefined && (
                    <>
                      <div tabIndex={0} ref={alertRef}>
                        {noServerResponse ? (
                          <Alert
                            variant='danger'
                            onClose={() => setNoServerResponse(false)}
                            dismissible
                          >
                            <Alert.Heading>
                              Connection Error: Unable to reach the server
                            </Alert.Heading>
                            <p>
                              You are currently not connected to the server.
                              Please check your internet.
                            </p>
                          </Alert>
                        ) : null}
                      </div>
                      <AnswerViewSection
                        handleInspect={handleInspectAnswers}
                        handleSendInSurvey={handleSendInSurvey}
                        setNoServerResponse={setNoServerResponse}
                        toggleRefetchSections={toggleRefetchSections}
                        fetchFinishedSections={fetchFinishedSections}
                        key={currentSection.id}
                        sectionName={currentSection.title}
                        sectionDesc={currentSection.description}
                        sectionId={currentSection.id as string}
                        lastSection={
                          currentSection.id === lastSectionId ? true : false
                        }
                        startPage={
                          currentSection.id === startPage.id ? true : false
                        }
                        toggleNextSection={toggleNextSection}
                        questionOrder={currentSection.questionOrder}
                      />
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AnswerView;
