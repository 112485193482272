import React, { useState, useEffect } from 'react';
import './SurveyRow.css';
import 'bootstrap/dist/css/bootstrap.css';
import Accordion from 'react-bootstrap/Accordion';
import './SurveyRow.css';
import CustomButton from '../Buttons/CustomButton';
import { useNavigate } from 'react-router-dom';
import SendSurvey from '../SendSurvey/SendSurvey';
import {
  filledStar,
  star,
  bank,
  bankAdd,
  removeIcon,
  surveyIcon,
  manualComplete,
  exportIcon,
} from '../../assets/Icons';
import {
  getStaffById,
  updateStaffById,
} from '../../functions/Misc/staffCreated';
import ViewSurvey from '../ViewSurvey/ViewSurvey';
import { getSurveyFromCreated } from '../../functions/Created_functions/createdUtils';
import {
  returnedSurveyCreated,
  surveyFromSurveyBank,
  surveySend,
  sectionFromSurveyBank,
  keyword,
  sectionType,
  downloadData,
} from '../../assets/types';

import {
  getKeywordsForSurveyTemp,
  getSurveyFromSurveyBank,
  removeSurveyTempAndSections,
} from '../../functions/Template_functions/tempUtils';

import { Button, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import {
  surveyTempExtract,
  surveyTempUpdate,
  updateSurveyTemp,
} from '../../functions/Template_functions/surveyTemp';
import ViewSurveyInstances from '../../views/ViewSurveyInstances/ViewSurveyInstances';
import { useAuth0 } from '@auth0/auth0-react';

import {
  getSurveyInstances,
  surveySentExtract,
  updateSurveySent,
} from '../../functions/Sent_functions/surveySent';

import {
  getKeywordsBySurveyCreated,
  getSurveyCreatedById,
  surveyCreatedExtract,
} from '../../functions/Created_functions/surveyCreated';
import { keywordExtract } from '../../functions/Misc/keywords';
import hashString from '../../functions/scripts/urlScript';
import { deleteURLrelation } from '../../functions/Misc/URLrelation';
import { render } from '@testing-library/react';
import { getSectionSentBySurveyId } from '../../functions/Sent_functions/sectionSent';
import { answerGetSectionQuestions } from '../../functions/Sent_functions/sentUtils';
import { questionSentExtract } from '../../functions/Sent_functions/questionSent';

export type SurveyProps = {
  id: string;
  title: string;
  titleEmoji?: string | undefined;
  description: string;
  creationDate: Date;
  department: string;
  sectionOrder: string[];
  ownerID?: string;
  keywords?: string[] | undefined;
  process: string;
  team?: string[];
  creator?: string;
  inBank?: boolean;
};

const SurveyRow = ({
  createSurvey,
  setSurveyTemplate,
  survey,
  idx,
  isTemplate,
  isFavAble,
  surveyBankSentExists,
  surveyBank,
  batchCompleted,
  teamSection,
  setRenderPage,
  renderPage,
}: {
  createSurvey?: boolean;
  setSurveyTemplate?: (surveyTemplateId: string) => void;
  survey: SurveyProps;
  idx: number;
  isTemplate: boolean;
  isFavAble?: boolean;
  surveyBankSentExists?: surveyCreatedExtract[];
  surveyBank?: boolean;
  batchCompleted?: boolean;
  teamSection?: boolean;
  setRenderPage?: (arg: boolean) => void;
  renderPage?: boolean;
}) => {
  const { isAuthenticated, user, isLoading } = useAuth0();
  const ownerId = user?.sub;
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const handleCloseDeleteModal = () => setShowDeleteModal(false);
  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);
  const [surveyShow, setSurveyShow] = useState<
    surveyFromSurveyBank | returnedSurveyCreated
  >();
  const [showShowModal, setShowShowModal] = useState(false);
  const [showSendModal, setShowSendModal] = useState(false);
  const [missingSections, setMissingSections] = useState(false);
  const [missingQuestions, setMissingQuestions] = useState(false);
  const [favtest, setfavtest] = useState<string[]>([]);
  const [isFav, setIsFav] = useState(false);
  const [selectedExistingSurveyBatchSent, setSelectedExistingSurveyBatchSent] =
    useState();
  const [surveyCreatedBatchToSendFrom, setSurveyCreatedBatchToSendFrom] =
    useState<surveyCreatedExtract>();
  const [keywords, setKeywords] = useState<keyword[]>();
  const [viewLog, setViewLog] = useState(false);
  const [numberOfCompletedInstances, setNumberOfCompletedInstances] =
    useState<number>();
  const [numberOfInstances, setNumberOfInstances] = useState<number>();

  const [surveyInstances, setSurveyInstances] = useState<surveySentExtract[]>();

  const surveyToSend: surveySend = {
    id: survey.id,
    title: survey.title,
    description: survey.description,
  };

  function convertToCSV(data: any[]): string {
    const headers = [
      'survey_id',
      'patient_id',
      'survey_name',
      'section_name',
      'question_name',
      'answer_id',
      'answer',
      'date',
    ];

    const csvRows = [headers.join(',')];

    for (const item of data) {
      const answerList = Array.isArray(item.answer) ? item.answer : [];
      if (answerList.length === 0) {
        // Create a row with empty value for answer
        const values = headers.map((header) => {
          const fieldValue = item[header];
          let escapedValue =
            typeof fieldValue === 'string' ? `"${fieldValue}"` : fieldValue;

          // Check if the current field is 'answer'
          if (header === 'answer') {
            escapedValue = ''; // Set empty value
          }

          return escapedValue;
        });
        csvRows.push(values.join(','));
      } else {
        // Create a new row for each item in the answer list
        for (const answerItem of answerList) {
          const values = headers.map((header) => {
            const fieldValue = item[header];
            let escapedValue =
              typeof fieldValue === 'string' ? `"${fieldValue}"` : fieldValue;

            // Check if the current field is 'answer'
            if (header === 'answer') {
              escapedValue = answerItem; // Use the current answer item
            }

            return escapedValue;
          });
          csvRows.push(values.join(','));
        }
      }
    }

    // Join all rows with line breaks
    const csvContent = csvRows.join('\n');

    // Add BOM and specify UTF-8 encoding
    const csvData = '\ufeff' + csvContent;

    return csvData;
  }

  function downloadCSVFile(data: downloadData[], filename: string): void {
    const csvData = convertToCSV(data);
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    link.click();
    URL.revokeObjectURL(url);
  }

  const sendFromSelectedBatch = () => {
    setShowSendModal(true);
    setShowModal(false);
    if (selectedExistingSurveyBatchSent) {
      getSurveyCreatedById(selectedExistingSurveyBatchSent).then((survey) => {
        setSurveyCreatedBatchToSendFrom(survey);
      });
    }
  };

  const handleUseAsTemplate = () => {
    if (createSurvey) {
      setSurveyTemplate?.(survey.id);
    } else {
      navigate('/createsurveyfromtemplate', { state: survey.id });
    }
  };

  const handleFavorite = () => {
    const itemExists = favtest.some((item) => item === survey.id);
    if (!itemExists) {
      const updatedItems = [...favtest, survey.id];
      if (ownerId) {
        updateStaffById({
          id: ownerId,
          favorites: updatedItems,
        }).then(() => {
          if (setRenderPage) {
            setRenderPage(!renderPage);
          }
        });
      }
    } else {
      const updatedItems = favtest.filter((item) => item !== survey.id);
      if (ownerId) {
        updateStaffById({
          id: ownerId,
          favorites: updatedItems,
        }).then(() => {
          if (setRenderPage) {
            setRenderPage(!renderPage);
          }
        });
      }
    }
  };

  const handleGetFavorites = () => {
    if (ownerId) {
      getStaffById(ownerId).then((result: any) => {
        setfavtest(result.favorites);
      });
    }
  };

  const handleViewSurveyClick = () => {
    if (isFavAble && isTemplate) {
      getSurveyFromSurveyBank(survey.id).then((result: any) => {
        setSurveyShow(result);
        setShowShowModal(true);
      });
    } else {
      getSurveyFromCreated(survey.id).then((result: any) => {
        setSurveyShow(result);
        setShowShowModal(true);
      });
    }
  };

  const handleExportClick = () => {
    const data: downloadData[] = [];
    surveyInstances?.forEach((surveyInstance, index) => {
      getSectionSentBySurveyId(surveyInstance.id).then((sections) => {
        const getSectionQuestionsPromises = sections.map((section) => {
          return answerGetSectionQuestions(section?.id).then((res) => {
            return { ...section, questions: res };
          });
        });
        Promise.all(getSectionQuestionsPromises).then(
          (sectionsWithQuestions) => {
            sectionsWithQuestions.forEach((section) => {
              section.questions.forEach((question) => {
                const dateString =
                  surveyInstance.completedDate === undefined
                    ? ''
                    : surveyInstance.completedDate.toString().slice(0, 10);
                data.push({
                  patient_id:
                    surveyInstance.patientId === undefined
                      ? ''
                      : surveyInstance.patientId,
                  survey_id: surveyInstance.id,
                  survey_name: survey.title,
                  section_name: section.title,
                  question_name: question.title,
                  answer_id: question.id,
                  answer: question.answer,
                  date: dateString,
                });
              });
            });
            if (index === surveyInstances.length - 1) {
              downloadCSVFile(data, survey.title + '.csv');
            }
          }
        );
      });
    });
  };

  function handleSelectedSurveyAlreadySent(event: any) {
    setSelectedExistingSurveyBatchSent(event.target.value);
  }

  const handleAddToSurveyBank = () => {
    const surveyTemp: surveyTempUpdate = {
      id: survey.id,
      inBank: true,
    };
    updateSurveyTemp(surveyTemp).then((result) => {
      setShowModal(false);
      navigate('/surveybank');
    });
  };

  const handleSendSurvey = () => {
    if (surveyBankSentExists?.length !== 0) {
      setShowModal(!showModal);
    } else {
      if (isFavAble && isTemplate) {
        getSurveyFromSurveyBank(survey.id).then(
          (result: surveyFromSurveyBank) => {
            if (result.sections.length === 0) {
              setMissingSections(true);
            } else if (
              result.sections.every((section) => section.questions.length === 0)
            ) {
              setMissingQuestions(true);
            }
            setShowSendModal(!showSendModal);
          }
        );
      } else {
        getSurveyFromCreated(survey.id).then((result: any) => {
          setSurveyShow(result);
          setShowSendModal(!showSendModal);
        });
      }
    }
  };

  const handleGetKeywords = () => {
    if (isTemplate) {
      getKeywordsForSurveyTemp(survey.id).then((res) => {
        setKeywords(res.keywords);
      });
    } else {
      getKeywordsBySurveyCreated(survey.id).then((res) => {
        setKeywords(res);
      });
    }
  };

  const handleRemoveSurveyTemp = () => {
    if (isFav) {
      handleFavorite();
      removeSurveyTempAndSections(survey.id).then((res) => {
        if (setRenderPage) {
          setRenderPage(!renderPage);
        }
      });
    } else {
      removeSurveyTempAndSections(survey.id).then((res) => {
        if (setRenderPage) {
          setRenderPage(!renderPage);
        }
      });
    }
    handleCloseDeleteModal();
  };

  const handleManualComplete = () => {
    getSurveyInstances(survey.id).then((surveyInstanceList) => {
      surveyInstanceList.map((surveyInstance) => {
        const updateSent = {
          id: surveyInstance.id,
          forced: true,
        };
        updateSurveySent(updateSent).then((surveyInstanceId) => {
          hashString(surveyInstanceId).then((urlId) => {
            deleteURLrelation(urlId).then((deletedUrlId) => {
              if (setRenderPage) {
                setRenderPage(!renderPage);
              }
            });
          });
        });
      });
    });
  };

  useEffect(() => {
    handleGetKeywords();
    handleGetFavorites();
  }, [survey]);

  useEffect(() => {
    handleGetFavorites();
  }, [renderPage]);

  useEffect(() => {
    setIsFav(favtest.some((item) => item === survey.id));
  });

  const handleGetInstances = () => {
    if (survey.id) {
      getSurveyInstances(survey.id).then((result: surveySentExtract[]) => {
        const tot = result.filter((obj) => !obj.middle);
        const completed = tot.filter((obj) => obj.completed).length;
        setNumberOfCompletedInstances(completed);
        setNumberOfInstances(tot.length);
        setSurveyInstances(result);
      });
    }
  };

  useEffect(() => {
    handleGetInstances();
  }, [survey]);

  const handleClose = () => setViewLog(false);
  if (isLoading) {
    return <div>Loading ...</div>;
  }

  if (!isAuthenticated || !user) {
    return <div>User is not logged in.</div>;
  }
  return (
    <div className='surveyrow'>
      <SendSurvey
        missingQuestions={missingQuestions}
        missingSections={missingSections}
        isTemplate={surveyCreatedBatchToSendFrom ? false : isTemplate}
        survey={
          surveyCreatedBatchToSendFrom
            ? surveyCreatedBatchToSendFrom
            : surveyToSend
        }
        showModal={showSendModal}
        handleCloseModal={() => {
          setShowSendModal(false);
        }}
      />
      {surveyShow && (
        <ViewSurvey
          survey={surveyShow}
          showModal={showShowModal}
          handleCloseModal={() => {
            setShowShowModal(false);
          }}
        />
      )}
      <div className='accordion'>
        <Accordion.Item eventKey={idx.toString()}>
          <Accordion.Header>
            {isFavAble && isTemplate && (
              <span
                id='star'
                onClick={(event) => {
                  handleFavorite();
                  event.stopPropagation();
                }}
              >
                {isFav ? filledStar : star}
              </span>
            )}

            <div className='column' id='surveyrow-title-col'>
              {' '}
              {survey.title}{' '}
            </div>
            {window.innerWidth > 600 && (
              <div className='column'>
                {survey.department ? survey.department : 'None'}
              </div>
            )}

            <div className='column'>
              {numberOfCompletedInstances} / {numberOfInstances}
            </div>

            <div className='column'>
              {survey.creationDate.toString().slice(0, 10)}{' '}
            </div>
          </Accordion.Header>

          <Accordion.Body>
            <div className='body-top'>
              <u>
                Process:{' '}
                {survey.process && survey.process !== ''
                  ? survey.process
                  : 'None'}
              </u>

              <u>
                Creation-date: {survey.creationDate.toString().slice(0, 10)}{' '}
              </u>
            </div>

            <div className='body-top'>
              <u>Title: {survey.title} </u>

              <u>
                Department: {survey.department ? survey.department : 'None'}
              </u>

              {/* <u>Completion-date: {survey.completedDate} </u> */}
            </div>

            {keywords && (
              <div className='info-row'>
                <u>
                  Tags:
                  {keywords?.map((obj, index) => {
                    const valueTmp = obj.value as string;
                    return (index ? ', ' : ' ') + valueTmp;
                  })}
                </u>
              </div>
            )}

            {!isTemplate && survey.team?.length !== 0 && (
              <div className='info-row'>
                <u>
                  Team:
                  {survey.team?.map((person: string, index: number) => {
                    return (index ? ', ' : ' ') + person;
                  })}
                </u>
              </div>
            )}
            <div className='surveyrow-description'>
              <em>{survey.description}</em>
            </div>
            <div className='button-section'>
              <OverlayTrigger
                key={'view-right'}
                placement='right'
                overlay={<Tooltip>View Survey</Tooltip>}
              >
                <Button id='view-survey-btn' onClick={handleViewSurveyClick}>
                  {surveyIcon}
                </Button>
              </OverlayTrigger>
              {!isTemplate && (
                <OverlayTrigger
                  key={'view-middle'}
                  placement='right'
                  overlay={<Tooltip>Export answers</Tooltip>}
                >
                  <Button id='export-btn' onClick={handleExportClick}>
                    {exportIcon}
                  </Button>
                </OverlayTrigger>
              )}
              {!isTemplate && !batchCompleted && !teamSection && (
                <OverlayTrigger
                  key={'right'}
                  placement='right'
                  overlay={<Tooltip>Move to completed</Tooltip>}
                >
                  <Button
                    id='manual-complete-btn'
                    onClick={handleManualComplete}
                  >
                    {manualComplete}
                  </Button>
                </OverlayTrigger>
              )}

              {!isTemplate && (
                <div className='button'>
                  <CustomButton
                    text={'View Log'}
                    onClick={() => setViewLog(true)}
                  />
                </div>
              )}
              {isTemplate && (
                <>
                  {!createSurvey && (
                    <div className='button'>
                      <CustomButton text={'Send'} onClick={handleSendSurvey} />
                    </div>
                  )}

                  <div className='button'>
                    <CustomButton
                      text={'Use as template'}
                      onClick={handleUseAsTemplate}
                    />
                  </div>

                  {!survey.inBank && (
                    <div className='button'>
                      <CustomButton
                        icon={bankAdd}
                        id={'add-to-bank-button'}
                        onClick={handleAddToSurveyBank}
                      />
                    </div>
                  )}

                  {isTemplate &&
                    !surveyBank &&
                    survey.creator !== undefined &&
                    survey.creator == ownerId && (
                      <div className='button'>
                        <CustomButton
                          icon={removeIcon}
                          id={'delete-temp-btn'}
                          onClick={(event: any) => {
                            setShowDeleteModal(true);
                            event.stopPropagation();
                          }}
                        />
                      </div>
                    )}
                </>
              )}

              {!isTemplate && !batchCompleted && (
                <div className='button'>
                  <CustomButton
                    text={'Send again'}
                    onClick={
                      surveyBankSentExists?.length != 0
                        ? () => setShowModal(true)
                        : () => setShowSendModal(true)
                    }
                  />
                </div>
              )}
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </div>

      {viewLog && (
        <Modal
          show={viewLog}
          onHide={handleClose}
          backdrop='static'
          keyboard={false}
          size='xl'
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <ViewSurveyInstances
              id={survey.id}
              title={survey.title}
              description={survey.description}
              completedSection={batchCompleted}
            />
          </Modal.Body>
        </Modal>
      )}

      {surveyBankSentExists?.length !== 0 && (
        <>
          <Modal
            show={showModal}
            onHide={handleCloseModal}
            backdrop='static'
            keyboard={false}
            size='lg'
          >
            <Modal.Header closeButton>
              <Modal.Title>
                You have one or more ongoing surveys using this template
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className='already-exists-modal-description'>
                <em>
                  Do you want to send out more instances of this survey in an
                  already sent out batch? If so, please select the batch you
                  would like to add the new instances to.
                </em>
              </div>

              <div>
                <select
                  name='existing-surveys-sent'
                  id='existing-surveys-sent'
                  onChange={handleSelectedSurveyAlreadySent}
                  value={selectedExistingSurveyBatchSent}
                >
                  <option disabled selected>
                    {' '}
                    -- select an option --{' '}
                  </option>
                  {surveyBankSentExists?.map((surveyCreatedSent, index) => {
                    return (
                      <option key={index} value={surveyCreatedSent.id}>
                        Title: {surveyCreatedSent.title}, Date:{' '}
                        {surveyCreatedSent.creationDate.toString().slice(0, 10)}{' '}
                      </option>
                    );
                  })}
                </select>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <CustomButton
                id={'send-as-new-button'}
                text={'Send as new batch'}
                onClick={() => {
                  setShowSendModal(true);
                  setShowModal(false);
                }}
              />
              {selectedExistingSurveyBatchSent && (
                <CustomButton
                  id={'add-to-existing-batch-button'}
                  text={'Send from selected batch'}
                  onClick={sendFromSelectedBatch}
                />
              )}
            </Modal.Footer>
          </Modal>
        </>
      )}

      {isTemplate &&
        !surveyBank &&
        survey.creator !== undefined &&
        survey.creator == ownerId && (
          <Modal
            show={showDeleteModal}
            onHide={handleCloseDeleteModal}
            backdrop='static'
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>Delete survey</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Are you sure you want to delete the survey <i>"{survey.title}"</i>
              ? If the survey is in the Survey Bank, it will be deleted from
              there too.
            </Modal.Body>
            <Modal.Footer>
              <CustomButton
                id={'back-button'}
                text={'Back'}
                onClick={handleCloseDeleteModal}
              />
              <CustomButton
                id={'delete-button'}
                text={'Delete'}
                onClick={handleRemoveSurveyTemp}
              />
            </Modal.Footer>
          </Modal>
        )}
    </div>
  );
};

export default SurveyRow;
