import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './Empty.css';
import AnswerView from '../../components/answerView/AnswerView';
import { getSurveyIdByURL } from '../../functions/Misc/URLutils';
import {
  answerGetFinishedSections,
  answerGetSurvey,
  typeReturnSurvey,
} from '../../functions/Sent_functions/sentUtils';
import { getAnonymByURL } from '../../functions/Misc/URLutils';
import {
  createURLrelation,
  URLrelation,
} from '../../functions/Misc/URLrelation';
import hashString from '../../functions/scripts/urlScript';
import { createInstanceSentSurvey } from '../../functions/Sent_functions/sentUtils';
import { getSurveySentById } from '../../functions/Sent_functions/surveySent';
import { getSurveyCreatedById } from '../../functions/Created_functions/surveyCreated';

const Empty = () => {
  const { uniqueURL } = useParams<{ uniqueURL: string }>(); //Hämtar unika url biten från pathen ".../empty/uniqueURL"
  const [surveySentId, setSurveySentId] = useState<string>('');
  const [surveyData, setSurveyData] = useState<typeReturnSurvey>();
  const [finishedSections, setFinishedSections] = useState<string[]>([]);
  const [newUrl, setNewUrl] = useState<string>('');
  const [hasNavigated, setHasNavigated] = useState<boolean>(false);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  //To get the survey information based on the URL
  const renderSurvey = () => {
    if (uniqueURL) {
      //A guard
      setIsLoading(true); //Hinders faulty stuff from showing
      getAnonymByURL(uniqueURL).then((anonymous) => {
        //Gets anonymous state of a survey
        if (anonymous) {
          const info = { team: [], anonymous: true };
          getSurveyIdByURL(uniqueURL).then((middleId) => {
            //Fetches the Survey Id based on current url
            getSurveySentById(middleId).then((middleSurvey) => {
              getSurveyCreatedById(middleSurvey.parentSurveyId).then(
                (parentSurvey) => {
                  createInstanceSentSurvey(parentSurvey.id, info).then(
                    (newSurveySentId) => {
                      hashString(newSurveySentId).then((newURL) => {
                        //Creates a new URL based on the newly created survey instance
                        const uRLDataRelation: URLrelation = {
                          id: newURL,
                          sentId: newSurveySentId,
                          anonymous: anonymous,
                        };
                        createURLrelation(uRLDataRelation).then(() => {
                          setNewUrl(newURL);
                        });
                      });
                    }
                  );
                }
              );
            });
          });
        } else {
          //IF survey was not anonymous
          getSurveyIdByURL(uniqueURL).then((middleId) => {
            //Fetch they data of the survey
            setSurveySentId(middleId);
          });
        }
      });
      setIsLoading(false); // Set loading state to false
    }
  };

  //If a newUrl have been created, navigate to that URL
  useEffect(() => {
    if (newUrl !== '') {
      navigate('/empty/' + newUrl);
      setHasNavigated(true);
    }
  }, [newUrl]);

  //Hit kommer den en gång - BRA
  //TODO: Kolla om denna ens behövs???? Tror vår else sats i stora funktinonen redan fixar detta
  useEffect(() => {
    if (hasNavigated && newUrl !== '') {
      getSurveyIdByURL(newUrl).then((SentId: any) => {
        setSurveySentId(SentId);
      });
    }
  }, [newUrl, hasNavigated]);

  //To get the data that survey sent id contain (questions ect)
  const handleGetSurveyData = () => {
    if (surveySentId !== '') {
      answerGetSurvey(surveySentId).then((surveyData: any) => {
        setSurveyData(surveyData);
      });
      answerGetFinishedSections(surveySentId).then((res) => {
        setFinishedSections(res);
      });
    }
  };

  useEffect(() => {
    if (surveySentId !== '') {
      handleGetSurveyData();
    }
  }, [surveySentId]);

  useEffect(() => {
    if (uniqueURL) {
      renderSurvey();
    }
  }, []);

  if (isLoading) {
    return <div>Loading...</div>; // Show a loading state until the data is fetched
  }

  return (
    <div style={{ height: '100%' }}>
      {surveyData && surveyData !== undefined && (
        <AnswerView
          toggleRefetchSections={handleGetSurveyData}
          finishedSections={finishedSections}
          surveyCompleted={surveyData.complete}
          sections={surveyData.sectionArray}
          surveyTitle={surveyData.title}
          surveyDescription={surveyData.surveyDescription}
          surveyId={surveySentId}
          sectionOrder={surveyData.sectionOrder}
        />
      )}
    </div>
  );
};

export default Empty;
