import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import './SendSurvey.css';
import DoneButton from '../Buttons/DoneButton';
import { surveySend, surveyType } from '../../assets/types';
import CustomButton from '../Buttons/CustomButton';
import SendSurveyChoices from './SendSurveyChoices';
import SendSurveyInfo from './SendSurveyInfo';
import {
  createInstanceSentSurvey,
  surveySentInfo,
} from '../../functions/Sent_functions/sentUtils';
import hashString from '../../functions/scripts/urlScript';
import {
  createURLrelation,
  URLrelation,
} from '../../functions/Misc/URLrelation';
import { copyTempToCreatedSurvey } from '../../functions/Created_functions/createdUtils';
import { getStaff, staff } from '../../functions/Misc/staffCreated';
import Select, { MultiValue } from 'react-select';
import { updateSurveyCreated } from '../../functions/Created_functions/surveyCreated';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';

interface Props {
  survey: surveySend;
  showModal: boolean;
  handleCloseModal: () => void;
  isTemplate?: boolean;
  missingSections: boolean;
  missingQuestions: boolean;
}

const SendSurvey = ({
  survey,
  handleCloseModal,
  showModal,
  isTemplate,
  missingSections,
  missingQuestions,
}: Props) => {
  const { user } = useAuth0();
  const ownerId = user?.sub;
  const [single, setSingle] = useState(false);
  const [multiple, setMultiple] = useState(false);
  const [anonymous, setAnonymous] = useState(false);
  const [answerSelf, setAnswerSelf] = useState(false);
  const [startDisabled, setStartDisabled] = useState(true);
  const [ids, setIds] = useState<string[]>([]);
  const [page1, setPage1] = useState(true);
  const [idMissing, setIdMissing] = useState(false);
  const [finishedHandle, setFinishedHandle] = useState(false);
  let idAndUrl: any[] = [];
  const [hej, setHej] = useState<any[]>([]);
  const [showModalURL, setShowModalURL] = useState(false);
  const [theURL, setTheURL] = useState(' ');
  const [patId, setPatId] = useState(' ');
  const [idAndUrlState, setIdAndUrlState] = useState<any[]>([]);
  const [staffIds, setStaffIds] = useState<staff[]>([]);
  const [selectedStaff, setSelectedStaff] = useState<string[]>([]);
  const navigate = useNavigate();
  const [hasLoadedUrlRelation, setHasLoadedUrlRelation] =
    useState<boolean>(false);

  const handleNext = () => {
    if (ids.length === 0 && !anonymous) {
      setIdMissing(true);
      return;
    } else {
      if (answerSelf && single) {
        handleCloseModal();
        setSingle(false);
        setMultiple(false);
        setAnonymous(false);
        setAnswerSelf(false);
        setStartDisabled(true);
        setIds([]);
        return;
      }
    }
    setIdMissing(false);
    setPage1(false);
  };

  const handleSend = (data: any) => {
    ///ANONYM--------------------------------------------------
    if (anonymous) {
      const info: surveySentInfo = {
        team:
          selectedStaff.length === 0
            ? []
            : [...selectedStaff, ownerId as string],
        anonymous: anonymous,
        middle: true,
      };
      if (isTemplate) {
        copyTempToCreatedSurvey(survey.id).then((newId) => {
          const updateCreated = {
            ownerId: ownerId,
            id: newId,
            team:
              selectedStaff.length === 0
                ? []
                : [...selectedStaff, ownerId as string],
          };
          updateSurveyCreated(updateCreated).then(() => {
            createInstanceSentSurvey(newId, info).then((surveySentId) => {
              hashString(surveySentId).then((newURL) => {
                setTheURL(newURL);
                const uRLDataRelation: URLrelation = {
                  id: newURL,
                  sentId: surveySentId,
                  anonymous: anonymous,
                };
                createURLrelation(uRLDataRelation).then((newnew) => {
                  const obj = { pat_id: 'Anon', url: newURL };
                  idAndUrl.push(obj);
                  setIdAndUrlState(idAndUrl);
                  // setShowModalURL(true);
                  setHasLoadedUrlRelation(true);
                });
              });
            });
          });
        });
      } else {
        const updateCreated = {
          id: survey.id,
          ownerId: ownerId,
          team:
            selectedStaff.length === 0
              ? []
              : [...selectedStaff, ownerId as string],
        };
        updateSurveyCreated(updateCreated).then(() => {
          createInstanceSentSurvey(survey.id, info).then((surveySentId) => {
            hashString(surveySentId).then((newURL) => {
              setTheURL(newURL);
              const uRLDataRelation: URLrelation = {
                id: newURL,
                sentId: surveySentId,
                anonymous: anonymous,
              };
              createURLrelation(uRLDataRelation).then((newnew) => {
                const obj = { pat_id: 'Anon', url: newURL };
                idAndUrl.push(obj);
                setIdAndUrlState(idAndUrl);
                // setShowModalURL(true);
                setHasLoadedUrlRelation(true);
              });
            });
          });
        });
        setPatId('Anonym');
      }
    }

    ////INTE ANONYM ------------------------------------------
    else {
      if (isTemplate) {
        copyTempToCreatedSurvey(survey.id).then((newId) => {
          const updateCreated = {
            id: newId,
            ownerId: ownerId,
            team:
              selectedStaff.length === 0
                ? []
                : [...selectedStaff, ownerId as string],
          };
          updateSurveyCreated(updateCreated).then(() => {
            ids.forEach((id) => {
              const info: surveySentInfo = {
                team:
                  selectedStaff.length === 0
                    ? []
                    : [...selectedStaff, ownerId as string],
                patientId: id,
                anonymous: anonymous,
              };
              createInstanceSentSurvey(newId, info).then((surveySentId) => {
                hashString(surveySentId).then((newURL) => {
                  setTheURL(newURL);
                  const uRLDataRelation: URLrelation = {
                    id: newURL,
                    sentId: surveySentId,
                    anonymous: anonymous,
                  };
                  createURLrelation(uRLDataRelation)
                    .then((newnew) => {
                      setPatId(id);
                      const obj = { pat_id: id, url: newURL };
                      idAndUrl.push(obj);
                    })
                    .then(() => {
                      if (idAndUrl.length === ids.length) {
                        setIdAndUrlState(idAndUrl);
                      }
                    });
                });
              });
            });
          });
        });
      } else {
        const updateCreated = {
          id: survey.id,
          ownerId: ownerId,
          team:
            selectedStaff.length === 0
              ? []
              : [...selectedStaff, ownerId as string],
        };
        updateSurveyCreated(updateCreated).then(() => {
          ids.forEach((id) => {
            const info: surveySentInfo = {
              team:
                selectedStaff.length === 0
                  ? []
                  : [...selectedStaff, ownerId as string],
              patientId: id,
              anonymous: anonymous,
            };
            createInstanceSentSurvey(survey.id, info).then((surveySentId) => {
              hashString(surveySentId).then((newURL) => {
                setTheURL(newURL);
                const uRLDataRelation: URLrelation = {
                  id: newURL,
                  sentId: surveySentId,
                  anonymous: anonymous,
                };
                createURLrelation(uRLDataRelation)
                  .then((newnew) => {
                    setPatId(id);
                    const obj = { pat_id: id, url: newURL };
                    idAndUrl.push(obj);
                  })
                  .then(() => {
                    if (idAndUrl.length === ids.length) {
                      setIdAndUrlState(idAndUrl);
                    }
                  });
              });
            });
          });
        });
      }
    }
    handleCloseModal();
    setShowModalURL(true);
    setFinishedHandle(true);
  };

  useEffect(() => {
    if (answerSelf && (idAndUrlState.length == ids.length || anonymous ) && finishedHandle) {
      navigate(`/empty/${idAndUrlState[0].url}`);
      setIdAndUrlState([]);
      idAndUrl = [];
    } else if (idAndUrlState.length == ids.length && finishedHandle) {
      setHasLoadedUrlRelation(true);
      idAndUrl = [];
    }
  }, [idAndUrlState]);

  const handleSelectPatientIds = (value: any) => {
    let temp: string[] = [];
    value.forEach((element: any) => {
      temp.push(element.value);
    });
    setIds(temp);
  };
  const handleSelectPatientId = (value: any) => {
    if (value === null) {
      setIds([]);
      return;
    }
    let temp = [value.value];
    setIds(temp);
  };

  function createOptionsListTeam(list: staff[]) {
    const newList = list.map((obj) => {
      const tempName: string = obj.name as string;
      const tempId: string = obj.id as string;
      return { value: tempId, label: tempName };
    });
    return newList;
  }

  const handleSelectTeamChange = (
    value: MultiValue<{ label: string; value: string }>
  ) => {
    let team: string[] = [];
    value.map((option: { label: string; value: string }) =>
      team.push(option.value)
    );
    setSelectedStaff(team);
  };

  const handleCloseUrlModal = () => {
    setShowModalURL(false);
    setHasLoadedUrlRelation(false);
    setIdAndUrlState([]);
    setIds([])
    setAnonymous(false)
    setMultiple(false)
    setSelectedStaff([])
    setSingle(false)
    setAnswerSelf(false)
    setStartDisabled(true)
    setPage1(true)
    setFinishedHandle(false)
    setStaffIds([])
    setIdMissing(false)
    idAndUrl = [];
  };

  useEffect(() => {
    getStaff().then((res) => {
      setStaffIds(res);
    });
  }, []);


  return (
    <div>
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        backdrop={true}
        keyboard={true}
        size='lg'
      >
        <Modal.Header closeButton>
          <Modal.Title className='send-survey-title'>
            Send survey - {survey.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='scrollable-modal-body'>
          {missingQuestions || missingSections ? (
            <div className='no-send-container'>
              you cannot send a survey without questions or sections
            </div>
          ) : (
            <div className='send-survey-container'>
              {page1 ? (
                <>
                  <SendSurveyChoices
                    ids={ids}
                    setIds={setIds}
                    anonymous={anonymous}
                    setAnonymous={setAnonymous}
                    answerSelf={answerSelf}
                    setAnswerSelf={setAnswerSelf}
                    single={single}
                    setSingle={setSingle}
                    multiple={multiple}
                    setMultiple={setMultiple}
                    startDisabled={startDisabled}
                    setStartDisabled={setStartDisabled}
                    handleSelectPatientId={handleSelectPatientId}
                    handleSelectPatientIds={handleSelectPatientIds}
                    idMissing={idMissing}
                  />
                  {isTemplate && (
                    <div className='select-team-div'>
                      {/* isTemplate ELLER create new batch ??*/}
                      <Select
                        placeholder='Team'
                        options={createOptionsListTeam(staffIds)}
                        isMulti
                        isClearable
                        isSearchable
                        onChange={(value) => {
                          handleSelectTeamChange(value);
                        }}
                      />
                    </div>
                  )}
                </>
              ) : (
                <SendSurveyInfo
                  ids={ids}
                  handleSend={handleSend}
                  anonymous={anonymous}
                />
              )}
              <div className='send-survey-buttons'>
                {page1 ? (
                  <DoneButton
                    text={answerSelf ? 'Answer' : 'Send'}
                    onClick={() => handleSend([])}
                    disabled={!anonymous && ids.length === 0}
                  />
                ) : (
                  <>
                    <CustomButton text='back' onClick={() => setPage1(true)} />
                  </>
                )}
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
      <Modal
        size='lg'
        show={showModalURL}
        backdrop='static'
        onHide={handleCloseUrlModal} // setIdAndUrlState([]) ; idAndUrl=[]}}
        centered
      >
        <Modal.Header closeButton={hasLoadedUrlRelation}>
          <Modal.Title>URL to survey</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='url-link-modal'>
            {!hasLoadedUrlRelation && <p>Loading...</p>}
            {hasLoadedUrlRelation &&
              idAndUrlState.map((obj) => (
                <p>
                  Patient: {obj.pat_id}, Link:
                  https://forms.datatovalue.se/empty/{obj.url}
                </p>
              ))}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default SendSurvey;
