import './Pagination.css';

const Pagination = ({
  maxPageNumbers,
  nPages,
  currentPage,
  setCurrentPage,
}: {
  maxPageNumbers: number;
  nPages: number;
  currentPage: any;
  setCurrentPage: any;
}) => {
  const pageNumbers = [...Array(nPages + 1).keys()].slice(1);

  function getNumbers() {
    if (nPages <= maxPageNumbers) {
      return pageNumbers;
    }
    const start = Math.max(2, currentPage - Math.floor(maxPageNumbers / 2));
    const end = Math.min(nPages - 1, start + maxPageNumbers - 1);

    let arr: any = [1];
    if (start > 2) {
      arr.push('...');
    }
    for (let i = start; i <= end; i++) {
      arr.push(i);
    }
    if (end < nPages - 1) {
      arr.push('...');
    }
    arr.push(nPages);
    return arr;
  }

  function isPageNumber(n: number | string): boolean {
    return typeof n === 'number';
  }

  function changeCPage(id: number) {
    setCurrentPage(id);
  }

  const nextPage = () => {
    if (currentPage != nPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage !== 1) setCurrentPage(currentPage - 1);
  };

  return (
    <nav>
      <ul className='pagination'>
        <li className='page-item'>
          <a href='#' className='page-link' onClick={prevPage}>
            Prev
          </a>
        </li>

        {getNumbers().map((n: any, i: any) => (
          <li
            className={`page-item ${
              isPageNumber(n) && currentPage === n ? 'active' : ''
            }`}
            key={i}
          >
            <a
              href='#'
              className={`page-link ${!isPageNumber(n) ? 'disabled' : ''}`}
              onClick={() => isPageNumber(n) && changeCPage(n)}
            >
              {n}
            </a>
          </li>
        ))}
        <li className='page-item'>
          <a href='#' className='page-link' onClick={nextPage}>
            Next
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;
