import{ keyword }from '../../assets/types';
import createClient from "../axios";

//Fälten som är nödvändiga för denna typen. Frågetecken denoterar ett friviligt alternativ
export type surveyCreatedCreate = {
    title: string;
    titleEmoji?: string;
    description: string;
    department: string;
    ownerId: string;
    keywords?: string[];
    process: string;
    sectionOrder?: string[];
    team?: string[];
}

//Fält för att uppdatera en survey. allt friviligt förutom id.
export type surveyCreatedUpdate = {
    id: string;
    title?: string;
    titleEmoji?: string;
    description?: string;
    department?: string;
    ownerId?: string;
    keywords?: string[];
    process?: string;
    sectionOrder?: string[];
    team?: string[];
}

//The type returned when getting a survey
export type surveyCreatedExtract = {
    id: string;
    title: string;
    titleEmoji?: string;
    description: string;
    creationDate: Date;
    department: string;
    sectionOrder: string[];
    ownerID: string;
    keywords?: string[];
    process: string;
    team: string[];
}


/**
 * Creates a survey created.
 * @param data type surveyCreatedCreate, containing the necessary data.
 * @returns string, the id of the created survey.
 */
export async function createSurveyCreated(data: surveyCreatedCreate):Promise<string> {
    const client = await createClient();
    return client.post('/surveycreated', data).then((response) => {
            return response.data.id
        }).catch((error) => console.log(error));
}

/**
 * Get a created survey by id
 * @param id id of the survey
 * @returns type surveyCreatedExtract, the survey with the id.
 */
export async function getSurveyCreatedById(id: string):Promise<surveyCreatedExtract> {
    const client = await createClient();
    return client.get('/surveycreated/' + id) //id is the survey id
        .then((response) => {
            return response.data;
        })
        .catch((error) => console.log(error));
}

/**
 * Updates a created survey.
 * @param data surveycreatedupdate containing id and fields to update.
 * @returns string, the id of the updated survey.
 */
export async function updateSurveyCreated(data: surveyCreatedUpdate):Promise<String> {
    const client = await createClient();
    if (data.keywords) {
        const keywordIds: { id: string }[] = [];
        for (let i = 0; i < data.keywords.length; i++) {
            keywordIds.push({ id: data.keywords[i] });
        };
        const dataUpd = {
            id: data.id,
            title: data.title,
            titleEmoji: data.title,
            description: data.description,
            department: data.department,
            ownerId: data.ownerId,
            keywords: {
                connect: keywordIds,
            },
            process: data.process,
            sectionOrder: data.sectionOrder
        }
        return client.put('/surveycreated/' + dataUpd.id, dataUpd)
        .then((response) => {
            return response.data.id;
        })
        .catch((error) => console.log(error));
    }
    return client.put('/surveycreated/' + data.id, data) //data is a json object
        .then((response) => {
            return response.data.id;
        })
        .catch((error) => console.log(error));
}

/**
 * Deletes a created survey.
 * @param id string of survey to be deleted
 * @returns string, the id of the deleted survey.
 */
export async function deleteSurveyCreated(id: string):Promise<String> {
    const client = await createClient();
    return client.delete('/surveycreated/' + id) //data is a json object
        .then((response) => {
            return response.data;
        })
        .catch((error) => console.log(error));
}

/**
 * Gets all surveys belonging to an owner
 * @param id the id of the owner
 * @returns type surveyCreatedExtract[], an array containing all surveys.
 */
export async function getSurveysByOwner(staffId: string):Promise<surveyCreatedExtract[]> {
    const client = await createClient();
    return client.get('/surveycreated/owner/' + staffId, {params: {
        id: staffId
    }}) //id is the owner id
        .then((response) => {
            return response.data;
        })
        .catch((error) => console.log(error));
}

/**
 * Gets the keywords for a survey created
 * @param id The id of the survey
 * @returns keyword[], an array of keywords with value and id
 */
export async function getKeywordsBySurveyCreated(id: string): Promise<keyword[]>{
    const client = await createClient();
    return client.get('/keywords/surveycreated/' +  id)
    .then((response)=> {
        return response.data;
    })
    .catch((error)=>console.log(error));
}

/**
 * Gets the surveys with given team member
 * @param id The id of the team member
 * @returns surveyCreatedExtract[], an array of surveys
 */
export async function getSurveysByTeamMember(id: string): Promise<surveyCreatedExtract[]>{
    const client = await createClient();
    return client.get('/surveycreated/team/' +  id)
    .then((response)=> {
        return response.data;
    })
    .catch((error)=>console.log(error));
}
