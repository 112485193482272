import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import ViewSurveyListAnswers from './ViewSurveyListAnswers';
import {
  Answer,
  questionSurveyCreated,
  questionTypeRetrive,
} from '../../assets/types';
import './ViewSurvey.css';
import { Emoji } from 'emoji-picker-react';
import { Accordion } from 'react-bootstrap';
import ViewFollowUpQuestion from './ViewFollowUpQuestion';

interface Props {
  question: questionTypeRetrive;
  followUp: questionSurveyCreated[];
  allQuestions: questionTypeRetrive[];
}

const ViewSurveyQuestion = ({ question, followUp, allQuestions }: Props) => {
  const {
    title,
    titleEmoji,
    description,
    type,
    mandatory,
    qoptions,
    emojiOpt,
    bounds,
    multipleChoice,
  } = question;
  //meta data

  function reWriteAnswers(qoptions?: string[], emojiOpt?: string[]) {
    if (qoptions?.length !== 0 || qoptions !== undefined) {
      const answers: Answer[] = qoptions
        ? qoptions.map((qoption: any, index: any) => ({
            answer: qoption as string,
            emoji: (emojiOpt ? emojiOpt[index] : undefined) as string,
          }))
        : [];
      return answers;
    }
  }

  const answers: Answer[] = reWriteAnswers(qoptions, emojiOpt) as Answer[];

  return (
    <div className='answer-view-question-container'>
      <Card>
        <Card.Header>
          <div>
            <div className='answer-view-quest-container-title-div'>
              <Col md={12}>
                <div className='answer-view-quest-title-div'>
                  <p>
                    {title}{' '}
                    <i style={{ fontSize: 'small' }}>
                      {' '}
                      {mandatory ? 'Required' : 'Optional'}
                    </i>
                  </p>
                  <div className='answer-view-quest-title-emoji-div'>
                    <Emoji unified={titleEmoji as string} size={40} />
                  </div>
                </div>
              </Col>
            </div>
            <div className='quest-description-div'>
              <p>{description}</p>
            </div>
          </div>
        </Card.Header>
        <Row>
          {type !== 'multiple choice' && (
            <i className='type-text'>
              input {type}{' '}
              {type === 'number' &&
                bounds !== undefined &&
                bounds.length > 0 && (
                  <>
                    between {bounds[0]} and {bounds[1]}
                  </>
                )}
              :
            </i>
          )}
          <ViewSurveyListAnswers
            answers={answers}
            type={type}
            multipleChoice={multipleChoice}
          />
        </Row>
        {followUp.length > 0 && (
          <Accordion>
            <ViewFollowUpQuestion
              parent={{
                name: question.title,
                id: question.id as string,
              }}
              questions={followUp}
              allQuestions={allQuestions}
            />
          </Accordion>
        )}
      </Card>
    </div>
  );
};

export default ViewSurveyQuestion;
