import React, { useEffect, useState } from 'react';
import SurveySection from '../../components/SurveySection/SurveySection';
import './SurveyBank.css';
import FilterAndSearchBar from '../../components/FilterAndSearchBar/FilterAndSearchBar';
import { getAllSurveyTempsInBank, getAllSurveyTemps, surveyTempExtract } from '../../functions/Template_functions/surveyTemp';
import { getSurveysByOwnerNotCompleted } from '../../functions/Created_functions/createdUtils';
import { useAuth0 } from '@auth0/auth0-react';
import { surveyCreatedExtract } from '../../functions/Created_functions/surveyCreated';

interface Props {
  createSurvey?: boolean;
  setSurveyTemplate?: (surveyTemplateId: string) => void;
}

function SurveyBank({ createSurvey, setSurveyTemplate }: Props) {
  const { isAuthenticated, user, isLoading } = useAuth0();  
  const ownerId = user?.sub;
  const [data, setData] = useState([]);
  const [surveysToSend, setSurveysToSend] = useState([]);
  const [resetCurrentPage, setResetCurrentPage] = useState<boolean>(false);
  const [surveysSent, setSurveysSent] = useState<surveyCreatedExtract[]>([]);
  const [renderPage, setRenderPage] = useState<boolean>(false)


  //TODO KOLLA OM DETTA SKA ÄNDRAS
  async function handleGetSentSurveys() {
    if(ownerId){
    getSurveysByOwnerNotCompleted(ownerId).then((result: any) => {
      setSurveysSent(result);
    });}
  }

  async function handleGetSurveys() {
    getAllSurveyTempsInBank().then((res: any) => {
      setData(res);
    });
  }

  useEffect(() => {
    handleGetSurveys();
    handleGetSentSurveys();
  }, []);

  useEffect(() => {
    handleGetSurveys();
  }, [renderPage]);

  useEffect(() => {
    setSurveysToSend(data);
  }, [data]);

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  if (!isAuthenticated || !user) {
    return <div>User is not logged in.</div>;
  }
  
  return (
    <div className='page'>
      <div className='surveybank'>
        <div className='surveybank-top'>
          <FilterAndSearchBar
            dataToSend={surveysToSend}
            data={data}
            setDataToSend={setSurveysToSend}
            setResetCurrentPage={setResetCurrentPage}
            resetCurrentPage={resetCurrentPage}
          />
        </div>
        <div className='surveybank-bottom'>
          <SurveySection
            showSearchBar={false}
            createSurvey={createSurvey}
            setSurveyTemplate={setSurveyTemplate}
            firstColumn={'Title'}
            secondColumn={'Department'}
            thirdColumn={'No. Responds'}
            fourthColumn={'Date'}
            data={surveysToSend}
            itemsPerPage={9}
            title={'Survey Bank'}
            isTemplate={true}
            surveyBank={true}
            // isMySurvey={false}
            setResetCurrentPage={setResetCurrentPage}
            resetCurrentPage={resetCurrentPage}
            surveyBankCompareData={surveysSent.length !== 0 ? surveysSent : []}
            isFavAble={true}
            setRenderPage={setRenderPage}
            renderPage={renderPage}
            
          />
        </div>
      </div>
    </div>
  );
}

export default SurveyBank;
