import * as question from './questionTemp';
import * as section from './sectionTemp';
import * as survey from './surveyTemp';
import * as types from '../../assets/types';
import * as surveyscreated from '../Created_functions/surveyCreated';
import * as sectionscreated from '../Created_functions/sectionCreated';
import * as questionscreated from '../Created_functions/questionCreated';
import createClient from "../axios"
import { Answer } from '../../assets/types';

export type questionTempCreateAnswer = {
    title: string;
    titleEmoji?: string;
    description: string;
    type: string;
    mandatory: boolean;
    answers: Answer[];
    bounds: number[];
    condition: string[];
    multipleChoice: boolean;
    parentQuestionId?: string;
    sectionId: string;
}

export type questionTempUpdateAnswer = {
    id: string;
    title?: string;
    titleEmoji?: string;
    description?: string;
    type?: string;
    mandatory?: boolean;
    answers?: Answer[];
    bounds?: number[];
    condition?: string[];
    multipleChoice?: boolean;
    parentQuestionId?: string;
    sectionId?: string;
}

export async function getQuestionsFromSurveyBank(sectionId: string) : Promise <(types.questionTypeRetrive)[]> {
    const getQuestions = question.getQuestionTempBySectionId(sectionId);
    let qarray: types.questionTypeRetrive[] = [];

    for (let i = 0; i < (await getQuestions).length && (await getQuestions).length> 0; i++) {
        qarray[i] = {
            id: (await getQuestions)[i].id,
            title: (await getQuestions)[i].title,
            titleEmoji: (await getQuestions)[i].titleEmoji,
            description: (await getQuestions)[i].description,
            type: (await getQuestions)[i].type,
            mandatory: (await getQuestions)[i].mandatory,
            qoptions: (await getQuestions)[i].qoptions,
            emojiOpt: (await getQuestions)[i].emojiOpt,
            bounds: (await getQuestions)[i].bounds,
            condition: (await getQuestions)[i].condition,
            multipleChoice: (await getQuestions)[i].multipleChoice,
            sectionId: (await getQuestions)[i].sectionId,
            parentQuestionId: (await getQuestions)[i].parentQuestionId
        }
    };
    return qarray;
}

export async function getSectionsFromSurveyBank(surveyTempId: string):Promise<types.sectionFromSurveyBank[]>{
    const getSection = await section.getSectionTempBySurveyId(surveyTempId);
    let array: types.sectionFromSurveyBank[] = [];
    
    for (let i = 0; i < (await getSection).length; i++) {
        const questions: types.questionTypeRetrive[] = await getQuestionsFromSurveyBank((await getSection)[i].id);
        array[i] = {
            id : (await getSection)[i].id,
            title: (await getSection)[i].title,
            description:(await getSection)[i].description,
            questionOrder: (await getSection)[i].questionOrder,
            questions: questions
        }
    };
    return array; 
}

export async function getSurveyFromSurveyBank(surveyTempId: string):Promise<types.surveyFromSurveyBank>{
    const surveyGet: survey.surveyTempExtract = await survey.getSurveyTempById(surveyTempId);
    const getSections: types.sectionFromSurveyBank[] = await getSectionsFromSurveyBank(surveyTempId);
    //hämta keywords också!! TODO
    const returnSurvey: types.surveyFromSurveyBank = {
        title: surveyGet.title,
        titleEmoji: surveyGet.titleEmoji,
        description: surveyGet.description,
        department: surveyGet.department,
        creator: surveyGet.creator,
        keywords: surveyGet.keywords,
        process: surveyGet.process,
        sectionOrder: surveyGet.sectionOrder,
        sections: getSections
    };
    return returnSurvey;
}

/**
 * Copies all questions from a sectionsCreated to a sectionTemp
 * @param createdSectionId Id of the section to copy from
 * @param tempSectionId Id of the section to copy to
 * @returns Tuple of the ids of the created and sent questions
 */
export async function createTempQuestionCreated(createdSectionId: string, tempSectionId: string): Promise<[string, string][]> {
    //Tuple to keep track of which ids match eachother
    //This is to be able to change the questionOrder in the section.
    //sent, created
    const idTuple: [string, string][] = [];

    //All created questions in this created section.
    const createdQuestions: questionscreated.questionCreatedExtract[] = await questionscreated.getQuestionCreatedBySectionId(createdSectionId);

    //Place all the id's of created questions in a list
    const createdIds: string[] = []
    for(let i = 0; i < createdQuestions.length; i++){
        createdIds.push(createdQuestions[i].id);
    }

    //for loop for creating root questions! Aka questions without followupquestions
    for(let i = 0; i < createdQuestions.length; i++) {
        if(createdQuestions[i].parentQuestionId == undefined){
            const questionSent: question.questionTempCreate = {
                title: createdQuestions[i].title,
                titleEmoji: createdQuestions[i].titleEmoji,
                type: createdQuestions[i].type,
                mandatory: createdQuestions[i].mandatory,
                qoptions: createdQuestions[i].qoptions,
                emojiOpt: createdQuestions[i].emojiOpt,
                bounds: createdQuestions[i].bounds,
                condition: createdQuestions[i].condition,
                description: createdQuestions[i].description,
                multipleChoice: createdQuestions[i].multipleChoice,
                sectionId: tempSectionId
            };
            //When pushed sent is the id of the new question
            const temp:string = await question.createQuestionTemp(questionSent); 
            //pushes the sentQuestion id and the created question id.
            idTuple.push([temp, createdQuestions[i].id]); 
            //Removes the created id from the list, so that all that is remaining after done for loop is the followup ids
            const index = createdIds.indexOf(createdQuestions[i].id);
            if(index > -1){
              createdIds.splice(i, 1)
            }; 
        }
        //for loop creating follow up questions!
        //First if is if there are followup questions.
        if(createdIds.length > 0 ){
            for(let i = 0; i < createdIds.length; i++){
                if(createdIds.includes(createdQuestions[i].id)){
                    //find questionsent matching id
                    const searchId = createdQuestions[i].parentQuestionId;
                    const matchingTuple = idTuple.find(([id1, id2]) => id2 === searchId);
                    if(matchingTuple){
                        const questionParentId = matchingTuple[0];

                        const followUpTemp: question.questionTempCreate = {
                            title: createdQuestions[i].title,
                            titleEmoji: createdQuestions[i].titleEmoji,
                            type: createdQuestions[i].type,
                            mandatory: createdQuestions[i].mandatory,
                            qoptions: createdQuestions[i].qoptions,
                            emojiOpt: createdQuestions[i].emojiOpt,
                            bounds: createdQuestions[i].bounds,
                            condition: createdQuestions[i].condition,
                            description: createdQuestions[i].description,
                            multipleChoice: createdQuestions[i].multipleChoice,
                            sectionId: tempSectionId,
                            parentQuestionId: questionParentId
                        };
                        //This is currently unused but create returns string, use if want or remove
                        const sentFollowUp: string = await question.createQuestionTemp(followUpTemp);
                    }
                }
            }
        }    
    }
    return idTuple;
}
 
//Uppdatera question order listan med hjälp av den tillbaka givna tuplen från questionCreated
//gör tuplar att returnera till pappa funktionen
/**
 * Copies all sections from a created survey to a temp survey.
 * @param createdSurveyId Id of the created survey to copy from.
 * @param sentSurveyId Id of the temp survey to copy to.
 * @returns A list of tuples with the temp and created section ids.
 */
export async function createTempSectionsCreated(createdSurveyId: string, tempSurveyId: string): Promise<[string, string][]>{
    //Gets all 
    const sectionsCreated: sectionscreated.sectionCreatedExtract[] = await sectionscreated.getSectionCreatedBySurveyId(createdSurveyId);
    const sectionTempTuple: [string, string][] = [];
    for(let i = 0; i < sectionsCreated.length; i++) {
        const createTempSection: section.sectionTempCreate = {
            title: sectionsCreated[i].title,
            titleEmoji: sectionsCreated[i].titleEmoji,
            description: sectionsCreated[i].description,
            questionOrder: sectionsCreated[i].questionOrder,
            surveyId: tempSurveyId
        }
        const sectionId: string = await section.createSectionTemp(createTempSection);
        sectionTempTuple.push([sectionId, sectionsCreated[i].id]); //pusha båda ids här!! sent, created
        const questionsTemp: [string, string][] = await createTempQuestionCreated(sectionsCreated[i].id, sectionId);
        const newQuestionOrder: string[] = [];
        for(let j = 0; j < sectionsCreated[i].questionOrder.length; j++){
            const searchId = sectionsCreated[i].questionOrder[j];
            const matchingTuple = questionsTemp.find(([id1, id2]) => id2 === searchId);
            if(matchingTuple){
                newQuestionOrder.push(matchingTuple[0]);
            };
        };
        const updateTempSection: section.sectionTempUpdate = {
            id: sectionId,
            questionOrder: newQuestionOrder
        };
        const tempUpdated: string = await section.updateSectionTemp(updateTempSection);
    };
    return sectionTempTuple;
}
    
/**
 * Copies a survey created and creates a survey temp with the same information.
 * @param createdId of the survey created to copy
 * @returns surveyTempId of the created survey temp
 */
export async function createInstanceTempSurvey(createdId: string): Promise<string> {
    const copyCreatedSurvey: surveyscreated.surveyCreatedExtract = await surveyscreated.getSurveyCreatedById(createdId);
    const createTempSurvey: survey.surveyTempCreate = {
        title: copyCreatedSurvey.title,
        titleEmoji: copyCreatedSurvey.titleEmoji,
        description: copyCreatedSurvey.description,
        department: copyCreatedSurvey.department,
        creator: copyCreatedSurvey.ownerID,
        keywords: copyCreatedSurvey.keywords,
        process: copyCreatedSurvey.process,
        sectionOrder: copyCreatedSurvey.sectionOrder,
    };
    const surveySentId = await survey.createSurveyTemp(createTempSurvey);
    const sectionSentCreated: [string, string][] = await createTempSectionsCreated(createdId, surveySentId);
    const newSectionOrder: string[] = [];
    for(let j = 0; j < copyCreatedSurvey.sectionOrder.length; j++){
        const searchId = copyCreatedSurvey.sectionOrder[j];
        const matchingTuple = sectionSentCreated.find(([id1, id2]) => id2 === searchId);
        if(matchingTuple){
            newSectionOrder.push(matchingTuple[0]);
        }
    }
    const updateSentSurvey: survey.surveyTempUpdate = {
        id: surveySentId,
        sectionOrder: newSectionOrder
    }
    const sentUpdated: string = await survey.updateSurveyTemp(updateSentSurvey);
    return surveySentId;
}

/**
 * Creates a question temp and maps qoptions and emojiOpt to the question temp.
 * @param data - Data to create a question temp with.
 * @returns {Promise<string>} - Returns the id of the created question temp.
 */
export async function createQuestionTempAnswer(inputData: questionTempCreateAnswer):Promise<string> {
    const qoptions = inputData.answers.map((answer) => answer.answer);
    const emojiOpt = inputData.answers.map((answer) => answer.emoji);
    const client = await createClient();
    let {answers, ...rest} = inputData
    const data : question.questionTempCreate = {
        ...rest,
        qoptions: qoptions,
        emojiOpt: emojiOpt,
    }
    return client.post('/questiontemp/', data)
    .then((response) => {
        return response.data.id
    })
    .catch((error) => console.log(error));
}

/**
 * Updates a question temp and maps qoptions and emojiOpt to the question temp.
 * @param data - Data to update the question temp with.
 * @returns {Promise<string>} - Returns the id of the created question temp.
 */
export async function updateQuestionTempAnswer(inputData: questionTempUpdateAnswer):Promise<string> {
    const qoptions = inputData.answers?.map((answer) => answer.answer);
    const emojiOpt = inputData.answers?.map((answer) => answer.emoji);
    const client = await createClient();
    let {answers, ...rest} = inputData
    const data : question.questionTempUpdate = {
        ...rest,
        qoptions: qoptions,
        emojiOpt: emojiOpt,
    }
    return client.put('/questiontemp/' + inputData.id, data)
    .then((response) => {
        return response.data.id;
    })
    .catch((error) => console.log(error)); 
}


/**
 * Removes the children of given question, and its grandchildren etc. (Recursive function)
 * @param id id of parent question
 * @returns boolean, true if removed else false.
 */
export async function removeChild(id: string):Promise<boolean>{
    try {
        const children: question.questionTempExtract[] = await question.getChildQuestionsTempById(id);
        if(children === undefined || children.length === 0) return false;  
        for (let index = 0; index < children.length; index++) {
            const child = children[index];
            const childId:string = child.id;
            removeChild(child.id)
            question.deleteQuestionTemp(childId)
        }
        return true;
    }
    catch (error) {
        console.error(`An error occurred while removing question with ID ${id}:`, error);
        return false;
    }
}

/**
 * Removes a question based on id, as well as its children.
 * @param id Question id
 * @returns boolean, true if question and children has been deleted else false.
 */
export async function removeQuestionTemp(id: string): Promise<boolean> {
    try {
        await removeChild(id);
        question.deleteQuestionTemp(id);
        return true;
    }
    catch (error) {
        console.error(`An error occurred while removing question with ID ${id}:`, error);
        return false;
    }
}

/**
 * Gets the keywords for a survey template and returns them with the id.
 * @param id The id of the survey
 * @returns surveyKeyword, an object containing survey id and an array of its keywords
 */
export async function getKeywordsForSurveyTemp(id: string): Promise<types.surveyKeyword> {
    const getKeywords: types.keyword[] = await survey.getKeywordsBySurveyTemp(id);
    const surveyKeywords: types.surveyKeyword = {
        id: id,
        keywords: getKeywords
    };
    return surveyKeywords;
}

 /** 
  * Removes a section and its questions recursively.
  * @param id Id of the section to remove
  * @returns id of the removed section
  */
export async function removeSectionTempAndQuestions(id:string):Promise<string>{
    try {
        const questions: question.questionTempExtract[] = await question.getQuestionTempBySectionId(id);
        for(let i = 0; i < questions.length; i++){
            await removeQuestionTemp(questions[i].id);
        }
        section.deleteSectionTemp(id);
        return id;
    } catch (error) {
        console.error(`An error occurred while removing section with ID ${id}:`, error);
        return (`An error occurred while removing section with ID ${id}:` + error);
    }
}

/*
 * Removes a survey and its sections.
 * @param id Id of the survey to remove
 * @returns id of the removed survey
*/
export async function removeSurveyTempAndSections(id:string):Promise<string>{
    try {
        const sections: section.sectionTempExtract[] = await section.getSectionTempBySurveyId(id);
        for(let i = 0; i < sections.length; i++){
            await removeSectionTempAndQuestions(sections[i].id);
        }
        survey.deleteSurveyTemp(id);
        return id;
    } catch (error) {
        console.error(`An error occurred while removing survey with ID ${id}:`, error);
        return (`An error occurred while removing survey with ID ${id}:` + error);
    }
}
