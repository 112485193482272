import Button from 'react-bootstrap/Button';
import './StartWidget.css';

interface Props {
  title : string;
  icon : any;
  onClick: () => void;
}

const StartWidget = ({ title, icon, onClick }: Props) => {
  return (
    <Button className='startWidget' variant='light' onClick={onClick}>
      <div className='icon-container'>{icon}</div>
      <div className='text-container'>{title}</div>
    </Button>
  );
};

export default StartWidget;
