import React, { useState, useEffect } from 'react';
import './App.css';
import { Routes, Route, useLocation, BrowserRouter } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import StartPage from './views/StartPage/StartPage';
import MySurveys from './views/MySurveys/MySurveys';
import MyCreatedSurveys from './views/MyCreatedSurveys/MyCreatedSurveys';
import Favorites from './views/Favorites/Favorites';
import Login from './views/Login/Login';
import SurveyBank from './views/SurveryBank/SurveyBank';
import CreateSurveyFromTemplate from './views/CreateSurveyFromTemplate/CreateSurveyFromTemplate';
import CreateNewSurvey from './views/CreateNewSurvey/CreateNewSurvey';

import UnathorizedPage from './views/UnathorizedPage';
import SideNavbar from './components/SideNavBar/SideNavbar';
import 'bootstrap/dist/css/bootstrap.css';
import TopBar from './components/TopBar/TopBar';
import Empty from './views/Empty/Empty';
import PatientSearch from './views/PatientSearch/PatientSearch';
import Protected from './components/Protected';
import Cookies from 'js-cookie'
import axios from 'axios';

function App() {
  const { isLoading, isAuthenticated, getIdTokenClaims } = useAuth0();
  const location = useLocation();
  const { pathname } = location;

  let [breadcrumbItems, setBreadcrumbItems] = useState(
    !isAuthenticated ? [] : [{ text: 'Home', link: '/startpage' }]
  );

  useEffect(() => {
    (async () => {

      if (isLoading) { return }
      const token = await getIdTokenClaims()
      await Cookies.set('access_token', `${token?.__raw}`)
    })()

  },[isLoading])

  //Sets the breadcrumb based on the page the user is on
  useEffect(() => {
    if (!isAuthenticated) {
      const updatedBreadcrumbItems = [
        { text: 'Home', link: '/startpage' },
        ...location.pathname
          .split('/')
          .filter((path) => path)
          .map((path) => ({
            text: path.charAt(0).toUpperCase() + path.slice(1),
            link: `/${path}`,
          })),
      ];
      setBreadcrumbItems(updatedBreadcrumbItems);
    }
  }, [location.pathname]);

  return (
    <div className='App'>
      {pathname.slice(0, 6) !== '/empty' &&
        pathname !== '/' &&
        isAuthenticated && <TopBar breadcrumbItems={breadcrumbItems} />}
      {pathname !== '/login' &&
        pathname.slice(0, 6) !== '/' &&
        pathname.slice(0, 6) !== '/empty' &&
        isAuthenticated && <SideNavbar />}

      <Routes>
        <Route path='/' element={<Login />} />
        <Route
          path='/startpage'
          element={<Protected Component={StartPage} />}
        />
        <Route
          path='/mysurveys'
          element={<Protected Component={MySurveys} />}
        />
        <Route
          path='/mycreatedtemplates'
          element={<Protected Component={MyCreatedSurveys} />}
        />
        <Route
          path='/favorites'
          element={<Protected Component={Favorites} />}
        />
        <Route
          path='/surveybank'
          element={<Protected Component={SurveyBank} />}
        />
        <Route
          path='/patientsearch'
          element={<Protected Component={PatientSearch} />}
        />
        <Route
          path='/createnewsurvey'
          element={<Protected Component={CreateNewSurvey} />}
        />
        <Route
          path='/createsurveyfromtemplate'
          element={<Protected Component={CreateSurveyFromTemplate} />}
        />
        <Route path='/login' element={<Login />} />
        <Route path='/empty/:uniqueURL' element={<Empty />} />
        <Route path='/unauth' element={<UnathorizedPage />} />
      </Routes>
    </div>
  );
}
export default App;
