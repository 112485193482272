import React, { useEffect, useState } from 'react';
import SurveySection from '../../components/SurveySection/SurveySection';
import './Favorites.css';
import FilterAndSearchBar from '../../components/FilterAndSearchBar/FilterAndSearchBar';
import { getStaffById } from '../../functions/Misc/staffCreated';
import { getAllSurveyTemps } from '../../functions/Template_functions/surveyTemp';
import { useAuth0 } from '@auth0/auth0-react';

function Favorites() {
  const { isAuthenticated, user, isLoading } = useAuth0();
  const ownerId = user?.sub;
  const [surveyTemps, setSurveyTemps] = useState<any[]>([]);
  const [favorites, setFavorites] = useState<any[]>([]);
  const [renderPage, setRenderPage] = useState<boolean>(false)


  const [favoritesToSend, setFavoritesToSend] = useState<any[]>([]);
  useEffect(() => {
    setFavoritesToSend(favorites);
  }, [favorites]);

  const handleGetFavorites = () => {
    if (ownerId && surveyTemps) {
      getStaffById(ownerId).then((result: any) => {
        const favs = surveyTemps.filter((obj) =>
          result.favorites.includes(obj.id)
        );
        setFavorites(favs);
      });
    }
  };

  const handleGetTemps = () => {
    getAllSurveyTemps().then((result: any) => {
      setSurveyTemps(result);
    });
  };

  useEffect(() => {
    handleGetTemps();
  }, []);

  useEffect(() => {
    handleGetFavorites();
  }, [surveyTemps]);


  useEffect(() => {
    handleGetFavorites();
  }, [renderPage]);

  
  if (isLoading) {
    return <div>Loading ...</div>;
  }

  if (!isAuthenticated || !user) {
    return <div>User is not logged in.</div>;
  }

  return (
    <div className='page'>
      <div className='favorites'>
        <div className='favorites-top'>
          <FilterAndSearchBar
            dataToSend={favoritesToSend}
            data={favorites}
            setDataToSend={setFavoritesToSend}
          />
        </div>
        <div className='favorites-bottom'>
          <SurveySection
            showSearchBar={false}
            firstColumn={'Title'}
            secondColumn={'Department'}
            thirdColumn={'No. Responds'}
            fourthColumn={'Date'}
            data={favoritesToSend}
            itemsPerPage={8}
            title={'Favorites'}
            isTemplate={true}
            isFavAble={true}
            renderPage={renderPage}
            setRenderPage={setRenderPage}
           
          />
        </div>
      </div>
    </div>
  );
}

export default Favorites;
