import createClient from "../axios";
//Variable "data" is a JSON object - The object consists different things based on the function
//Variable "id" is an ID - The ID depends on either the id of the section you want to get or the survey you want to get.

//The type sent in to create a section
export type sectionCreatedCreate ={
    title: string;
    titleEmoji?: string;
    description: string;
    surveyId: string;
    questionOrder?: string[];
}

//the type that you send in to update a question. All fields optional except for id.
export type sectionCreatedUpdate = {
    id: string;
    title?: string;
    titleEmoji?: string;
    description?: string;
    questionOrder?: string[];
}

//the type that is returned when you want to get a section.
export type sectionCreatedExtract = {
    id: string;
    title: string;
    titleEmoji?: string;
    description: string;
    questionOrder: string[];
    surveyId: string;
}

/**
 * Creates a created section
 * @param data sectionCreatedCreate containg necessary data
 * @returns string, the id of the creation section.
 */
export async function createSectionCreated(data: sectionCreatedCreate):Promise<string> {
    const client = await createClient();
    return client.post('/sectioncreated', data) //data is a json object
        .then((response) => {
            return response.data.id;
        })
        .catch((error) => console.log(error));
}

/**
 * Gets a question by ID
 * @param id id of section created
 * @returns type sectionCreatedExtract, the asked for section.
 */
export async function getSectionCreatedById(id: string):Promise<sectionCreatedExtract> {
    const client = await createClient();
    return client.get('/sectioncreated/' + id) //id is the section id
        .then((response) => {
            return response.data;
        })
        .catch((error) => console.log(error));
}

/**
 * Updates a created section
 * @param id data, sectionCreatedUpdate that contains the data and id.
 * @returns string, the id of the updated section
 */
export async function updateSectionCreated(data: sectionCreatedUpdate):Promise<string> {
    const client = await createClient();
    return client.put('/sectioncreated/' + data.id, data) //data is a json object
        .then((response) => {
            return response.data.id;
        })
        .catch((error) => console.log(error));
}

/**
 * Deleta a created section
 * @param id Id of section created
 * @returns string, the id of the deleted section
 */
export async function deleteSectionCreated(id: string):Promise<string> {
    const client = await createClient();
    return client.delete('/sectioncreated/' + id) //data is a json object
        .then((response) => {
            return response.data.id;
        })
        .catch((error) => console.log(error));
}
/**
 * Gets all created sections by the Survey id.
 * @param id id of the survey
 * @returns sectionCreatedExtract[], an array of all the sections.
 */
export async function getSectionCreatedBySurveyId(surveyId: string):Promise<sectionCreatedExtract[]> {
    const client = await createClient();
    return client.get('/sectioncreated/survey/' + surveyId, {params: {
        id: surveyId
    }}) //id is the survey id
        .then((response) => {
            return response.data;
        })
        .catch((error) => console.log(error));
}
