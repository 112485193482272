import * as question from './questionCreated';
import * as section from './sectionCreated';
import * as survey from './surveyCreated';
import { getSurveyInstances } from '../Sent_functions/surveySent';
import * as types from '../../assets/types';
import * as surveyTemp from '../Template_functions/surveyTemp';
import * as sectionTemp from '../Template_functions/sectionTemp';
import * as questionTemp from '../Template_functions/questionTemp';
import { keyword, surveyKeyword } from '../../assets/types'
import * as TempUtils from '../Template_functions/tempUtils'

/**
 * Removes the children of given question, and its grandchildren etc. (Recursive function)
 * @param id id of parent question
 * @returns boolean, true if removed else false.
 */
export async function removeChild(id: string):Promise<boolean>{
    try {
        const children: question.questionCreatedExtract[] = await question.getChildQuestionsCreatedById(id);
        if(children === undefined || children.length === 0) return false;  
        for (let index = 0; index < children.length; index++) {
            const child = children[index];
            const childId:string = child.id;
            removeChild(child.id)
            question.deleteQuestionCreated(childId)
        }
    return true;
    }
    catch (error) {
        console.error(`An error occurred while removing question with ID ${id}:`, error);
        return false;
    }
}

/**
 * Removes a question based on id, as well as its children.
 * @param id Question id
 * @returns boolean, true if question and children has been deleted else false.
 */
export async function removeQuestionToCreate(id: string): Promise<boolean> {
    try {
        const deletedchildren: boolean = await removeChild(id);
        question.deleteQuestionCreated(id);
        return true;
    }
    catch (error) {
        console.error(`An error occurred while removing question with ID ${id}:`, error);
        return false;
      }
}

/**
 * Removes a section created and its questions.
 * @param sectionId Section id
 * @returns boolean, true if the section has been removed else false.
 */
export async function removeSectionCreated(sectionId: string):Promise<boolean> {
    try {
        const questions: question.questionCreatedExtract[] = await question.getQuestionCreatedBySectionId(sectionId)
        for(let i = 0; i<questions.length && questions.length>0; i++){
            const deletedQuestion: boolean = await removeQuestionToCreate(questions[i].id);
        }
        const id: string = await section.deleteSectionCreated(sectionId)
        return true;
    }
    catch (error) {
        console.error(`An error occurred while removing section with ID ${sectionId}:`, error);
        return false;
    }
}



/**
 * Gets all surveys created by owner where all instances have been completed.
 * @param id Owner ID
 * @returns surveyExtract[] of all surveys created by owner where all instances have been completed.
 */
export async function getSurveysByOwnerCompleted(id: string): Promise<survey.surveyCreatedExtract[]> {
    const surveys = await survey.getSurveysByOwner(id);
    const newSurveys: survey.surveyCreatedExtract[] = [];
    if(surveys)
  {  await Promise.all(surveys.map(async (survey: survey.surveyCreatedExtract) => {
      const instances = await getSurveyInstances(survey.id);
      const notEmpty = instances.length !== 0;
      const comp = instances.every((instance: any) => instance.completed);
      const forced = instances.every((instance: any) => instance.forced);
      if (notEmpty && (comp || forced)) {
        newSurveys.push(survey);
      }
    }));}
    return newSurveys;
}
  

/**
 * Gets all surveys created by owner where at least one instance has not been completed.
 * @param id Owner ID
 * @returns surveyExtract[] of all surveys created by owner where at least one instance has not been completed.
 */
 export async function getSurveysByOwnerNotCompleted(id: string):Promise<survey.surveyCreatedExtract[]> {
    const surveys = await survey.getSurveysByOwner(id);
    const newSurveys: survey.surveyCreatedExtract[] = [];
    if (surveys)
 {   await Promise.all(surveys.map(async (survey: survey.surveyCreatedExtract) => {
      const instances = await getSurveyInstances(survey.id);
      const empty = instances.length !== 0;
      const teamEmpty = survey.team.length === 0;
      const notCom = instances.some((instance: any) => !instance.completed);  
      const notForced = instances.some((instance: any) => !instance.forced);      
      if (empty && notCom && teamEmpty && notForced) {
        newSurveys.push(survey);
      }
    }));}
    return newSurveys;
}

/**
 * Gets all surveys created by owner which have not been sent to any patients.
 * @param id Owner ID
 * @returns surveyExtract[] of all surveys created by owner which have not been sent to any patients.
*/
export async function getSurveysByOwnerNotSent(id: string):Promise<survey.surveyCreatedExtract[]> {
    const surveys = await survey.getSurveysByOwner(id);
    const newSurveys: survey.surveyCreatedExtract[] = surveys.filter(async (survey : survey.surveyCreatedExtract)=>{
        const instances = await getSurveyInstances(survey.id);
        return(
            instances.length === 0
        )
    });
    return newSurveys
}

export async function getQuestionsFromCreated(sectionCreatedId: string) : Promise <types.questionSurveyCreated[]> {
    const getQuestions = await question.getQuestionCreatedBySectionId(sectionCreatedId);
    let qarray: types.questionSurveyCreated[] = [];

    for (let i = 0; i < (await getQuestions).length && (await getQuestions).length> 0; i++) {
        qarray[i] = {
            id: (await getQuestions)[i].id,
            title: (await getQuestions)[i].title,
            titleEmoji: (await getQuestions)[i].titleEmoji,
            description: (await getQuestions)[i].description,
            type: (await getQuestions)[i].type,
            mandatory: (await getQuestions)[i].mandatory,
            qoptions: (await getQuestions)[i].qoptions,
            emojiOpt: (await getQuestions)[i].emojiOpt,
            bounds: (await getQuestions)[i].bounds,
            condition: (await getQuestions)[i].condition,
            multipleChoice: (await getQuestions)[i].multipleChoice,
            sectionId: (await getQuestions)[i].sectionId,
            parentQuestionId: (await getQuestions)[i].parentQuestionId
        }
    };
    return qarray;
}

export async function getSectionsFromCreated(surveyCreatedId: string):Promise<types.sectionSurveyCreated[]>{
    const getSection: section.sectionCreatedExtract[] = await section.getSectionCreatedBySurveyId(surveyCreatedId);
    let array: types.sectionSurveyCreated[] = [];
    
    for (let i = 0; i < getSection.length; i++) {
        const questions: types.questionSurveyCreated[] = await getQuestionsFromCreated(getSection[i].id);
        array[i] = {
            id: getSection[i].id,
            title: getSection[i].title,
            description:getSection[i].description,
            questionOrder: getSection[i].questionOrder,
            questions: questions
        }
    };
    return array; 
}


export async function getSurveyFromCreated(surveyCreatedId: string):Promise<types.returnedSurveyCreated>{
    const surveyGet: survey.surveyCreatedExtract = await survey.getSurveyCreatedById(surveyCreatedId);
    const getSections: types.sectionSurveyCreated[] = await getSectionsFromCreated(surveyCreatedId)
    const returnSurvey: types.returnedSurveyCreated = {
        id: surveyGet.id,
        title: surveyGet.title,
        titleEmoji: surveyGet.titleEmoji,
        description: surveyGet.description,
        sectionOrder: surveyGet.sectionOrder,
        sections: getSections
    }
    return returnSurvey;
}


/**
 * Copies all questions to a sectionsCreated from a sectionTemp
 * @param createdSectionId Id of the section to copy to
 * @param tempSectionId Id of the section to copy from
 * @returns Tuple of the ids of the created and sent questions
 */
export async function createCreatedQuestionbyTemp(tempSectionId: string, createdSectionId: string): Promise<[string, string][]> {
    //Tuple to keep track of which ids match eachother
    //This is to be able to change the questionOrder in the section.
    //created, temp
    const idTuple: [string, string][] = [];

    //All template questions in this template section.
    const templateQuestions: questionTemp.questionTempExtract[] = await questionTemp.getQuestionTempBySectionId(tempSectionId);

    //Place all the id's of template questions in a list
    const tempIds: string[] = []
    for(let i = 0; i < templateQuestions.length; i++){
        tempIds.push(templateQuestions[i].id);
    }

    //for loop for creating root questions! Aka questions without followupquestions
    for(let i = 0; i < templateQuestions.length; i++) {
        if(templateQuestions[i].parentQuestionId == undefined){
            const questionToCreate: question.questionCreateDB = {
                title: templateQuestions[i].title,
                titleEmoji: templateQuestions[i].titleEmoji,
                type: templateQuestions[i].type,
                mandatory: templateQuestions[i].mandatory,
                qoptions: templateQuestions[i].qoptions,
                emojiOpt: templateQuestions[i].emojiOpt,
                bounds: templateQuestions[i].bounds,
                condition: templateQuestions[i].condition,
                description: templateQuestions[i].description,
                multipleChoice: templateQuestions[i].multipleChoice,
                sectionId: createdSectionId
            };
            //When pushed sent is the id of the new question
            const createId:string = await question.createQuestionCreated(questionToCreate); 
            //pushes the sentQuestion id and the created question id.
            idTuple.push([createId, templateQuestions[i].id]); 
            //Removes the created id from the list, so that all that is remaining after done for loop is the followup ids
            const index = tempIds.indexOf(templateQuestions[i].id);
            if(index > -1){
                tempIds.splice(index, 1)
            }; 
        }
        //for loop creating follow up questions!
        //First if is if there are followup questions.      
    }
    if(tempIds.length > 0 ){
        let questionsleft: boolean = true;
        while(questionsleft){
        for(let i = 0; i < templateQuestions.length; i++){
            if(tempIds.includes(templateQuestions[i].id)){
                //find questionToCreate matching id
                const searchId = templateQuestions[i].parentQuestionId;
                const matchingTuple = idTuple.find(([id1, id2]) => id2 === searchId);
                if(matchingTuple){
                    const questionParentId = matchingTuple[0];

                    const followUpCreated: question.questionCreateDB = {
                        title: templateQuestions[i].title,
                        titleEmoji: templateQuestions[i].titleEmoji,
                        type: templateQuestions[i].type,
                        mandatory: templateQuestions[i].mandatory,
                        qoptions: templateQuestions[i].qoptions,
                        emojiOpt: templateQuestions[i].emojiOpt,
                        bounds: templateQuestions[i].bounds,
                        condition: templateQuestions[i].condition,
                        description: templateQuestions[i].description,
                        multipleChoice: templateQuestions[i].multipleChoice,
                        sectionId: createdSectionId,
                        parentQuestionId: questionParentId
                    };
                    //This is currently unused but create returns string, use if want or remove
                    const createdFollowUp: string = await question.createQuestionCreated(followUpCreated);
                    idTuple.push([createdFollowUp, templateQuestions[i].id]); 
                    const index = tempIds.indexOf(templateQuestions[i].id);
                    if(index>-1){
                        tempIds.splice(index, 1)
                    }; 
                }
            }
        }
        if(tempIds.length == 0){
            questionsleft = false;
        }
        }
    } 
    return idTuple;
}

 
//Uppdatera question order listan med hjälp av den tillbaka givna tuplen från questionToCreate
//gör tuplar att returnera till pappa funktionen
/**
 * Copies all sections from a template survey to a created survey.
 * @param createdSurveyId Id of the created survey to copy from.
 * @param sentSurveyId Id of the temp survey to copy to.
 * @returns A list of tuples with the temp and created section ids.
 */
export async function copyTempToCreatedSection(surveyCreatedId: string, surveyTempId: string): Promise<[string, string][]>{
    //Gets all 
    const sectionsTemp: sectionTemp.sectionTempExtract[] = await sectionTemp.getSectionTempBySurveyId(surveyTempId);
    //created, temp
    const sectionCreateTuple: [string, string][] = [];
    for(let i = 0; i < sectionsTemp.length; i++) {
        const createCreateSection: section.sectionCreatedCreate = {
            title: sectionsTemp[i].title,
            titleEmoji: sectionsTemp[i].titleEmoji,
            description: sectionsTemp[i].description,
            questionOrder: sectionsTemp[i].questionOrder,
            surveyId: surveyCreatedId
        }
        const sectionId: string = await section.createSectionCreated(createCreateSection);
        sectionCreateTuple.push([sectionId, sectionsTemp[i].id]); //pusha båda ids här!! sent, created
        const questionsTemp: [string, string][] = await createCreatedQuestionbyTemp(sectionsTemp[i].id, sectionId);
        const newQuestionOrder: string[] = [];
        for(let j = 0; j < sectionsTemp[i].questionOrder.length; j++){
            const searchId = sectionsTemp[i].questionOrder[j];
            const matchingTuple = questionsTemp.find(([id1, id2]) => id2 === searchId);
            if(matchingTuple){
                newQuestionOrder.push(matchingTuple[0]);
            };
        };
        const updateCreatedSection: section.sectionCreatedUpdate = {
            id: sectionId,
            questionOrder: newQuestionOrder
        };
         await section.updateSectionCreated(updateCreatedSection);
    };
    return sectionCreateTuple;
}
    

/**
 * Copies a survey temp and creates a survey created with the same information.
 * @param surveyTempId  of the survey created to copy
 * @returns createdId of the created survey temp
 */
export async function copyTempToCreatedSurvey(surveyTempId: string): Promise<string> {
    const oldSurveyTemp: surveyTemp.surveyTempExtract = await surveyTemp.getSurveyTempById(surveyTempId);
    const newSurveyCreate: survey.surveyCreatedCreate = {
        title: oldSurveyTemp.title,
        titleEmoji: oldSurveyTemp.titleEmoji,
        description: oldSurveyTemp.description,
        department: oldSurveyTemp.department,
        ownerId: oldSurveyTemp.creator,
        process: oldSurveyTemp.process,
        sectionOrder: oldSurveyTemp.sectionOrder,
        keywords: oldSurveyTemp.keywords,
    };
    const surveyCreateId = await survey.createSurveyCreated(newSurveyCreate);
    const sectionSentCreated: [string, string][] = await copyTempToCreatedSection(surveyCreateId, surveyTempId);
    const newSectionOrder: string[] = [];
    for(let j = 0; j < oldSurveyTemp.sectionOrder.length; j++){
        const searchId = oldSurveyTemp.sectionOrder[j];
        const matchingTuple = sectionSentCreated.find(([id1, id2]) => id2 === searchId);
        if(matchingTuple){
            newSectionOrder.push(matchingTuple[0]);
        }
    }

    const keywords_temp: surveyKeyword = await TempUtils.getKeywordsForSurveyTemp(surveyTempId);
    const keywordIds: string[] = [];
    for(let j = 0; j < keywords_temp.keywords.length; j++){
        keywordIds.push(keywords_temp.keywords[j].id);
    }
    const updateCreatedSurvey: survey.surveyCreatedUpdate = {
        id: surveyCreateId,
        sectionOrder: newSectionOrder,
        keywords: keywordIds
    }
    await survey.updateSurveyCreated(updateCreatedSurvey);
    return surveyCreateId;
}

