import { Navigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

type Props = {
  Component: React.FC;
};

const Protected: React.FC<Props> = ({ Component }) => {
  const { isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return <div>loading</div>;
  }
  return isAuthenticated ? <Component /> : <Navigate to='/login' />;
};

export default Protected;
