import { response } from 'express';
import createClient from '../axios'
import{ keyword }from '../../assets/types';

//Fält för att skicka en survey
export type surveySentCreate = {
    title: string;
    titleEmoji?: string;
    description: string;
    department: string;
    team: string[];
    keywords?: string[];
    process?: string;
    anonymous: boolean;
    patientId?: string;
    completed: boolean;
    sectionOrder: string[];
    parentSurveyId: string;
    middle?: boolean;
    forced?: boolean;
};

//Fält för att uppdatera en survey. allt friviligt förutom id.
export type surveySentUpdate = {
    id: string;
    title?: string;
    titleEmoji?: string;
    description?: string;
    department?: string;
    ownerId?: string;
    keywords?: string[];
    process?: string;
    anonymous?: boolean;
    patientId?: string;
    completed?: boolean;
    sectionOrder?: string[];
    middle?: boolean;
    forced?: boolean;
    completedDate?: Date;
};

//return all information from tabel
//Hur får man ut relationer?
export type surveySentExtract = {
    id: string;
    title: string;
    titleEmoji?: string;
    description: string;
    creationDate: Date; //Dubbelkolla så detta line funkar o inte ger error
    department: string;
    team: string[];
    process?: string;
    anonymous: boolean;
    patientId?: string;
    sentDate: Date;
    completedDate?: Date;
    completed: boolean;
    sectionOrder: string[];
    parentSurveyId: string;
    middle?: boolean;
    forced?: boolean;
};

/**
 * Create a survey sent
 * @param data containing all information needed to create a sent survey
 * @returns -string, id of the created survey
 */
export async function createSurveySent(data: surveySentCreate):Promise<string> {
    const client = await createClient();
    return client.post('/surveysent', data) //data is a json object
        .then((response) => {
            return response.data.id
        })
        .catch((error) => console.log(error));
};

/**
 * Gets a sent survey by ID
 * @param id containing all information needed to create a sent survey
 * @returns - surveySentExtract, the survey.
 */
export async function getSurveySentById(id: string):Promise<surveySentExtract> {
    const client = await createClient();
    return client.get('/surveysent/' + id) //id is the survey id
        .then((response) => {
            return response.data
        })
        .catch((error) => console.log(error));
};

/**
 * Update survey sent.
 * @param data containing all information to update the sent survey
 * @returns -string, id of the updated survey.
 */
export async function updateSurveySent(data: surveySentUpdate):Promise<string> {
    const client = await createClient();
    if (data.keywords) {
        const keywordIds: { id: string }[] = [];
        for (let i = 0; i < data.keywords.length; i++) {
            keywordIds.push({ id: data.keywords[i] });
        };
        const dataUpd = {
            id: data.id,
            title: data.title,
            titleEmoji: data.title,
            description: data.description,
            department: data.department,
            ownerId: data.ownerId,
            keywords: {
                connect: keywordIds,
            },
            process: data.process,
            anonymous: data.anonymous,
            patientId: data.patientId,
            completed: data.completed,
            sectionOrder: data.sectionOrder,
            forced: data.forced,
        }
        

        return client.put('/surveysent/' + dataUpd.id, dataUpd)
        .then((response) => {
            return response.data.id;
        })
        .catch((error) => console.log(error));
    }
    return client.put('/surveysent/' + data.id, data) //data is a json object
        .then((response) => {
            return response.data.id
        })
        .catch((error) => console.log(error));
};

/**
 * Delete sent survey
 * @param id the id of the sent survey
 * @returns -string, id of the deleted survey
 */
export async function deleteSurveySent(id: string):Promise<string> {
    const client = await createClient();
    return client.delete('/surveysent/' + id) //data is a json object
        .then((response) => {
            return response.data.id
        })
        .catch((error) => console.log(error));
};

/**
 * Gets all instances of a sent survey through the created id.
 * @param parentSurveyId id to the created survey
 * @returns - stri
 */
export async function getSurveyInstances(parentsurveyId: string): Promise<surveySentExtract[]> {
    const client = await createClient();
    return client.get('/surveysent/parentsurvey/' + parentsurveyId, {params: {
        id: parentsurveyId
    }})
      .then((response) => {
        return response.data
      })
      .catch((error) => console.log(error))
};

/**
 * Gets all surveys by patient ID.
 * @param patientId Patient ID
 * @returns surveyExtract[] of all surveys by patient ID.
*/
export async function getSurveysByPatientId(patientId: string): Promise<surveySentExtract[]> {
    const client = await createClient();
    return client.get('/surveysent/patientid/' + patientId)
    .then((response) => {
        return response.data
      })
      .catch((error) => console.log(error))
};
  
/**
 * Get all surveys sent by staff id.
 * @param staffId 
 * @returns -surveySentExtract[], an array containing all surveys.
 */
export async function getSurveySentByStaff(staffId: string): Promise<surveySentExtract[]> {
    const client = await createClient();
    return client.get('/surveysent/staff/' + staffId, {params: {
        id: staffId
    }})
    .then((response)=> {
        return response.data
    })
    .catch((error)=> console.log(error))
};

/**
 * Gets the keywords for a survey created
 * @param id The id of the survey
 * @returns Keyword[], an array of keywords with value and id
 */
export async function getKeywordsBySurveySent(id: string): Promise<keyword[]>{
    const client = await createClient();
    return client.get('/surveysent/keywords/' +  id)
    .then((response)=> {
        return response.data.keywords;
    })
    .catch((error)=>console.log(error));
};
