import React, { useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import '../SectionCreate/SectionCreate.css';
import './QuestionCreate.css';
import { Container } from 'react-bootstrap';
import {
  plusSign,
  addNewEmojiIcon,
  addNewEmojiIconLarge,
} from '../../assets/Icons';
import { Emoji } from 'emoji-picker-react';
import EmojiPicker from 'emoji-picker-react';
import DoneButton from '../Buttons/DoneButton';
type AnswerType = { answer: string; emoji: string };

interface Props {
  toggleChangedAnswer: (answer: AnswerType) => void;
  answerDataType: string;
}

const QuestionCreateAddNewAnswer = ({
  toggleChangedAnswer,
  answerDataType,
}: Props) => {
  const [answerText, setAnswerText] = useState('');
  const [emojiAnswer, setEmojiAnswer] = useState('');
  const [pickEmojiNewAnswer, setPickEmojiNewAnswer] = useState(false);
  const [missingAnswer, setMissingAnswer] = useState(false);
  const [missingIcon, setMissingIcon] = useState(false);

  const enterHandler = (event: any) => {
    if (event.key === 'Enter') {
      addNewAnswer();
    }
  };
  const addNewAnswer = () => {
    if (answerDataType === 'icon' && emojiAnswer.length === 0) {
      setMissingIcon(true);
      if (answerText.length === 0) {
        setMissingAnswer(true);
      }
      return;
    }
    if (answerText.length === 0) {
      setMissingAnswer(true);
      return;
    }
    toggleChangedAnswer({ answer: answerText, emoji: emojiAnswer });
    setAnswerText('');
    setEmojiAnswer('');
    setMissingAnswer(false);
    setMissingIcon(false);
  };

  return (
    <>
      <div className='rowContainer'>
        <div className='addNewAnswerContainer'>
          <button
            className='add-answer-btn'
            onClick={() => {
              addNewAnswer();
            }}
          >
            {plusSign}{' '}
          </button>

          <button
            className={
              answerDataType !== 'icon'
                ? 'btn btn-light'
                : 'btn btn-light iconButton'
            }
            onClick={() => {
              setPickEmojiNewAnswer(!pickEmojiNewAnswer);
            }}
          >
            {emojiAnswer.length != 0 ? (
              <Emoji
                unified={emojiAnswer}
                size={answerDataType !== 'icon' ? 25 : 40}
              />
            ) : (
              <>
                {answerDataType !== 'icon'
                  ? addNewEmojiIcon
                  : addNewEmojiIconLarge}
              </>
            )}
          </button>
          <input
            className='addNewAnswerInput'
            value={answerText}
            type='text'
            id='message'
            name='message'
            onChange={(e) => setAnswerText(e.target.value)}
            placeholder={
              answerDataType !== 'icon' ? 'Add new answer' : 'Add alt text'
            }
            onKeyUp={enterHandler}
            width='30%'
          />
        </div>
        {/* <div className='add-answer-btn'>
        <DoneButton
          text='Add'
          onClick={() => {
            addNewAnswer();
          }}
        />
</div> */}
      </div>
      <div className='missingAnswerText'>
        {missingAnswer && (
          <>
            {answerDataType !== 'icon'
              ? 'missing answer text'
              : 'missing alt text '}
          </>
        )}
        {missingIcon && 'missing icon'}
      </div>
      <div className={pickEmojiNewAnswer ? 'd-block' : 'd-none'}>
        <EmojiPicker
          onEmojiClick={(emoji) => {
            setPickEmojiNewAnswer(false);
            setEmojiAnswer(emoji.unified);
          }}
        />{' '}
      </div>
    </>
  );
};

export default QuestionCreateAddNewAnswer;
