import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import QuestionCreate from '../QuestionCreate/QuestionCreate';
import { useState, useEffect, useRef } from 'react';
import './SectionCreate.css';
import {
  trashIcon,
  collapseIcon,
  expandIcon,
  collapseIconDisabled,
  expandIconDisabled,
} from '../../assets/Icons';
import {
  questionType,
  questionFromQuestionBank,
  questionFromQBType,
  questionTypeRetrive,
  Answer,
  childEditing,
} from '../../assets/types';
import CustomButton from '../Buttons/CustomButton';
import {
  createQuestionTempAnswer,
  questionTempCreateAnswer,
  updateQuestionTempAnswer,
  questionTempUpdateAnswer,
  removeQuestionTemp,
} from '../../functions/Template_functions/tempUtils';
import { all } from 'axios';

interface Props {
  questionOrderStart: string[];
  sectionId: string;
  questionOrder: string[];
  questionsFromTemplate?: questionTypeRetrive[];
  questionsFromBank?: questionFromQuestionBank;
  setChildIsEditing: (id: string, isEditing: boolean) => void;
  childrenEditing: childEditing[];
  setAddNewQuestionFromBank: (addNew: boolean, id: string) => void;
  setQuestionOrder: (order: string[]) => void;
  handleFetchQuestionBank: () => void;
  setNoServerResponse: (noResponse: boolean) => void;
}

const ListQuestionsInSection = ({
  questionOrderStart,
  sectionId,
  questionOrder,
  questionsFromTemplate,
  questionsFromBank,
  childrenEditing,
  setChildIsEditing,
  handleFetchQuestionBank,
  setAddNewQuestionFromBank,
  setQuestionOrder,
  setNoServerResponse,
}: Props) => {
  const [addingNewQuestion, setAddingNewQuestion] = useState(false);
  const [questions, setQuestions] = useState<questionType[]>([]);

  const [questionsListSorted, setQuestionsListSorted] = useState<
    questionType[]
  >([]);

  const swapQuestionsUp = (index: number) => {
    const newList: string[] = [...questionOrder];
    [newList[index - 1], newList[index]] = [newList[index], newList[index - 1]];
    return newList;
  };
  const swapQuestionsDown = (index: number) => {
    const newList: string[] = [...questionOrder];
    [newList[index + 1], newList[index]] = [newList[index], newList[index + 1]];
    return newList;
  };

  const moveUpId = (id: string) => {
    const index = questionOrder.indexOf(id);
    if (index === -1 || index === 0) {
      return; // cannot move down any further
    }
    const newList: string[] = swapQuestionsUp(index);
    setQuestionOrder(newList);
  };

  const moveDownId = (id: string) => {
    const index = questionOrder.indexOf(id);
    if (index === -1 || index === questionsListSorted.length - 1) {
      return; // cannot move down any further
    }
    const newList: string[] = swapQuestionsDown(index);
    setQuestionOrder(newList);
  };

  const removeQuestion = (element: questionType) => {
    let index: number = -1;
    questions.forEach((question, i) => {
      return question.id === element.id ? (index = i) : null;
    });
    if (index === -1) {
      return; // cannot remove
    }
    const newList: questionType[] = [...questions];
    newList.splice(index, 1);
    if (!element.parentQuestionId) {
      const id = element.id as string;
      const index_order = questionOrder.indexOf(id);
      const newOrder: string[] = [...questionOrder];
      newOrder.splice(index_order, 1);
      setQuestionOrder(newOrder);
    }
    setQuestions(newList);
    removeQuestionTemp(element.id).then((deleted) => {
      if (deleted === undefined) {
        setNoServerResponse(true);
        return;
      }
      if (!deleted) {
        console.log('error deleting question');
      }
    });
  };

  function reWriteAnswers(qoptions?: string[], emojiOpt?: string[]) {
    if (qoptions?.length !== 0 || qoptions !== undefined) {
      const answers: Answer[] = qoptions
        ? qoptions.map((qoption: any, index: any) => ({
            answer: qoption as string,
            emoji: (emojiOpt ? emojiOpt[index] : undefined) as string,
          }))
        : [];
      return answers;
    }
  }

  const addNewQuestion = (parentQuestionId?: string) => {
    const newQuestion: questionType = {
      id: '',
      sectionId: sectionId,
      title: '',
      titleEmoji: '',
      description: '',
      answers: [],
      parentQuestionId: parentQuestionId,
      type: 'multiple choice',
      mandatory: false,
      bounds: [],
      condition: [],
      multipleChoice: false,
    };
    const { id: _, ...rest } = newQuestion;
    const questionCreate: questionTempCreateAnswer = rest;
    createQuestionTempAnswer(questionCreate).then((id) => {
      if (id === undefined) {
        setNoServerResponse(true);
        return;
      }
      setChildIsEditing(id, true);
      newQuestion.id = id;
      const newList: questionType[] = [...questions, newQuestion];
      setQuestions(newList);
      setAddingNewQuestion(true);
      if (!parentQuestionId) {
        const newOrder = [...questionOrder, id];
        setQuestionOrder(newOrder);
      }
    });
  };

  const addQuestionsFromQuestionBank = (
    questionsFromBank: questionFromQBType[]
  ) => {
    if (!questions) {
      return;
    }
    const promises = questionsFromBank.map((question) => {
      const newQuestion: questionType = {
        id: '',
        sectionId: sectionId,
        title: question.title,
        titleEmoji: question?.titleEmoji,
        bounds: question.bounds,
        description: question.description,
        condition: question.condition,
        answers: question.answers,
        type: question.type,
        mandatory: question.mandatory,
        multipleChoice: question.multipleChoice,
      };
      const { id: _, ...rest } = newQuestion;
      const questionCreate: questionTempCreateAnswer = rest;
      return createQuestionTempAnswer(
        questionCreate as questionTempCreateAnswer
      ).then((id) => {
        if (id === undefined) {
          setNoServerResponse(true);
        }
        newQuestion.id = id;
        return newQuestion;
      });
    });

    Promise.all(promises).then((newQuestions) => {
      setQuestions([...questions, ...newQuestions]);
      const newQuestionOrder = newQuestions.map((q) => {
        return q.id;
      });

      setQuestionOrder([...questionOrder, ...newQuestionOrder]);
    });

    setAddNewQuestionFromBank(false, '-1');
  };

  const addQuestionsFromQuestionTemplate = (
    questionsFromBank: questionTypeRetrive[]
  ) => {
    if (!questions) {
      return;
    }
    const allParis: { oldId: string; newId: string }[] = [];
    const parentAndChildQuestions: { parentId: string; childId: string }[] = [];

    const promises = questionsFromBank.map((question) => {
      allParis.push({ oldId: question.id, newId: '' });
      if (question.parentQuestionId) {
        parentAndChildQuestions.push({
          parentId: question.parentQuestionId,
          childId: question.id,
        });
      }
      const answers: Answer[] = reWriteAnswers(
        question.qoptions,
        question.emojiOpt
      ) as Answer[];
      const newQuestion: questionType = {
        id: '',
        sectionId: sectionId,
        title: question.title,
        titleEmoji: question?.titleEmoji,
        bounds: question.bounds,
        description: question.description,
        condition: question.condition,
        answers: answers,
        type: question.type,
        mandatory: question.mandatory,
        multipleChoice: question.multipleChoice,
      };
      const { id: _, ...rest } = newQuestion;
      const questionCreate: questionTempCreateAnswer = rest;
      return createQuestionTempAnswer(
        questionCreate as questionTempCreateAnswer
      ).then((id) => {
        if (id === undefined) {
          setNoServerResponse(true);
        }
        newQuestion.id = id;
        const index = allParis.findIndex((pair) => pair.oldId === question.id);
        allParis[index] = { oldId: question.id, newId: id };
        return newQuestion;
      });
    });

    Promise.all(promises).then((newQuestions) => {
      const newQuestionOrder: string[] = questionOrderStart.map(
        (id) => allParis.find((pair) => pair.oldId === id)?.newId as string
      );
      parentAndChildQuestions.forEach((pair) => {
        const newChild = allParis.find((p) => p.oldId === pair.childId)?.newId;
        const newParent = allParis.find(
          (p) => p.oldId === pair.parentId
        )?.newId;
        const question = newQuestions.find((q) => q.id === newChild);
        if (question !== undefined) {
          const updateQuestionTemp: questionTempUpdateAnswer = {
            id: question.id,
            title: question.title,
            titleEmoji: question.titleEmoji,
            description: question.description,
            answers: question.answers,
            type: question.type,
            mandatory: question.mandatory,
            bounds: question.bounds,
            condition: question.condition,
            parentQuestionId: newParent,
            multipleChoice: question.multipleChoice,
          };
          updateQuestionTempAnswer(updateQuestionTemp).then((updated) => {
            if (updated !== question.id) {
              console.log('something went terribly wrong when updating :(');
            }
          });
          newQuestions = newQuestions.filter((q) => q.id !== question.id);
          question.parentQuestionId = newParent;
          newQuestions.push(question);
        }
      });
      setQuestions([...questions, ...newQuestions]);
      setQuestionOrder(newQuestionOrder);
    });

    setAddNewQuestionFromBank(false, '-1');
  };

  const handleAddQuestionsFromQuestionBank = () => {
    if (questionsFromBank) {
      setAddingNewQuestion(false);
      addQuestionsFromQuestionBank(questionsFromBank.questions);
    }
  };

  const handleUpdateQuestion = (question: questionType) => {
    const updateQuestionTemp: questionTempUpdateAnswer = {
      id: question.id,
      title: question.title,
      titleEmoji: question.titleEmoji,
      description: question.description,
      answers: question.answers,
      type: question.type,
      mandatory: question.mandatory,
      bounds: question.bounds,
      condition: question.condition,
      multipleChoice: question.multipleChoice,
    };
    updateQuestionTempAnswer(updateQuestionTemp).then((updated) => {
      if (updated === undefined) {
        setNoServerResponse(true);
        return;
      }
      if (updated !== question.id) {
        console.log('something went terribly wrong');
      }
    });
    const newList: questionType[] = [...questions];
    const index = newList.findIndex((element) => element.id === question.id);
    newList[index] = question;
    setQuestions(newList);
  };

  const getFollowUpQuestions = (questionId: string) => {
    return questions.filter((q) => q?.parentQuestionId === questionId);
  };

  const getFollowUpQuestionsConditions = (questionId: string) => {
    const followUpQuestions = questions.filter(
      (q) => q?.parentQuestionId === questionId
    );
    return followUpQuestions.map((q) => q.condition).flat();
  };

  useEffect(() => {
    handleAddQuestionsFromQuestionBank();
  }, [questionsFromBank]);

  useEffect(() => {
    setQuestionsListSorted(
      questions.length
        ? questionOrder.map(
            (id) => questions.find((obj) => obj.id === id) as questionType
          )
        : []
    );
  }, [questionOrder]);

  const hasEffectRun = useRef(false);

  useEffect(() => {
    if (!hasEffectRun.current && questionsFromTemplate) {
      addQuestionsFromQuestionTemplate(questionsFromTemplate);
      hasEffectRun.current = true;
    }
  }, []);

  useEffect(() => {}, [questionsListSorted, questions]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {questionsListSorted.some((q) => q !== undefined) &&
        questionsListSorted.map((question, index) => {
          return (
            <Container
              key={question.id}
              style={{ paddingTop: '10px', paddingBottom: '10px' }}
            >
              <Container>
                <div className='question-create-in-section'>
                  <Col sm={11} md={11}>
                    <QuestionCreate
                      followUpConditionsFromTemp={getFollowUpQuestionsConditions(
                        question.id as string
                      )}
                      childrenEditing={childrenEditing}
                      setChildIsEditing={setChildIsEditing}
                      setMissingConditions={() => {}}
                      followUpConditionEditing={false}
                      toggleFollowUpEditingProp={() => {}}
                      conditionsFromParent={[]}
                      handleFetchQuestionBank={handleFetchQuestionBank}
                      removeQuestion={removeQuestion}
                      questionsList={questions}
                      followUp={getFollowUpQuestions(question.id as string)}
                      question={question}
                      updateQuestion={handleUpdateQuestion}
                      newQuestion={addingNewQuestion}
                      addNewQuestion={addNewQuestion}
                      childCount={0}
                    />
                  </Col>
                  <Col
                    sm={1}
                    md={1}
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: '10px',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <button
                        className='invisibleButton'
                        onClick={() => {
                          moveUpId(question.id as string);
                        }}
                      >
                        {questionOrder.indexOf(question.id as string) !== 0
                          ? collapseIcon
                          : collapseIconDisabled}
                      </button>{' '}
                      <button
                        className='invisibleButton'
                        onClick={() => {
                          moveDownId(question.id as string);
                        }}
                      >
                        {questionOrder.indexOf(question.id as string) !==
                        questionOrder.length - 1
                          ? expandIcon
                          : expandIconDisabled}
                      </button>
                    </div>
                    <div>
                      {
                        <button
                          onClick={() => {
                            removeQuestion(question);
                          }}
                          className='invisibleButton'
                        >
                          {trashIcon}
                        </button>
                      }
                    </div>
                  </Col>
                </div>
              </Container>
            </Container>
          );
        })}
      <div className='add-new-question-in-list-section-buttons-div'>
        <CustomButton
          text='Add new Question'
          onClick={() => {
            addNewQuestion();
          }}
        />
        <CustomButton
          text='Add new Question from Question Bank'
          onClick={() => {
            setAddNewQuestionFromBank(true, sectionId);
          }}
        />
      </div>
    </div>
  );
};

export default ListQuestionsInSection;
