import createClient from '../axios';
import { keyword } from '../../assets/types'
//Variable "data" is a JSON object - The object consists different things based on the function
//Variable "id" is an ID - The ID depends on either the id of the section you want to get or the survey you want to get.

export type surveyTempCreate = {
    title: string;
    titleEmoji?: string;
    description: string;
    department: string;
    creator: string;
    keywords?: string[];
    process: string;
    sectionOrder: string[];
};

//Fält för att uppdatera en survey. allt friviligt förutom id.
export type surveyTempUpdate = {
    id: string;
    title?: string;
    titleEmoji?: string;
    description?: string;
    department?: string;
    creator?: string;
    keywords?: string[];
    process?: string;
    sectionOrder?: string[];
    inBank?: boolean;
};

export type surveyTempExtract = {
    id: string;
    title: string;
    titleEmoji?: string;
    description: string;
    creationDate: Date;
    department: string;
    creator: string;
    keywords?: string[];
    process: string;
    sectionOrder: string[];
    inBank: boolean;
};



/**
 * Create a template survey
 * @param data containing all information needed to create a template survey
 * @returns -string, id of the created survey
 */
export async function createSurveyTemp(data: surveyTempCreate):Promise<string> {
    const client = await createClient();
    return client.post('/surveytemp', data) //data is a json object
        .then((response) => {
            return response.data.id
        })
        .catch((error) => console.log(error));
};

/**
 * Get a template survey
 * @param data containing all information needed to create a template question
 * @returns -string, id of the created question
 */
export async function getSurveyTempById(id: string):Promise<surveyTempExtract> {
    const client = await createClient();
    return client.get('/surveytemp/' + id) //id is the section id
        .then((response) => {
            return response.data
        })
        .catch((error) => console.log(error));
};

/**
 * Update a template survey
 * @param data containing all information needed to update a template question
 * @returns -string, id of the created question
 */
export async function updateSurveyTemp(data: surveyTempUpdate):Promise<string> {
    const client = await createClient();
    if (data.keywords) {
        const keywordIds: { id: string }[] = [];
        for (let i = 0; i < data.keywords.length; i++) {
            keywordIds.push({ id: data.keywords[i] });
        };
        const dataUpd = {
            id: data.id,
            title: data.title,
            titleEmoji: data.title,
            description: data.description,
            department: data.department,
            creator: data.creator,
            keywords: {
                connect: keywordIds,
            },
            process: data.process,
            sectionOrder: data.sectionOrder,
            inBank: data.inBank
        }
        return client.put('/surveytemp/' + dataUpd.id, dataUpd)
        .then((response) => {
            return response.data.id;
        })
        .catch((error) => console.log(error));
    }
    return client.put('/surveytemp/' + data.id, data) //data is a json object
        .then((response) => {
            return response.data.id
        })
        .catch((error) => console.log(error));
};

/**
 * Deleta a template survey
 * @param id the id of the template survey to delete
 * @returns -string, id of the deleted survey
 */
export async function deleteSurveyTemp(id: string):Promise<string> {
    const client = await createClient();
    return client.delete('/surveytemp/' + id) //data is a json object
        .then((response) => {
            return response.data.id
        })
        .catch((error) => console.log(error));
};

/**
 * get all survey templates.
 * @returns - surveyTempExtract[] returns all survey templates
 */
export async function getAllSurveyTemps():Promise<surveyTempExtract[]> {
    const client = await createClient();
    return client.get('/surveytemp')
        .then((response) => {
            return response.data
        })
        .catch((error) => console.log(error));
};

//HEJ JAG GJORDE DENNA MEN DET ÄR EN KOPIA AV DEN ÖVER BEHÖVS DEN
export async function getAllSurveyTempsInBank():Promise<surveyTempExtract[]> {
    const surveys : surveyTempExtract[] = await getAllSurveyTemps()
    const newSurveys: surveyTempExtract[] = [];
if(surveys)
  {  await Promise.all(surveys.map(async (survey: surveyTempExtract) => {
      if (survey.inBank) {
        newSurveys.push(survey);
      }
    }));}
    return newSurveys;
};

/**
 * Gets survey templates by the creator
 * @param id The id of the creator
 * @returns surveyTempExtract[], an array of surveys belonging to a creator.
 */
export async function getSurveyTempByCreator(id: string):Promise<surveyTempExtract[]> {
    const client = await createClient();
    return client.get('/surveytemp/bycreator/' + id) //data is a json object
        .then((response) => {
            return response.data
        })
        .catch((error) => console.log(error));
};

export async function getSurveyTempByKeywords(data: string[]): Promise<surveyTempExtract[]>{
    const client = await createClient();
    return client.get('/surveytemp/bykeyword/', {params: data})
        .then((response)=>{
        return response.data
        })
        .catch((error)=>console.log(error))
};

/**
 * Gets the keywords for a survey created
 * @param id The id of the survey
 * @returns Keyword[], an array of keywords with value and id
 */
export async function getKeywordsBySurveyTemp(id: string): Promise<keyword[]>{
    const client = await createClient();
    return client.get('/surveytemp/keywords/' +  id)
        .then((response)=> {
            return response.data;
        })
        .catch((error)=>console.log(error));
};
