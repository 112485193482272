import { Emoji } from 'emoji-picker-react';
import './AnswerViewQuestion.css';
import { imageIcon } from '../../assets/Icons';
import { Answer } from '../../assets/types';
import { useState, useEffect, useRef } from 'react';

interface Props {
  answers?: Answer[];
  type: string;
  multipleChoice: boolean;
  register: any;
  errors: any;
  questionId: string | undefined;
  required: boolean;
  savedAnswers: string[];
  toggleClear: boolean;
  setClear: (clear: boolean) => void;
  bounds?: number[];
  handleIconAnswerClick: (answer: string) => void;
  selectedIconAnswers: string[];
  setSelectedIconAnswers: (selectedIconAnswers: string[]) => void;
  condition?: string[];
  toggleFollowUp: (add: string | undefined, remove: string | undefined) => void;
  clearFollowUps: boolean;
  setClearFollowUps: (clear: boolean, id: string) => void;
  setBackUpMultipleAnswers: (id: string, answers: string[]) => void;
}

const AnswerViewListAnswers = ({
  handleIconAnswerClick,
  selectedIconAnswers,
  setSelectedIconAnswers,
  answers,
  type,
  multipleChoice,
  register,
  questionId,
  required,
  savedAnswers,
  toggleClear,
  bounds,
  setClear,
  condition,
  toggleFollowUp,
  clearFollowUps,
  setClearFollowUps,
  setBackUpMultipleAnswers,
}: Props) => {
  const [answerText, setAnswerText] = useState<string>('');
  const [checkedAnswers, setCheckedAnswers] = useState(savedAnswers || []);
  const oldCheckedAnswers = useRef(savedAnswers || []);

  const handleAnswerChange = (answer: string) => {
    let followUpAdd: string | undefined = undefined;
    let followUpRemove: string | undefined = undefined;
    if (multipleChoice && condition?.includes(answer)) {
      if (oldCheckedAnswers.current.includes(answer)) {
        followUpRemove = answer;
      } else {
        followUpAdd = answer;
      }
    } else if (!multipleChoice) {
      if (condition?.includes(answer)) {
        followUpAdd = answer;
      }
      if (condition?.includes(oldCheckedAnswers.current[0])) {
        followUpRemove = oldCheckedAnswers.current[0];
      }
    }
    toggleFollowUp(followUpAdd, followUpRemove);
    if (!multipleChoice) {
      // For radio buttons, set the checked answer directly
      setCheckedAnswers([answer]);
      setBackUpMultipleAnswers(questionId as string, [answer])
      oldCheckedAnswers.current = [answer];
    } else {
      // For checkboxes, update the checked answers array
      const updatedCheckedAnswers = checkedAnswers.includes(answer)
        ? checkedAnswers.filter((a) => a !== answer)
        : [...checkedAnswers, answer];
      setBackUpMultipleAnswers(questionId as string, updatedCheckedAnswers)
      setCheckedAnswers(updatedCheckedAnswers);
      oldCheckedAnswers.current = updatedCheckedAnswers;
    }
  };

  useEffect(() => {
    if (toggleClear) {
      setCheckedAnswers([]);
      setSelectedIconAnswers([]);
      setAnswerText('');
      setClear(false);
      oldCheckedAnswers.current = [];
    }
  }, [toggleClear]);

  useEffect(() => {
    if (clearFollowUps) {
      setCheckedAnswers([]);
      setSelectedIconAnswers([]);
      setAnswerText('');
      setClearFollowUps(false, questionId as string);
      oldCheckedAnswers.current = [];
    }
  }, [clearFollowUps]);

  if (type === 'image') {
    return (
      <div className='imageContainer'>
        {imageIcon} <p>Not yet implemented</p>
      </div>
    );
  }

  if (type === 'multiple choice') {
    const inputType = multipleChoice ? 'checkbox' : 'radio';
    return (
      <div className='multiple-choice-container'>
        {answers &&
          answers.map((answer: Answer, index) => (
            <div
              key={answer.answer + 'div container'}
              className='listAnswersDiv'
            >
              <div className='inputButtons'>
                <input
                  {...register(questionId, {
                    required: {
                      value: required,
                      message: 'this question is required',
                    },
                  })}
                  key={answer.answer}
                  type={inputType}
                  value={answer.answer}
                  checked={checkedAnswers.includes(answer.answer)}
                  onChange={() => handleAnswerChange(answer.answer)}
                />
              </div>
              <Emoji unified={answer.emoji} size={25} />
              <div className='answerTextDiv'>{answer.answer}</div>
            </div>
          ))}
      </div>
    );
  }

  if (type === 'icon') {
    return (
      <>
        {answers &&
          answers.map((answer) => {
            const selected = selectedIconAnswers.includes(answer.answer);
            const buttonStyle = {
              backgroundColor: selected
                ? 'rgba(128, 128, 128, 0.2)'
                : 'transparent',
              color: selected ? '#fff' : '#000',
              borderRadius: '8px',
            };

            const handleClick = () => {
              handleIconAnswerClick(answer.answer);
            };

            return (
              <div
                key={answer.answer + 'div container'}
                className='listAnswersDiv'
              >
                <button
                  name={answer.answer}
                  type='button'
                  className='iconButton'
                  style={buttonStyle}
                  onClick={handleClick}
                >
                  <Emoji unified={answer.emoji} size={40} />
                </button>
                <div className='altTextRemoveContainer'></div>
              </div>
            );
          })}
      </>
    );
  }
  if (type === 'text') {
    return (
      <>
        <textarea
          className='answerInputText'
          defaultValue={savedAnswers}
          onChange={(e) => {
            setAnswerText(e.target.value);
          }}
          {...register(questionId, {
            required: { value: required, message: 'this question is required' },
          })}
        />
      </>
    );
  }
  if (type === 'number' && bounds !== undefined && bounds.length > 0) {
    return (
      <>
        <input
          className='answerInput'
          defaultValue={savedAnswers}
          type={type}
          {...register(questionId, {
            required: { value: required, message: 'this question is required' },
            min: {
              value: bounds[0],
              message:
                'Number must be between ' + bounds[0] + ' and ' + bounds[1],
            },
            max: {
              value: bounds[1],
              message:
                'Number must be between ' + bounds[0] + ' and ' + bounds[1],
            },
          })}
        />
      </>
    );
  }
  return (
    <>
      <input
        className='answerInput'
        defaultValue={savedAnswers}
        type={type}
        {...register(questionId, {
          required: { value: required, message: 'this question is required' },
        })}
      />
    </>
  );
};

export default AnswerViewListAnswers;
