import {homeIcon, surveyIconBox, outlineForm, star, bank, surveyIcon, patientSearch} from '../../assets/Icons';

const NavItems = [
  {
    id: '1',
    label: 'Start Page',
    icon: homeIcon,
    href: '/startpage',
  },
  {
    id: '2',
    label: 'My Surveys',
    icon: surveyIconBox,
    href: '/mysurveys',
  },
  {
    id: '3',
    label: 'My Created Templates',
    icon: outlineForm,
    href: '/mycreatedtemplates',
  },
  {
    id: '4',
    label: 'Favorites',
    icon: star,
    href: '/favorites',
  },
  {
    id: '5',
    label: 'Survey Bank',
    icon: bank,
    href: '/surveybank',
  },
   {
    id: '6',
    label: 'Patient Search',
    icon: patientSearch,
    href: '/patientsearch',
  },
  {
    id: '7',
    label: 'Create Survey',
    icon: surveyIcon,
    href: '#',
    subItems: [
      {
        id: '7.1',
        label: 'Create New Survey',
        href: '/createnewsurvey',
      },
      {
        id: '7.2',
        label: 'Create New Survey From Template',
        href: '/createsurveyfromtemplate',
      },
    ],
  },
];

export default NavItems;
