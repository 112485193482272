import { Accordion } from 'react-bootstrap';
import { questionTypeRetrive } from '../../assets/types';
import ViewSurveyQuestion from './ViewSurveyQuestion';

interface Props {
  questions: questionTypeRetrive[];
  parent: { name: string; id: string };
  allQuestions: questionTypeRetrive[];
}

const ViewFollowUpQuestion = ({ questions, allQuestions, parent }: Props) => {
  const getFollowUpQuestions = (questionId: string) => {
    return allQuestions.filter((q) => q?.parentQuestionId === questionId);
  };

  return (
    <Accordion.Item eventKey={parent.id}>
      <Accordion.Header>Follow Up Questions - {parent.name}</Accordion.Header>
      <Accordion.Body>
        {questions.map((question, index) => (
          <div
            key={question.id}
            className='view-survey-follow-up-questions-container'
          >
            {index !== 0 && <hr />}
            <div className='view-follow-up-conditions'>
              if answer to {parent.name} is{' '}
              <b>{question.condition.join(', or ')}</b>
            </div>
            <ViewSurveyQuestion
              allQuestions={allQuestions}
              key={question.id}
              question={question}
              followUp={getFollowUpQuestions(question.id as string)}
            />
          </div>
        ))}
      </Accordion.Body>
    </Accordion.Item>
  );
};

export default ViewFollowUpQuestion;
