import React, { useEffect, useState } from 'react';
import { Card, Col, Container } from 'react-bootstrap';
import { Emoji } from 'emoji-picker-react';
import ListAnswerChoice from './ListAnswerChoices';
import { questionType, SelectOptions } from '../../assets/types';
import Select, { MultiValue } from 'react-select';

import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';
import './QuestionCreate.css';
import processes from '../../assets/process.json';
import DoneButton from '../Buttons/DoneButton';
import {
  createQuestionBank,
  questionQBCreated,
} from '../../functions/Misc/questionbank';
import { getKeywords, keywordExtract } from '../../functions/Misc/keywords';

interface Props {
  question: questionType;
  showModal: boolean;
  handleCloseModal: () => void;
  handleFetchQuestionBank: () => void;
}

const AddQuestionToQuestionBank = ({
  question,
  handleCloseModal,
  showModal,
  handleFetchQuestionBank,
}: Props) => {
  const [keywords, setKeywords] = useState<string[]>([]);
  const [process, setProcess] = useState<string>('');
  const [fecthedKeywords, setFetchedKeywords] = useState<keywordExtract[]>([]);

  const handleSelectProcessChange = (value: any) => {
    if (value !== null) {
      setProcess(value.value);
    } else {
      setProcess('');
    }
  };

  function createOptionsList(strings: string[]): SelectOptions[] {
    if (!strings) return [] as SelectOptions[];
    return strings.map((string) => ({ label: string, value: string }));
  }

  function createOptionsListKeywords(list: keywordExtract[]) {
    const newList = list.map((obj) => {
      const tempVal: string = obj.value as string;
      const tempId: string = obj.id as string;
      return { value: tempId, label: tempVal };
    });
    return newList;
  }

  const handleSelectKeywordsChange = (
    value: MultiValue<{ label: string; value: string }>
  ) => {
    let keywords: string[] = [];
    value.map((option: { label: string; value: string }) =>
      keywords.push(option.value)
    );
    setKeywords(keywords);
  };

  const customStyles = {
    control: (base: any, state: any) => ({
      ...base,
      minWidth: '200px', // Set the width to your desired value
      width: '100%',
    }),
  };

  const handleAddToQB = () => {
    const questionToAdd: questionQBCreated = {
      title: question.title,
      titleEmoji: question.titleEmoji,
      description: question.description,
      type: question.type,
      multipleChoice: question.multipleChoice,
      answers: question.answers,
      mandatory: question.mandatory,
      bounds: question.bounds,
      condition: question.condition,
    };
    createQuestionBank(questionToAdd).then(() => {
      handleFetchQuestionBank();
    });
    handleCloseModal();
  };

  useEffect(() => {
    getKeywords().then((res) => {
      setFetchedKeywords(res);
    });
  }, []);

  return (
    <Modal
      show={showModal}
      onHide={handleCloseModal}
      backdrop={true}
      keyboard={true}
      size='lg'
      style={{ zIndex: '10001' }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Add question to Question Bank </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <Card>
            <Card.Header>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <Col md={10}>
                  <>
                    <Container className='questTitleDiv'>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          marginBottom: '20px',
                        }}
                      >
                        <p>{question.title}</p>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginLeft: '15px',
                          }}
                        >
                          <Emoji
                            unified={question.titleEmoji as string}
                            size={40}
                          />
                        </div>
                      </div>
                    </Container>
                  </>
                </Col>
                <Col md={2}></Col>
              </div>
              <Container className={'questDescriptionDiv'}>
                <p>{question.description}</p>
              </Container>
            </Card.Header>
            <Card.Body>
              <Container fluid>
                <Row>
                  <ListAnswerChoice
                    answers={question.answers}
                    edit={false}
                    handleRemove={() => {}}
                    type={question.type}
                    multipleChoice={question.multipleChoice}
                  />
                </Row>
              </Container>
            </Card.Body>
          </Card>
        </div>
        <div>
          <b>Add keywords and process to the question</b>
          <div className='tagsButtonAddQuestionDiv'>
            <div className='tagsAddQuestionDiv'>
              <Select
                classNamePrefix='react-select'
                placeholder='Keywords'
                defaultValue={createOptionsList(keywords)}
                options={createOptionsListKeywords(fecthedKeywords)}
                isMulti
                isClearable
                isSearchable
                onChange={(value) => {
                  handleSelectKeywordsChange(value);
                }}
              />
              <Select
                placeholder='Process'
                defaultValue={createOptionsList([process])}
                options={createOptionsList(processes.process)}
                isClearable
                isSearchable
                maxMenuHeight={200}
                onChange={(value) => {
                  handleSelectProcessChange(value);
                }}
              />
            </div>
            <div className='buttonAddQuestionDiv'>
              <DoneButton
                text='Add'
                onClick={() => {
                  handleAddToQB();
                }}
              />
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddQuestionToQuestionBank;
