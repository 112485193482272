import createClient from '../axios';
//Variable "data" is a JSON object - The object consists different things based on the function
//Variable "id" is an ID - The ID depends on either the id of the section you want to get or the survey you want to get.

export type sectionTempCreate ={
    title: string;
    titleEmoji?: string;
    description: string;
    surveyId: string;
    questionOrder?: string[];
}

export type sectionTempUpdate = {
    id: string;
    title?: string;
    titleEmoji?: string;
    description?: string;
    questionOrder?: string[];
}

export type sectionTempExtract = {
    id: string;
    title: string;
    titleEmoji?: string;
    description: string;
    questionOrder: string[];
    surveyId: string;
};

// export type sectionTempExtractMult = {
//     id: string;
//     title: string;
//     titleEmoji?: string;
//     description: string;
//     questionOrder: string;
//     surveyId: string;
// }[]

// export

/**
 * Create a template section
 * @param data containing all information needed to create a template section
 * @returns -string, id of the created section
 */
//Data contains: title, titleemoji?, description, surveyId.
//Create a section, does not push any questions.
export async function createSectionTemp(data: sectionTempCreate):Promise<string> {
    const client = await createClient();
    return client.post('/sectiontemp', data) //data is a json object
        .then((response) => {
            return response.data.id
        })
        .catch((error) => console.log(error));
}

/**
 * get a template section.
 * @param id id of the section
 * @returns -sectionTempExtract, the section.
 */
export async function getSectionTempById(id: string):Promise<sectionTempExtract> {
    const client = await createClient();
    return client.get('/sectiontemp/' + id) //id is the section id
        .then((response) => {
            return response.data
        })
        .catch((error) => console.log(error));
}

/**
 * Update a template section
 * @param data containing all information needed to update a template section
 * @returns -string, id of the updated template
 */
export async function updateSectionTemp(data: sectionTempUpdate):Promise<string> {
    const client = await createClient();
    return client.put('/sectiontemp/' + data.id, data) //data is a json object
        .then((response) => {
            return response.data.id
        })
        .catch((error) => console.log(error));
}

/**
 * Delete a template section
 * @param id id of the section
 * @returns -string, id of the deleted section
 */
export async function deleteSectionTemp(id: string):Promise<string> {
    const client = await createClient();
    return client.delete('/sectiontemp/' + id) //data is a json object
        .then((response) => {
            return response.data.id
        })
        .catch((error) => console.log(error));
}

/**
 * Get template sections by a survey id.
 * @param surveyId id of the survey
 * @returns - sectionTempExtract[], an array containing all sections.
 */
export async function getSectionTempBySurveyId(surveyId: string):Promise<sectionTempExtract[]> {
    const client = await createClient();
    return client.get('/sectiontemp/survey/' + surveyId, {params: {
        id: surveyId
    }}) //id is the survey id
        .then((response) => {
            return response.data
        })
        .catch((error) => console.log(error));
}
