import Button from 'react-bootstrap/Button';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { searchIcon } from '../../assets/Icons';
import './FilterAndSearchBar.css';
import { useEffect, useState, useRef } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import Collapse from 'react-bootstrap/Collapse';
import Select, { MultiValue } from 'react-select';
import processes from '../../assets/process.json';
import { Filter } from '../../assets/types';
import { SurveyProps } from '../SurveySection/SurveyRow';
import { crossIcon } from '../../assets/Icons';
import { getKeywords, keywordExtract } from '../../functions/Misc/keywords';
import {
  getKeywordsBySurveyTemp,
  surveyTempExtract,
} from '../../functions/Template_functions/surveyTemp';
import CustomButton from '../Buttons/CustomButton';

interface Props {
  data: any;
  dataToSend: any;
  setDataToSend: (data: any) => void;
  setResetCurrentPage?: (arg: boolean) => void;
  resetCurrentPage?: boolean;
}

function FilterAndSearchBar({
  data,
  dataToSend,
  setDataToSend,
  setResetCurrentPage,
  resetCurrentPage,
}: Props) {
  const [typedData, setTypedData] = useState('');
  const [filterClicked, setFilterClicked] = useState(false);
  const [finishedSearchWord, setFinishedSearchWord] = useState('');
  const [searchCategory, setSearchCategory] = useState('');
  const [sortOption, setSortOption] = useState<string>('');
  const [fetchedKeywords, setFetchedKeywords] = useState<keywordExtract[]>([]);

  const [keywords, setKeywords] = useState<string[]>([]);
  const [keywordsDefault, setKeywordsDefault] = useState<string[]>([]);

  const [testObject, setTestObject] = useState<Filter>({
    tags: [],
    searchText: finishedSearchWord, //tror ej denna behövs?
    searchCategory: searchCategory, // eller denna?
    process: '',
  });

  let tempDataToSend = data;

  const bigFilterFunc = () => {
    setSortOption('');
    if (setResetCurrentPage) {
      setResetCurrentPage(true);
    }
    if (testObject.tags.length !== 0) {
      filterByTags(tempDataToSend).then((res) => {
        tempDataToSend = res;

        if (finishedSearchWord != '') {
          tempDataToSend = filterDataOnSearch(tempDataToSend);
        }
        if (testObject.process != '') {
          tempDataToSend = filterByProcess(tempDataToSend);
        }
        setDataToSend(tempDataToSend);
      });
    } else {
      if (finishedSearchWord != '') {
        tempDataToSend = filterDataOnSearch(tempDataToSend);
      }
      if (testObject.process != '') {
        tempDataToSend = filterByProcess(tempDataToSend);
      }
      setDataToSend(tempDataToSend);
    }
  };

  const sortByTitleAZ = (option: string) => {
    setSortOption(option);
    const sortedArray = dataToSend
      .slice()
      .sort((a: SurveyProps, b: SurveyProps) => {
        const titleA = a.title.toUpperCase();
        const titleB = b.title.toUpperCase();

        if (titleA < titleB) {
          return -1;
        }
        if (titleA > titleB) {
          return 1;
        }
        return 0;
      });
    setDataToSend(sortedArray);
  };

  const sortByTitleZA = (option: string) => {
    setSortOption(option);
    const sortedArray = dataToSend
      .slice()
      .sort((a: SurveyProps, b: SurveyProps) => {
        const titleA = a.title.toUpperCase();
        const titleB = b.title.toUpperCase();

        if (titleA > titleB) {
          return -1;
        }
        if (titleA < titleB) {
          return 1;
        }
        return 0;
      });
    setDataToSend(sortedArray);
  };

  const sortByDateOldest = (option: string) => {
    setSortOption(option);

    const sortedArray = dataToSend
      .slice()
      .sort((a: SurveyProps, b: SurveyProps) => {
        const dateA = new Date(a.creationDate).getTime();
        const dateB = new Date(b.creationDate).getTime();
        return dateA - dateB;
      });
    setDataToSend(sortedArray);
  };

  const sortByDateNewest = (option: string) => {
    setSortOption(option);
    const sortedArray = dataToSend
      .slice()
      .sort((a: SurveyProps, b: SurveyProps) => {
        const dateA = new Date(a.creationDate).getTime();
        const dateB = new Date(b.creationDate).getTime();
        return dateB - dateA;
      });
    setDataToSend(sortedArray);
  };

  const sortOptions = [
    { name: 'Sort by Title, A-Z', onClick: sortByTitleAZ },
    { name: 'Sort by Title, Z-A', onClick: sortByTitleZA },
    { name: 'Sort by Date, Newest', onClick: sortByDateNewest },
    { name: 'Sort by Date, Oldest', onClick: sortByDateOldest },
  ];

  const onChangeHandler = (event: any) => {
    const typedData = event.target.value;
    setTypedData(typedData);
  };

  const enterHandler = (event: any) => {
    if (event.key === 'Enter') {
      setFinishedSearchWord(typedData);
      setTypedData('');
    }
  };

  const buttonEnterHandler = () => {
    setFinishedSearchWord(typedData);
    setTypedData('');
  };

  const handleClearClick = () => {
    setFinishedSearchWord('');
    setTestObject({
      ...testObject,
      tags: [],
      process: '',
      searchCategory: '',
      searchText: '',
    });
    setDataToSend(data);
    setSearchCategory('');
    setSortOption('');
    if (setResetCurrentPage) {
      setResetCurrentPage(true);
    }
  };

  const filterDataOnSearch = (dataToFilter: any) => {
    const lowerCaseSearchTerm = finishedSearchWord.toLowerCase();
    const newDataToSend: any = dataToFilter.filter((data: any) => {
      if (searchCategory.toLowerCase() === '') {
        return (
          data.title.toLowerCase().includes(lowerCaseSearchTerm) ||
          data.department.toLowerCase().includes(lowerCaseSearchTerm)
        );
      }
      if (searchCategory.toLowerCase() === 'title') {
        setTestObject({ ...testObject, searchCategory: 'title' });
        return data.title.toLowerCase().includes(lowerCaseSearchTerm);
      }
      if (searchCategory.toLowerCase() === 'department') {
        setTestObject({ ...testObject, searchCategory: 'department' });
        return data.department.toLowerCase().includes(lowerCaseSearchTerm);
      }
      if (searchCategory.toLowerCase() === 'description') {
        setTestObject({ ...testObject, searchCategory: 'description' });
        return data.description.toLowerCase().includes(lowerCaseSearchTerm);
      }
    });
    return newDataToSend;
  };

  const filterByTags = async (dataToFilterByTags: any) => {
    if (testObject.tags.length === 0) {
      return [];
    }

    const newDataToSend: any[] = [];
    await Promise.all(
      dataToFilterByTags.map(async (data: surveyTempExtract) => {
        const surveyKeywords = await getKeywordsBySurveyTemp(data.id).then(
          (res) => res.map((obj) => obj.id)
        );
        if (testObject.tags.every((tag) => surveyKeywords.includes(tag))) {
          newDataToSend.push(data);
        }
      })
    );

    return newDataToSend;
  };

  const filterByProcess = (dataToFilterByProcess: any) => {
    if (testObject.process == '') {
      return;
    }
    const newDataToSend: any = dataToFilterByProcess.filter((data: any) => {
      return data.process == testObject.process;
    });
    return newDataToSend;
  };

  function createOptionsList(
    strings: string[]
  ): { label: string; value: string }[] {
    if (!strings || strings[0] === '')
      return [] as { label: string; value: string }[];
    return strings.map((string) => ({ label: string, value: string }));
  }

  const handleSelectTagsChange = (
    value: MultiValue<{ label: string; value: string }>
  ) => {
    let tags: string[] = [];
    value.map((option: { label: string; value: string }) =>
      tags.push(option.value)
    );
    setTestObject({ ...testObject, tags: tags });
  };

  const handleSelectProcessChange = (value: any) => {
    if (value) {
      setTestObject({ ...testObject, process: value.value });
    } else {
      setTestObject({ ...testObject, process: '' });
    }
  };

  const customStyles = {
    control: (base: any, state: any) => ({
      ...base,
      minWidth: '200px',
      width: '100%',
    }),
  };

  function createOptionsListKeywords(list: keywordExtract[]) {
    if(list){
      const newList = list.map((obj) => {
        const tempVal: string = obj.value as string;
        const tempId: string = obj.id as string;
        return { value: tempId, label: tempVal };
      });
      return newList;
    }
   
  }

  const handleSelectKeywordsChange = (
    value: MultiValue<{ label: string; value: string }>
  ) => {
    if (value) {
      let keywords: string[] = [];
      let defaultKeywords: string[] = [];
      value.map(
        (option: { label: string; value: string }) => (
          keywords.push(option.value), defaultKeywords.push(option.label)
        )
      );
      setTestObject({ ...testObject, tags: keywords });
      // setKeywords(keywords);
      setKeywordsDefault(defaultKeywords);
    } else {
      setTestObject({ ...testObject, tags: [] });
    }
  };

  useEffect(() => {
    bigFilterFunc();
  }, [testObject.tags, testObject.process, finishedSearchWord, searchCategory]);

  useEffect(() => {
    getKeywords().then((res) => {
      setFetchedKeywords(res);
    });
  }, []);

  return (
    <>
      <div className='filter-search-sort row'>
        <div className='row' id='filter-search-container'>
          <div className='dropdown-search-searchbtn-div'>
            <div>
              <Dropdown>
                <Dropdown.Toggle id='dropdown-search-category'>
                  {searchCategory}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => setSearchCategory('Title')}>
                    Title
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => setSearchCategory('Description')}
                  >
                    Description
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => setSearchCategory('Department')}
                  >
                    Department
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>

            <div>
              <input
                type='search'
                placeholder={
                  finishedSearchWord !== '' ? finishedSearchWord : 'Search'
                }
                className='search-bar'
                aria-label='Search'
                onChange={onChangeHandler}
                onKeyUp={enterHandler}
                value={typedData}
              />
            </div>

            <div>
                <CustomButton
                  icon={ finishedSearchWord === '' ? searchIcon : crossIcon}
                  id='search-icon-button'
                  onClick={finishedSearchWord === '' ? buttonEnterHandler : () => setFinishedSearchWord('')}
                />
                
            </div>
          </div>

          <div className='filter-sort-reset-div'>
            <div>
              <CustomButton
                id={'filter-btn'}
                text='Filter'
                onClick={() => setFilterClicked(!filterClicked)}
             />
            </div>

            <div>
              <NavDropdown
                title={sortOption != '' ? sortOption : 'Sort'}
                id='navbarScrollingDropdown'
              >
                {sortOptions.map((option, index) => (
                  <NavDropdown.Item
                    key={index}
                    onClick={() => option.onClick(option.name)}
                  >
                    {option.name}
                  </NavDropdown.Item>
                ))}
              </NavDropdown>
            </div>

            <div>
              <CustomButton
                id='reset-btn'
                text='Reset'
                onClick={() => handleClearClick()}
              />
              
            </div>
          </div>
        </div>

        <div className='row'>
          <Collapse in={filterClicked}>
            <div id='example-collapse-text'>
              <Select
                placeholder='Keywords'
                defaultValue={createOptionsList(testObject.tags)} //{createOptionsList(keywords)}  gamla
                options={createOptionsListKeywords(fetchedKeywords)} //createOptionsList(keywordsDummy.keywords)} //byt ut itll hämtad
                isMulti
                isClearable
                isSearchable
                onChange={(value) => {
                  handleSelectKeywordsChange(value);
                }}
                //styles={customStyles}
              />

              <Select
                placeholder='Process'
                value={createOptionsList([testObject.process])}
                options={createOptionsList(processes.process)}
                isClearable
                isSearchable
                onChange={(value) => {
                  handleSelectProcessChange(value);
                }}
                styles={customStyles}
              />
            </div>
          </Collapse>
        </div>
      </div>
    </>
  );
}

export default FilterAndSearchBar;
