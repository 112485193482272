import React, { useState, useEffect } from 'react';
import './SideNavBar.css';
import 'bootstrap/dist/css/bootstrap.css';
import Nav from 'react-bootstrap/Nav';
import NavItems from './SideNavData';
import { useLocation } from 'react-router-dom';
import Profile from '../Profile/Profile';
import { expandIcon, crossIcon, hamburgerIcon } from '../../assets/Icons';

const SideNavbar = () => {
  const [visibleDropdown, setVisibleDropdown] = useState(false);
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(true ? window.innerWidth > 1024 : false);
  const [showToggle, setShowToggle] = useState(true ? window.innerWidth < 1024 : false);

  //To ensure responsible component
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1024) {
        setIsOpen(true);
        setShowToggle(false);
      }
      if (window.innerWidth < 1024) {
        setIsOpen(false);
        setShowToggle(true);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isOpen]);

  //To ensure that menu option (Create survey) is open when on certain paths
  useEffect(() => {
    if (
      location.pathname === '/createnewsurvey' ||
      location.pathname === '/createsurveyfromtemplate'
    ) {
      setVisibleDropdown(true);
    }
  }, []);

  return (
    <>
      {showToggle && (
        <div className='toggle'>
          <button onClick={() => setIsOpen(!isOpen)}>
            {isOpen ? crossIcon : hamburgerIcon}
          </button>
        </div>
      )}

      <div className={`side-nav ${isOpen ? 'sidenav-open' : 'sidenav-closed'}`}>
        <div className='img-div'>
          <Profile></Profile>
        </div>

        <Nav navbar={true} className='flex-column'>
          {NavItems.map((item, index) => (
            <Nav.Item key={item.id} id={item.id}>
              {item.subItems ? (
                <Nav.Link
                  className={'nav-item'}
                  id={'nav-item-with-dropdown'}
                  eventKey={item.id}
                  onClick={() => setVisibleDropdown(!visibleDropdown)}
                  href={item.href}
                >
                  {item.icon} {item.label} {expandIcon}
                </Nav.Link>
              ) : (
                <Nav.Link
                  className={`nav-item ${
                    location.pathname === item.href && !item.subItems
                      ? 'active'
                      : ''
                  }`}
                  eventKey={item.id}
                  href={item.href}
                >
                  {item.icon} {item.label}
                </Nav.Link>
              )}
              {item.subItems && visibleDropdown && (
                <div id='nav-dropdown'>
                  {item.subItems.map((subItem, index) => (
                    <Nav.Link
                      key={subItem.id}
                      className={`nav-item ${
                        location.pathname === subItem.href ? 'active' : ''
                      }`}
                      eventKey={subItem.id}
                      href={subItem.href}
                    >
                      {subItem.label}
                    </Nav.Link>
                  ))}
                </div>
              )}
            </Nav.Item>
          ))}
        </Nav>
      </div>
    </>
  );
};

export default SideNavbar;
