import { useAuth0 } from '@auth0/auth0-react';
import './Profile.css';
import defaultProfilePicture from './default-profile.png';

const Profile = () => {
  const { user, isAuthenticated, isLoading } = useAuth0();

  //Makes sure User have time loading in
  if (isLoading) {
    return <div>Loading ...</div>;
  }

  //Guards against non authenticated users
  if (!isAuthenticated || !user) {
    return <div>User is not logged in.</div>;
  }

  return (
    <div className='profile-div'>
      <img
        id='profile-pic'
        src={user.picture}
        alt={user.name + 'Picture'}
        style={{ marginTop: '2rem' }}
        onError={(e) => { //IF image can not be loaded => use default image
          const target = e.target as HTMLImageElement;
          target.src = defaultProfilePicture;
        }}
      />
      <p style={{ marginTop: '1rem', fontWeight: 'bold' }}>{user.name}</p>
    </div>
  );
};

export default Profile;
