function UnathorizedPage() {
  return (
    <>
    <p>
        DU FÅR INTE KOMMA IN
    </p>
    </>
  );
}

export default UnathorizedPage;
