async function hashString(sentSurveyId: string): Promise<string> {
  try{  
    const encoder = new TextEncoder();
    const data = encoder.encode(sentSurveyId);
    const buffer = await crypto.subtle.digest('SHA-256', data);
    const hashedSurveyId = Array.from(new Uint8Array(buffer))
      .map(b => b.toString(16).padStart(2, '0'))
      .join('');
    return hashedSurveyId;
  }
  catch(error){
    console.log(error);
    throw(error);
  }
}

export default hashString;