import createClient from "../axios"
//Variable "data" is a JSON object - The object consists different things based on the function
//Variable "id" is an ID - The ID depends on either the id of the section you want to get or the survey you want to get.

export type questionSentCreate = {
    title: string;
    titleEmoji?: string;
    description: string;
    type: string;
    mandatory: boolean;
    qoptions: string[];
    emojiOpt?: string[];
    bounds?: number[];
    condition: string[];
    multipleChoice:  boolean;
    sectionId: string;
    parentQuestionId?: string;
}

export type questionSentUpdate= {
    id: string;
    title?: string;
    titleEmoji?: string;
    description?: string;
    type?: string;
    mandatory?: boolean;
    qoptions?: string[];
    emojiOpt?: string[];
    bounds?: number[];
    condition?: string[];
    multipleChoice?: boolean;
    sectionId?: string;
    answer?: string[];
}

export type questionSentExtract = {
    id: string;
    title: string;
    titleEmoji?: string;
    description: string;
    type: string;
    mandatory: boolean;
    qoptions?: string[];
    emojiOpt?: string[];
    bounds?: number[];
    condition?: string[];
    multipleChoice: boolean;
    sectionId: string;    
    parentQuestionId?: string;
    answer: string[]; 
}

/**
 * Creates a sent question
 * @param data for creating the question, type questionSentCreate
 * @returns string, id of the created question.
 */
export async function createQuestionSent(data: questionSentCreate):Promise<string> {
    const client = await createClient();
    return client.post('/questionsent/', data)
    .then((response) => {
        return response.data.id
    })
    .catch((error) => console.log(error));
}

/**
 * Gets a question by its id.
 * @param id id of the question
 * @returns type questionsSentExtract, the questions data.
 */
export async function getQuestionSentById(id: string):Promise<questionSentExtract> {
    const client = await createClient();
    return client.get('/questionsent/'+ id)
    .then((response) => {
        return response.data
    })
    .catch((error) => console.log(error));
}

/**
 * Get all sent questions by section id
 * @param id the id of the section
 * @returns type questionSentExtract[], an array containing all questions.
 */
export async function getQuestionSentBySectionId(sectionId: string):Promise<questionSentExtract[]> {
    const client = await createClient();
    return client.get('/questionsent/section/' + sectionId,  {params: {
        id: sectionId
    }})
    .then((response) => {
        return response.data
    })
    .catch((error) => console.log(error));
}

/**
 * Updates a sent question
 * @param data the data of the question to be updated.
 * @returns string, the id of the updated question
 */
export async function updateQuestionSent(data: questionSentUpdate):Promise<string> {
    const client = await createClient();
    return client.put('/questionsent/' + data.id, data)
    .then((response) => {
        return response.data.id;
    })
    .catch((error) => console.log(error)); 
}

/**
 * deletes a sent question
 * @param id id of question to be deleted
 * @returns string, the id of the deleted question.
 */
export async function deleteQuestionSent(id: string):Promise<string> {
    const client = await createClient();
    return client.delete('/questionsent/' + id)
    .then((response) => {
        return response.data.id
    })
    .catch((error) => console.log(error));
}
