import { trashIcon } from '../../assets/Icons';
import { Emoji } from 'emoji-picker-react';
import './ListAnswerChoice.css';
import { imageIcon } from '../../assets/Icons';
import { Answer } from '../../assets/types';

interface Props {
  answers: Answer[];
  handleRemove: (data: string) => void;
  edit: boolean;
  type: string;
  multipleChoice: boolean;
}

const ListAnswerChoice = ({
  answers,
  handleRemove,
  edit,
  type,
  multipleChoice,
}: Props) => {
  if (type === 'image') {
    return (
      <div className='imageContainer'>
        {imageIcon} <p>Not yet implemented</p>
      </div>
    );
  }
  if (type === 'multiple choice') {
    const inputType = multipleChoice ? 'checkbox' : 'radio';
    return (
      <>
        {answers.map((answer) => (
          <div key={answer.answer + 'div container'} className='listAnswersDiv'>
            <div className='inputButtons'>
              <input
                disabled={true}
                key={answer.answer}
                type={inputType}
                value={answer.answer}
              />
            </div>

            <Emoji unified={answer.emoji} size={25} />

            <div className='answerTextDiv col'>{answer.answer}</div>

            {edit && (
              <div>
                {' '}
                <button
                  className='removeButton'
                  onClick={() => {
                    handleRemove(answer.answer);
                  }}
                >
                  {trashIcon}
                </button>
              </div>
            )}
          </div>
        ))}
      </>
    );
  }
  if (type === 'icon') {
    return (
      <>
        {answers.map((answer) => (
          <div key={answer.answer + 'div container'} className='listAnswersDiv'>
            <button className='iconButton'>
              <Emoji unified={answer.emoji} size={40} />
            </button>
            <div className='altTextRemoveContainer'>
              <div className='iconAltTextDiv'>alt text: {answer.answer}</div>
              {edit && (
                <div>
                  {' '}
                  <button
                    className='removeButton'
                    onClick={() => {
                      handleRemove(answer.answer);
                    }}
                  >
                    {trashIcon}
                  </button>
                </div>
              )}
            </div>
          </div>
        ))}
      </>
    );
  }
  return (
    <>
      <textarea
        disabled={true}
        placeholder={'user input restricted to: ' + type}
        className='listAnswersTextArea'
      />
    </>
  );
};

export default ListAnswerChoice;
