import SurveyInstanceSection from '../../components/SurveySection/SurveyInstancesSection';
import './ViewSurveyInstances.css';
import { getSurveyInstances, surveySentExtract } from '../../functions/Sent_functions/surveySent';
import { useEffect, useState } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { linkIcon } from '../../assets/Icons';
import hashString from '../../functions/scripts/urlScript';

type dataFromBefore = {
  id: string,
  title: string,
  description: string,
  completedSection?: boolean
}

const ViewSurveyInstances = ( {id, title, description, completedSection} : dataFromBefore )  => {

  const [dataToSend, setDataToSend] = useState<surveySentExtract[]>([]);
  const [middleList, setMiddleList] = useState<surveySentExtract[]>([]);
  const [middleLink, setMiddleLink] = useState<string>();

  const handleGetInstances = () => {
    if (id) {
      getSurveyInstances(id).then((result: surveySentExtract[]) => {
        const filteredList = result.filter(obj => !obj.middle)
        const middleListTmp = result.filter(obj => obj.middle)
        setDataToSend(filteredList);
        setMiddleList(middleListTmp)
        if(middleListTmp[0])
       { hashString(middleListTmp[0].id).then((middleUrl) => {
          setMiddleLink(middleUrl)
        })  }
      });
    }
  };

  useEffect(() => {
    handleGetInstances();
  }, []);

  return (
    <div >
      <div className='view-survey-instances-top'>
        <div className='view-survey-instances-title'>
          <b >{title} </b>
       { middleList.length !== 0 && !completedSection &&
       <OverlayTrigger
        trigger='click'
        key={'left'}
        placement={'left'}
        overlay={
          <Popover id={`popover-positioned-${'left'}`}>
             <Popover.Header>
              Anonymous Link
             </Popover.Header>
            <Popover.Body>
            https://forms.datatovalue.se/empty/{middleLink}
            </Popover.Body>
          </Popover>
        }
      >
        <button
          id='instance-url-btn-view'
          // disabled={isRunning}
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          {linkIcon}
        </button>
      </OverlayTrigger>}
      </div>

        <div className='view-survey-instances-desc'>
          <em> {description}</em>
        </div>
      </div>
      
      <SurveyInstanceSection surveyInstanceList={dataToSend} middleUrl={middleLink} completedSection={completedSection}/>
      {
        dataToSend.length === 0 ? 
        <h6><em>No answers yet</em></h6>
        : ''
      }
    </div>
  );
};

export default ViewSurveyInstances;
