import React, { useState, useEffect } from 'react';
import { getSurveysByOwnerNotCompleted } from '../../functions/Created_functions/createdUtils';
import { getSurveyTempByCreator } from '../../functions/Template_functions/surveyTemp';
import SurveySection from '../../components/SurveySection/SurveySection';
import { useAuth0 } from '@auth0/auth0-react';

function MyCreatedSurveys() {
  const { isAuthenticated, user, isLoading } = useAuth0();
  const ownerId = user?.sub;
  const [dataToSend, setDataToSend] = useState([]);
  const [surveysSent, setSurveysSent] = useState([]);
  const [renderPage, setRenderPage] = useState<boolean>(false);

  const handleGetSentSurveys = () => {
    if (ownerId) {
      getSurveysByOwnerNotCompleted(ownerId).then((result: any) => {
        setSurveysSent(result);
      });
    }
  };

  const handleGetMyCreatedTemps = () => {
    if (ownerId) {
      getSurveyTempByCreator(ownerId).then((result: any) => {
        setDataToSend(result);
      });
    }
  };

  useEffect(() => {
    handleGetMyCreatedTemps();
    handleGetSentSurveys();
  }, []);

  useEffect(() => {
    handleGetMyCreatedTemps();
    handleGetSentSurveys();
  }, [renderPage]);

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  if (!isAuthenticated || !user) {
    return <div>User is not logged in.</div>;
  }

  return (
    <div className='page'>
      <SurveySection
        showSearchBar={true}
        firstColumn={'Title'}
        secondColumn={'Department'}
        thirdColumn={'No. Responds'}
        fourthColumn={'Date'}
        data={dataToSend}
        itemsPerPage={10}
        title={'My Created Survey Templates'}
        isTemplate={true}
        isFavAble={true}
        surveyBankCompareData={surveysSent.length !== 0 ? surveysSent : []}
        renderPage={renderPage}
        setRenderPage={setRenderPage}
      />
    </div>
  );
}

export default MyCreatedSurveys;
