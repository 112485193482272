import createClient from '../axios'; 


export type URLrelation= {
    id: string; //Detta är URL
    sentId: string;
    patientId?: string;
    anonymous: boolean;
};

export type URLrelationUpdate= {
    id: string; //Detta är URL
    sentId?: string;
    patientId?: string;
    anonymous?: boolean;
};

/**
 * Creates an URL relation in the database
 * @param data, the information of the relation.
 * @returns string, the id of the entry.
 */
//This needs: the URL, the surveySentID, and the patientID(incase it exsists)
export async function createURLrelation(data: URLrelation):Promise<String> {
    const client = await createClient();
    return client.post('/URLrelation', data) //data is a json object
        .then((response) => {
            return response.data.id; //KAnske ta bort .id vi får se
        })
        .catch((error) => console.log(error));
};
 
/**
 * Gets an URL relation based on the id.
 * @param id the id of the url
 * @returns URLrelation, the entry in the table.
 */
export async function getURLrelationById(id: string):Promise<URLrelation> {
    const client = await createClient();
    return client.get('/URLrelation/' + id) //id is the survey id
        .then((response) => {
            return response.data;
        })
        .catch((error) => console.log(error));
};

/**
 * update URL relation
 * @param data containing the update information.
 * @returns string, the id the updated URL relation.
 */
export async function updateURLrelation(data: URLrelationUpdate):Promise<String> {
    const client = await createClient();
    return client.put('/URLrelation/' + data.id, data) //data is a json object
        .then((response) => {
            return response.data.id;
        })
        .catch((error) => console.log(error));
};

/**
 * deletes url relation
 * @param id The id of the url relation
 * @returns string, the id of the deleted relation.
 */
export async function deleteURLrelation(id: string):Promise<String> {
    const client = await createClient();
    return client.delete('/URLrelation/' + id) //data is a json object
        .then((response) => {
            return response.data;
        })
        .catch((error) => console.log(error));
};

/**
 * Gets the occurances of a survey in the table
 * @param id The id of the survey
 * @returns number, indicating a count.
 */
export async function getOccurancesById(id: string): Promise<Number> {
    const client = await createClient();
    return client.get('/URLrelation/' + id)
    .then((response)=>{
        return response.data;
    })
    .catch((error)=>console.log(error));
}
