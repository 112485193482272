import 'bootstrap/dist/css/bootstrap.css';
import Button from 'react-bootstrap/Button';
import CSS from 'csstype';

interface Props {
  id?: string;
  text?: string;
  icon?: any;
  disabled?: boolean;
  onClick: any;
  title?: any;
}

const style_button: CSS.Properties = {
  backgroundColor: 'var(--primary-color)',
  borderColor: 'var(--primary-color)',
};

const CustomButton = ({ id, text, icon, onClick, disabled, title}: Props) => {
  return (
    <Button
      id={id}
      style={style_button}
      className='primary'
      onClick={onClick}
      variant='primary'
      disabled={disabled || false}
      title={title}
    >
      {text}
      {icon}
    </Button>
  );
};

export default CustomButton;
