import createClient from '../axios';

export type staff = {
    id: string;
    favorites: string[];
    name?: string;
};

/**
 * Gets all staff.
 * @returns staff[], an array of all staff.
 */
export async function getStaff():Promise<staff[]> {
    const client = await createClient();
    return client.get('/staff')
    .then((response) => {
        return response.data
    })
    .catch((error) => console.log(error));
};

/**
 * gets a staff based on the id.
 * @param id id of the staff.
 * @returns staff.
 */
export async function getStaffById(id: string):Promise<staff> {
    const client = await createClient();
    return client.get('/staff/'+ id)
    .then((response) => {
        return response.data;
    })
    .catch((error) => console.log(error)); 
};

/**
 * Updates staff based on id.
 * @param data data with the information to update.
 * @returns string, the id of the staff.
 */
export async function updateStaffById(data: staff):Promise<string> {
    const client = await createClient();
    return client.put('/staff/'+ data.id, data)
    .then((response) => {
        return response.data.id;
    })
    .catch((error) => console.log(error)); 
};

/**
 * Creates a staff member.
 * @param id Id of the staff member to be created.
 * @returns id of the created staff member
 */
export async function createStaff(id: any):Promise<string> {
    const client = await createClient();
    return client.post('/staff/' + id, id)
    .then((response) => {
        return response.data.id;
    })
    .catch((error) => console.log(error));
};

/**
 * Deletes a staff.
 * @param id id of the staff.
 * @returns the id of the deleted staff.
 */
export async function deleteStaff(id:string):Promise<string> {
    const client = await createClient();
    return client.delete('/staff/' + id)
    .then((response) => {
        return response.data.id
    })
    .catch((error) => console.log(error));
};
