import React, { useEffect, useState } from 'react';
import './SurveyRow.css';
import 'bootstrap/dist/css/bootstrap.css';
import './SurveySection.css';
import SurveyRow, { type SurveyProps } from './SurveyRow';
import Accordion from 'react-bootstrap/Accordion';
import Pagination from '../Pagination/Pagination';
import { crossIcon } from '../../assets/Icons';
import { surveyCreatedExtract } from '../../functions/Created_functions/surveyCreated';

const SurveySection = ({
  createSurvey,
  setSurveyTemplate,
  firstColumn,
  secondColumn,
  thirdColumn,
  fourthColumn,
  data,
  itemsPerPage,
  title,
  isTemplate,
  isFavAble,
  showSearchBar,
  setResetCurrentPage,
  resetCurrentPage,
  surveyBankCompareData,
  surveyBank,
  batchCompleted,
  teamSection,
  setRenderPage,
  renderPage,
}: {
  showSearchBar: boolean;
  createSurvey?: boolean;
  setSurveyTemplate?: (surveyTemplateId: string) => void;
  firstColumn: string;
  secondColumn: string;
  thirdColumn: string;
  fourthColumn: string;
  data: SurveyProps[];
  itemsPerPage: number;
  title: string;
  isTemplate: boolean;
  resetCurrentPage?: boolean;
  setResetCurrentPage?: (arg: boolean) => void;
  isFavAble: boolean;
  surveyBankCompareData?: surveyCreatedExtract[];
  surveyBank?: boolean;
  batchCompleted?: boolean;
  teamSection?: boolean;
  setRenderPage?: (arg: boolean) => void;
  renderPage?: boolean;
}) => {
  const [activeKeys, setActiveKeys] = useState<string[]>([]);
  const [currentData, setCurrentData] = useState(data);
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = itemsPerPage;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  let currentRecords: any[] = [];
  let nPages = 0;
  if (currentData !== undefined) {
    currentRecords = currentData.slice(firstIndex, lastIndex);
    nPages = Math.ceil(currentData.length / recordsPerPage);
  }

  const handleAccordionToggle = (eventKey: any) => {
    if (activeKeys.includes(eventKey)) {
      setActiveKeys(activeKeys.filter((key) => key !== eventKey));
    } else {
      setActiveKeys([...activeKeys, eventKey]);
    }
  };

  const sortByDateNewest = (arg: any) => {
    if (arg) {
      let sortedArray = arg.slice().sort((a: SurveyProps, b: SurveyProps) => {
        const dateA = new Date(a.creationDate).getTime();
        const dateB = new Date(b.creationDate).getTime();
        return dateB - dateA;
      });
      return sortedArray;
    }
  };

  const [typedData, setTypedData] = useState('');
  const [finishedSearchWord, setFinishedSearchWord] = useState('');
  const [maxPageNumbers, setMaxPageNumbers] = useState(
    window.innerWidth > 1024 ? 3 : 1
  );
  const [smallerScreen, setSmallerScreen] = useState(
    window.innerWidth <= 600 ? true : false
  );
  const [surveyBankData, setSurveyBankData] = useState(
    surveyBankCompareData ? surveyBankCompareData : []
  );
  const [hasSurveyBankCompareData, setHasSurveyBankCompareData] =
    useState(false);

  useEffect(() => {
    if (surveyBankCompareData?.length !== 0 && surveyBankCompareData) {
      setSurveyBankData(surveyBankCompareData);
      setHasSurveyBankCompareData(true);
    }
  }, [surveyBankCompareData]);

  const filterDataOnSearch = () => {
    const lowerCaseSearchTerm = finishedSearchWord.toLowerCase();
    const sortedData = surveyBank ? data : sortByDateNewest(data);
    let tempDataToSend = sortedData;
    if (currentData !== undefined) {
      const newDataToSend: any = tempDataToSend.filter((data: any) => {
        return data.title.toLowerCase().includes(lowerCaseSearchTerm);
      });
      setCurrentData(newDataToSend);
    }
  };

  const onChangeHandler = (event: any) => {
    const typedData = event.target.value;
    setTypedData(typedData);
  };

  const enterHandler = (event: any) => {
    if (event.key === 'Enter') {
      setCurrentPage(1);
      setFinishedSearchWord(typedData);
      setTypedData('');
    }
  };

  useEffect(() => {
    setActiveKeys([]);
  }, [renderPage]);

  useEffect(() => {
    filterDataOnSearch();
  }, [finishedSearchWord]);

  useEffect(() => {
    const sortedData = surveyBank ? data : sortByDateNewest(data);
    setCurrentData(sortedData);
  }, [data]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1150) {
        setMaxPageNumbers(5);
      }
      if (window.innerWidth < 1150) {
        setMaxPageNumbers(3);
      }
      if (window.innerWidth < 900) {
        setMaxPageNumbers(5);
      }
      if (window.innerWidth < 490) {
        setMaxPageNumbers(3);
      }
      if (window.innerWidth < 400) {
        setMaxPageNumbers(1);
      }
      if (window.innerWidth < 350) {
        setMaxPageNumbers(0);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  });

  const clearSearch = () => {
    setFinishedSearchWord('');
    setCurrentPage(1);
    const sortedData = surveyBank ? data : sortByDateNewest(data);
    setCurrentData(sortedData);
  };

  useEffect(() => {
    filterDataOnSearch();
  }, [finishedSearchWord]);

  return (
    <div className='surveysection'>
      <div className='title-div'>
        <div id='title'>
          <b>{title}</b>
        </div>
        {showSearchBar && (
          <input
            id={'searchbar-in-surveysection'}
            type='search'
            placeholder={
              finishedSearchWord !== '' ? finishedSearchWord : 'Search Title...'
            }
            className='search-bar'
            aria-label='Search'
            onChange={onChangeHandler}
            onKeyUp={enterHandler}
            value={typedData}
          />
        )}
        {finishedSearchWord !== '' && (
          <button
            className={'clear-search-button-surveysection'}
            onClick={clearSearch}
          >
            {crossIcon}
          </button>
        )}
      </div>

      <div className='header row' id='header-row'>
        <div className='sectioncolumn'>
          <b>{firstColumn}</b>
        </div>
        {!smallerScreen && (
          <div className='sectioncolumn'>
            <b>{secondColumn}</b>
          </div>
        )}
        <div className='sectioncolumn'>
          <b>{thirdColumn}</b>
        </div>
        <div className='sectioncolumn'>
          <b>{fourthColumn}</b>
        </div>
      </div>

      <div className='surveyrows'>
        {currentRecords && (
          <Accordion
            flush
            activeKey={activeKeys}
            onSelect={handleAccordionToggle}
          >
            {currentRecords.map((survey: SurveyProps, index: number) => {
              const surveyBankSentExists = surveyBankData?.filter(
                (item) => item.title === survey.title
              );

              return (
                <div className='surveyrow-in-section' key={index}>
                  <SurveyRow
                    createSurvey={createSurvey}
                    setSurveyTemplate={setSurveyTemplate}
                    survey={survey}
                    idx={index}
                    isTemplate={isTemplate}
                    isFavAble={isFavAble}
                    surveyBankSentExists={surveyBankSentExists}
                    surveyBank={surveyBank}
                    batchCompleted={batchCompleted}
                    teamSection={teamSection}
                    setRenderPage={setRenderPage}
                    renderPage={renderPage}
                  />
                </div>
              );
            })}
          </Accordion>
        )}
      </div>

      <Pagination
        maxPageNumbers={maxPageNumbers}
        nPages={nPages}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </div>
  );
};

export default SurveySection;
