import Select, { MultiValue } from 'react-select';
import './QuestionCreate.css';
import { useRef, useState } from 'react';
import CustomButton from '../Buttons/CustomButton';
import DoneButton from '../Buttons/DoneButton';
import { updateQuestionTemp } from '../../functions/Template_functions/questionTemp';

interface Props {
  questionId: string;
  choices: string[];
  conditionsFromTemp: string[];
  edit: boolean;
  setEdit: (edit: boolean) => void;
  toggleFollowUpIsEditing: (id: string, isEditing: boolean) => void;
  setMissingConditions: (missing: boolean) => void;
  setConditions: (conditions: string[]) => void;
  setChildIsEditing: (id: string, isEditing: boolean) => void;
  handleAddConditions: (
    conditions: string[],
    remove: string[],
    question: string
  ) => void;
  setAddNewFollowUp: (addNewFollowUp: boolean) => void;
}

const FollowUpConditions = ({
  setAddNewFollowUp,
  edit,
  setEdit,
  toggleFollowUpIsEditing,
  questionId,
  conditionsFromTemp,
  setMissingConditions,
  choices,
  handleAddConditions,
  setChildIsEditing,
  setConditions,
}: Props) => {
  const customStyles = {
    control: (base: any, state: any) => ({
      ...base,
      minWidth: '200px', // Set the width to your desired value
      width: '100%',
    }),
  };

  const [selectedOptions, setselectedOptions] =
    useState<string[]>(conditionsFromTemp);

  const [missingOption, setMissingOption] = useState<boolean>(false);

  const previousValues = useRef(selectedOptions);

  const handleSelecChange = (
    value: MultiValue<{ label: string; value: string }>
  ) => {
    let conditions: string[] = [];
    value.map((option: { label: string; value: string }) =>
      conditions.push(option.value)
    );
    setselectedOptions(conditions);
  };

  const handleDone = () => {
    if (selectedOptions.length === 0) {
      setMissingOption(true);
    } else {
      setMissingOption(false);
      setEdit(false);
      setChildIsEditing(questionId, false);
      const remove = previousValues.current.filter(
        (prev) => !selectedOptions.includes(prev)
      );
      handleAddConditions(selectedOptions, remove, questionId);
      previousValues.current = selectedOptions;
      updateQuestionTemp({ id: questionId, condition: selectedOptions });
      setMissingConditions(false);
      toggleFollowUpIsEditing(questionId, false);
      setConditions(selectedOptions);
      setAddNewFollowUp(false);
    }
  };
  function createOptionsList(
    strings: string[]
  ): { label: string; value: string }[] {
    if (!strings) return [] as { label: string; value: string }[];
    return strings.map((string) => ({ label: string, value: string }));
  }
  return (
    <div className='follow-up-condition-contatiner'>
      <b>Show if selected answer is: </b>
      {edit ? (
        <>
          <Select
            placeholder='select conditional option'
            options={createOptionsList(choices)}
            defaultValue={createOptionsList(conditionsFromTemp)}
            value={createOptionsList(selectedOptions)}
            onChange={(value) => {
              handleSelecChange(value);
            }}
            styles={customStyles}
            isMulti
            isSearchable
          />
          {missingOption && 'Please select a conditional option'}
        </>
      ) : (
        selectedOptions.map((option, index) => (
          <div className='follow-up-conditions-show' key={index}>
            <i> {option} </i>
            {index !== selectedOptions.length - 1 && <> or</>}
          </div>
        ))
      )}
      {edit ? (
        <DoneButton text='Done' onClick={handleDone} />
      ) : (
        <CustomButton
          text='Edit'
          onClick={() => {
            setEdit(true);
            setChildIsEditing(questionId, true);
            toggleFollowUpIsEditing(questionId, true);
          }}
        />
      )}
    </div>
  );
};

export default FollowUpConditions;
