import React, { useEffect, useState } from 'react';
import './StartPage.css';
import StartWidget from '../../components/StartWidget/StartWidget';
import { bank } from '../../assets/Icons';
import SurveySection from '../../components/SurveySection/SurveySection';
import { useNavigate } from 'react-router-dom';
import { getSurveysByOwner } from '../../functions/Created_functions/surveyCreated';
import { getStaffById } from '../../functions/Misc/staffCreated';
import { getAllSurveyTemps } from '../../functions/Template_functions/surveyTemp';
import { getSurveysByOwnerNotCompleted } from '../../functions/Created_functions/createdUtils';
import { useAuth0 } from '@auth0/auth0-react';

function StartPage() {
  const { isAuthenticated, user, isLoading } = useAuth0();
  const ownerId = user?.sub;
  const [dataToSend, setDataToSend] = useState<any[]>([]);
  const [surveyTemps, setSurveyTemps] = useState<any[]>([]);
  const [favorites, setFavorites] = useState<any[]>([]);
  const [isSurveyTempsFetched, setIsSurveyTempsFetched] = useState(false);
  const [renderPage, setRenderPage] = useState<boolean>(false)

  const navigate = useNavigate();
  const navigateToSurveyBank = () => {
    navigate('/surveybank');
  };


  async function handleGetFavorites() {
    if (ownerId && surveyTemps) {
      getStaffById(ownerId).then((result: any) => {
        const favs = surveyTemps.filter((obj) =>
          result.favorites.includes(obj.id)
        );
        setFavorites(favs);
      });
    }
  }

  async function handleGetTemps() {
    getAllSurveyTemps().then((result: any) => {
      setSurveyTemps(result);
      setIsSurveyTempsFetched(true);
    });
  }



  async function handleGetSentSurveys() {
    if (ownerId) {
      getSurveysByOwnerNotCompleted(ownerId).then((result: any) => {
        setDataToSend(result);
      });
    }
  }

  useEffect(() => {
    handleGetSentSurveys();
    handleGetTemps();
  }, []);

  useEffect(() => {
    handleGetSentSurveys();
    handleGetTemps();
    handleGetFavorites();
  }, [renderPage]);

  useEffect(() => {
    if (isSurveyTempsFetched) {
      handleGetFavorites();
    }
  }, [isSurveyTempsFetched]);

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  if (!isAuthenticated || !user) {
    return <div>User is not logged in.</div>;
  }
  return (
    <div className='page' id='startpage'>
      <div className='top-container'>
        <StartWidget
          title={'Survey Bank'}
          icon={bank}
          onClick={navigateToSurveyBank}
        />
      </div>
      <div className='bottom-container'>
        <div className='bottom-left'>
          <SurveySection
            showSearchBar={true}
            firstColumn={'Title'}
            secondColumn={'Department'}
            thirdColumn={'No. Responds'}
            fourthColumn={'Date'}
            data={dataToSend}
            itemsPerPage={7}
            title={'Sent Surveys'}
            isTemplate={false}
            isFavAble={false}
            setRenderPage={setRenderPage}
            renderPage={renderPage}
          />
        </div>

        <div className='bottom-right'>
          <SurveySection
            showSearchBar={true}
            firstColumn={'Title'}
            secondColumn={'Department'}
            thirdColumn={'No. Responds'}
            fourthColumn={'Date'}
            data={favorites}
            itemsPerPage={7}
            title={'Favorites'}
            isTemplate={true}
            isFavAble={true}
            setRenderPage={setRenderPage}
            renderPage={renderPage}
          />
        </div>
      </div>
    </div>
  );
}

export default StartPage;
