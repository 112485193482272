import 'bootstrap/dist/css/bootstrap.css';

interface Props {
  text: string;
  id?: string;
  onClick?: () => void;
}

const SubmitButton = ({ text, onClick, id }: Props) => {
  return (
    <button id= {id} onClick={onClick} type='submit' className='btn btn-success'>
      {' '}
      {text}
    </button>
  );
};

export default SubmitButton;
