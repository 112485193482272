import Breadcrumb from 'react-bootstrap/Breadcrumb';
import './Breadcrumbs.css'

interface Props {
  breadcrumbItems: { text: string; link?: string }[];
}

function Breadcrumbs({ breadcrumbItems }: Props) {
  return (
    <div className='bread-div'>
    <Breadcrumb >
      {breadcrumbItems.map((item, index) => (
        <Breadcrumb.Item
          key={index}
          href={item.link !== undefined ? item.link : undefined}
        >
          {item.text}
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
    </div>
  );
};

export default Breadcrumbs;


