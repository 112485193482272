import { useState, useEffect } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import './QuestionBank.css';
import CustomButton from '../Buttons/CustomButton';
import Pagination from '../Pagination/Pagination';
import QuestionInQuestionBank from './QuestionInQuestionBank';
import { Accordion } from 'react-bootstrap';
import SearchBarQB from '../../components/FilterAndSearchBar/SearchBarQB';
import { questionInQBType } from '../../assets/types';

const QuestionBank = ({
  itemsPerPage,
  data,
  staticBackground,
  addNewQuestion,
  sendChecked,
  show,
  setShow,
}: {
  itemsPerPage: number;
  data: any;
  staticBackground: boolean;
  addNewQuestion: boolean;
  sendChecked: (questions: any[]) => void;
  show: boolean;
  setShow: (show: boolean) => void;
}) => {
  const [checkedQuestions, setCheckedQuestions] = useState<questionInQBType[]>(
    []
  );

  const handleQuestionChecked = (
    question: questionInQBType,
    checked: boolean
  ) => {
    if (checked) {
      setCheckedQuestions((prev) => [...prev, question]);
    } else {
      setCheckedQuestions((prev) => prev.filter((q) => q.id !== question.id));
    }
  };
  const [dataToShow, setDataToShow] = useState(data);
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = itemsPerPage;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const currentRecords = dataToShow.slice(firstIndex, lastIndex);
  const nPages = Math.ceil(data.length / recordsPerPage);

  //maxPageNumbers does not include first and last number
  const [maxPageNumbers, setMaxPageNumbers] = useState(
    window.innerWidth < 400 ? 3 : 4
  );

  //Creates responsive website
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 800) {
        setMaxPageNumbers(4);
      }
      if (window.innerWidth < 400) {
        setMaxPageNumbers(3);
      }
      if (window.innerWidth < 340) {
        setMaxPageNumbers(1);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  });

  const handleShow = () => {
    setShow(!show);
  };

  useEffect(() => {
    setShow(addNewQuestion);
  }, [addNewQuestion]);

  return (
    <div className='question-bank'>
      <CustomButton
        id={show ? 'button-offcanvas-open' : 'button-offcanvas-hidden'}
        text={show ? '>' : 'Q'}
        onClick={() => {
          handleShow();
          setCheckedQuestions([]);
        }}
      />

      {checkedQuestions.length > 0 && (
        <CustomButton
          id={'add-button'}
          text={'Add'}
          onClick={() => {
            handleShow();
            sendChecked(checkedQuestions);
            setCheckedQuestions([]);
          }}
        />
      )}

      <Offcanvas placement={'end'} show={show} backdrop={staticBackground}>
        <Offcanvas.Header>
          <Offcanvas.Title>Question Bank</Offcanvas.Title>
        </Offcanvas.Header>

        <Offcanvas.Body>
          <div className='top-bar'>
            <SearchBarQB
              dataToSend={dataToShow}
              data={data}
              setDataToSend={setDataToShow}
            />
            <em style={{ fontSize: 'smaller' }}>
              Result: {currentRecords.length} questions
            </em>
          </div>
          <div className='questions'>
            <Accordion>
              {currentRecords.map(
                (question: questionInQBType, index: number) => (
                  <QuestionInQuestionBank
                    key={index}
                    question={question}
                    idx={index}
                    onChecked={handleQuestionChecked}
                  />
                )
              )}
            </Accordion>
          </div>

          <Pagination
            maxPageNumbers={maxPageNumbers}
            nPages={nPages}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default QuestionBank;
