import React, { useState, useEffect, useRef, useCallback } from 'react';
import SectionListCreate from '../SectionCreate/SectionListCreate';
import SurveyTitleCreate from './SurveyTitleCreate';
import SurveyDescriptionCreate from './SurveyDescriptionCreate';
import './SurveyCreate.css';
import DoneButton from '../Buttons/DoneButton';
import CustomButton from '../Buttons/CustomButton';
import QuestionBank from '../QuestionBank/QuestionBank';
import {
  childEditing,
  questionFromQuestionBank,
  surveyFromSurveyBank,
} from '../../assets/types';
import Select, { MultiValue } from 'react-select';
import processes from '../../assets/process.json';
import {
  getQuestionBank,
  questionInfo,
} from '../../functions/Misc/questionbank';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';
import {
  createSurveyTemp,
  updateSurveyTemp,
  surveyTempUpdate,
} from '../../functions/Template_functions/surveyTemp';
import { getKeywords, keywordExtract } from '../../functions/Misc/keywords';
import { useAuth0 } from '@auth0/auth0-react';
import { Alert } from 'react-bootstrap';

interface Props {
  survey?: surveyFromSurveyBank;
}

const SurveyCreate = ({ survey }: Props) => {
  const { isAuthenticated, user, isLoading } = useAuth0();
  const ownerId = user?.sub;
  const [surveyId, setSurveyId] = useState<string>('-1');
  const [surveyTitle, setSurveyTitle] = useState(survey?.title || '');
  const [surveyTitleEmoji, setSurveyTitleEmoji] = useState(
    survey?.titleEmoji || ''
  );
  const [surveyDescription, setSurveyDescription] = useState(
    survey?.description == undefined ? '' : survey?.description
  );
  const [keywords, setKeywords] = useState<string[]>(
    survey?.keywords == undefined ? [] : survey?.keywords
  );
  const [keywordsDefault, setKeywordsDefault] = useState<string[]>([]);
  const [process, setProcess] = useState<string>(survey?.process || '');
  const [department, setDepartment] = useState<string>(
    survey?.department !== undefined ? survey.department : ''
  );
  const [sectionOrder, setSectionOrder] = useState<string[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [questionsInQB, setQuestionsInQB] = useState<questionInfo[]>([]);
  const [editMode, setEditMode] = useState(survey ? false : true);
  const [addQuestionFromBank, setAddQuestionFromBank] = useState(false);
  const [showQuestionBank, setShowQuestionBank] = useState(false);
  const [questionsFromBank, setQuestionsFromBank] =
    useState<questionFromQuestionBank>();
  const [questionBankSectionId, setQuestionBankSectionId] =
    useState<string>('-1');
  const [created, setCreated] = useState(false);
  const [fetchedKeywords, setFetchedKeywords] = useState<keywordExtract[]>([]);

  const [childIsEditing, setChildIsEditing] = useState<childEditing[]>([]);

  const [noServerResponse, setNoServerResponse] = useState(false);

  // Memoized setter function
  const memoizedSetChildIsEditing = useCallback(
    (id: string, isEditing: boolean) => {
      toggleChildIsEditing(id, isEditing);
    },
    [setChildIsEditing]
  );

  const toggleChildIsEditing = (id: string, isEditing: boolean) => {
    return new Promise((resolve) => {
      let exists: boolean = false;
      const newChildIsEditing: childEditing[] = childIsEditing.map((child) => {
        if (child.id === id) {
          exists = true;
          return {
            id: child.id,
            editing: isEditing,
          };
        } else {
          return child;
        }
      });
      if (exists) {
        setChildIsEditing(newChildIsEditing);
      } else {
        setChildIsEditing([...childIsEditing, { id: id, editing: isEditing }]);
      }
    });
  };

  const navigate = useNavigate();

  const setAddNewQuestionFromBank = (addNew: boolean, id: string) => {
    setAddQuestionFromBank(addNew);
    setShowQuestionBank(addNew);
    setQuestionBankSectionId(id);
  };

  const sendCheckedQuestions = (checkedQuestions: any[]) => {
    setQuestionsFromBank({
      sectionId: questionBankSectionId,
      questions: checkedQuestions,
    });
  };

  const handleAddedQuestion = () => {
    if (addQuestionFromBank === false) {
      setQuestionsFromBank(undefined);
    }
  };

  const handleAddToSurveyBank = () => {
    const survey: surveyTempUpdate = {
      id: surveyId,
      inBank: true,
    };
    updateSurveyTemp(survey).then((result) => {
      setShowModal(false);
      navigate('/surveybank');
    });
  };

  const departments_dummy = [
    'Brännskada',
    'Demensvård',
    'Akuten',
    'Öron näsa hals',
    'BB',
    'Ortopeden',
  ];

  const handleCreateSurvey = () => {
    if (ownerId) {
      const survey = {
        title: surveyTitle,
        titleEmoji: surveyTitleEmoji,
        description: surveyDescription,
        department: department,
        creator: ownerId,
        sectionOrder: [],
        process: process,
      };
      createSurveyTemp(survey).then((result: string) => {
        if (result === undefined) {
          setNoServerResponse(true);
          return;
        }
        const survey_id = result;
        setSurveyId(survey_id);
        setCreated(true);
        const updateSurvey: surveyTempUpdate = {
          id: result,
          keywords: keywords,
        };
        updateSurveyTemp(updateSurvey);
      });
    }
  };

  function createOptionsList(
    strings: string[]
  ): { label: string; value: string }[] {
    if (!strings || strings[0] === '')
      return [] as { label: string; value: string }[];
    return strings.map((string) => ({ label: string, value: string }));
  }

  function createOptionsListKeywords(list: keywordExtract[]) {
    const newList = list.map((obj) => {
      const tempVal: string = obj.value as string;
      const tempId: string = obj.id as string;
      return { value: tempId, label: tempVal };
    });
    return newList;
  }

  const handleSelectKeywordsChange = (
    value: MultiValue<{ label: string; value: string }>
  ) => {
    let keywords: string[] = [];
    let defaultKeywords: string[] = [];

    value.map(
      (option: { label: string; value: string }) => (
        keywords.push(option.value), defaultKeywords.push(option.label)
      )
    );

    setKeywords(keywords);
    setKeywordsDefault(defaultKeywords);
  };

  const handleSelectProcessChange = (value: any) => {
    if (value !== null) {
      setProcess(value.value);
    } else {
      setProcess('');
    }
  };

  const handleSelectDepartmentChange = (value: any) => {
    if (value !== null) {
      setDepartment(value.value);
    } else {
      setDepartment('');
    }
  };

  const handleDone = () => {
    setEditMode(false);
    if (!created) {
      setCreated(true);
    }
  };

  const handleFetchQuestionBank = () => {
    getQuestionBank().then((result) => {
      setQuestionsInQB(result);
    });
  };

  useEffect(() => {
    handleAddedQuestion();
  }, [addQuestionFromBank]);

  const previousValues = useRef({
    created,
    surveyTitle,
    surveyDescription,
    sectionOrder,
    keywords,
    process,
    department,
  });

  useEffect(() => {
    if (
      previousValues.current.created !== created &&
      previousValues.current.surveyTitle !== surveyTitle &&
      previousValues.current.surveyDescription !== surveyDescription
    ) {
      handleCreateSurvey();
      previousValues.current = {
        created,
        surveyTitle,
        surveyDescription,
        sectionOrder,
        keywords,
        process,
        department,
      };
    }
  });

  useEffect(() => {
    if (created &&
      previousValues.current.created === created &&
      (previousValues.current.surveyTitle !== surveyTitle ||
        previousValues.current.surveyDescription !== surveyDescription ||
        previousValues.current.sectionOrder !== sectionOrder ||
        previousValues.current.keywords !== keywords ||
        previousValues.current.process !== process ||
        previousValues.current.department !== department)
    ) {
      const survey: surveyTempUpdate = {
        id: surveyId,
        title: surveyTitle,
        description: surveyDescription,
        sectionOrder: sectionOrder,
        keywords: keywords,
        process: process,
        department: department,
      };
      updateSurveyTemp(survey);
      previousValues.current = {
        surveyTitle,
        surveyDescription,
        sectionOrder,
        created,
        keywords,
        process,
        department,
      };
    }
  }, [editMode, surveyTitle, surveyDescription, sectionOrder]); //TO DO kanske lägg till fler grejer här?

  const createdRef = useRef(false);

  useEffect(() => {
    const createSurvey = () => {
      if (survey && !createdRef.current) {
        createdRef.current = true;
        handleCreateSurvey();
        previousValues.current = {
          created: true,
          surveyTitle,
          surveyDescription,
          sectionOrder,
          keywords,
          process,
          department,
        };
      }
    };
    createSurvey();
  }, [survey, handleCreateSurvey]);

  useEffect(() => {
    getKeywords().then((res) => {
      if (res !== undefined) setFetchedKeywords(res);
    });
    getQuestionBank().then((result) => {
      setQuestionsInQB(result);
    });

    handleFetchQuestionBank();
  }, []);

  useEffect(() => {}, [childIsEditing]);

  const alertRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (noServerResponse && alertRef.current) {
      alertRef.current.focus();
    }
  }, [noServerResponse]);

  if (isLoading) {
    return <div>Loading ...</div>;
  }
  if (!isAuthenticated || !user) {
    return <div>User is not logged in.</div>;
  }

  return (
    <>
      <div tabIndex={0} ref={alertRef}>
        {noServerResponse ? (
          <Alert
            variant='danger'
            onClose={() => setNoServerResponse(false)}
            dismissible
          >
            <Alert.Heading>
              Connection Error: Unable to reach the server
            </Alert.Heading>
            <p>
              You are currently not connected to the server. Please check your
              internet. Any changes to this survey wont be saved until
              connection is restored. All prior changes to this template can be
              found in{' '}
              <Alert.Link href='/mycreatedtemplates'>
                my created templates
              </Alert.Link>
              . To continue working on this survey, create a copy and remove the
              original when the connection is reestablished.
            </p>
          </Alert>
        ) : null}
      </div>
      <QuestionBank
        itemsPerPage={10}
        data={questionsInQB}
        staticBackground={true}
        sendChecked={sendCheckedQuestions}
        show={showQuestionBank}
        setShow={setShowQuestionBank}
        addNewQuestion={addQuestionFromBank}
      />
      <div>
        <SurveyTitleCreate
          title={surveyTitle}
          toggleChangedTitle={setSurveyTitle}
          edit={editMode}
        />
        <SurveyDescriptionCreate
          description={surveyDescription}
          edit={editMode}
          toggleChangedDescription={setSurveyDescription}
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'end',
          }}
        >
          {!editMode ? (
            <CustomButton text='Edit' onClick={() => setEditMode(true)} />
          ) : (
            <div className='create-survey-select-and-done-btn' style={{}}>
              <div className='keywordsDiv'>
                <div className='custom-select'>
                  <Select
                    classNamePrefix='react-select'
                    placeholder='Keywords'
                    defaultValue={createOptionsList(keywordsDefault)} //{createOptionsList(keywords)}  gamla
                    options={createOptionsListKeywords(fetchedKeywords)} //createOptionsList(keywordsDummy.keywords)} //byt ut itll hämtad
                    isMulti
                    isClearable
                    isSearchable
                    onChange={(value) => {
                      handleSelectKeywordsChange(value);
                    }}
                  />
                </div>
                <div className='custom-select'>
                  <Select
                    classNamePrefix='react-select'
                    placeholder='Process'
                    defaultValue={createOptionsList([process])}
                    options={createOptionsList(processes.process)}
                    isClearable
                    isSearchable
                    onChange={(value) => {
                      handleSelectProcessChange(value);
                    }}
                  />
                </div>
                <div className='custom-select'>
                  <Select
                    classNamePrefix='react-select'
                    placeholder='Department'
                    defaultValue={createOptionsList([department])}
                    options={createOptionsList(departments_dummy)}
                    isClearable
                    isSearchable
                    onChange={(value) => {
                      handleSelectDepartmentChange(value);
                    }}
                  />
                </div>
              </div>
              <div className='create-survey-done-btn'>
                <DoneButton onClick={handleDone} text='Done' />
              </div>
            </div>
          )}
        </div>

        <hr />
      </div>
      {created && (
        <SectionListCreate
          setNoServerResponse={setNoServerResponse}
          setChildIsEditing={memoizedSetChildIsEditing}
          childrenEditing={childIsEditing}
          handleFetchQuestionBank={handleFetchQuestionBank}
          setAddNewQuestionFromBank={setAddNewQuestionFromBank}
          questionsFromBank={questionsFromBank}
          surveyId={surveyId}
          sectionsFromTemplate={survey?.sections}
          sectionOrder={sectionOrder}
          setSectionOrder={setSectionOrder}
          created={created}
        />
      )}
      <div
        style={{
          display: 'flex',
          justifyContent: 'end',
          padding: '15px',
          overflow: 'hidden',
        }}
      >
        <DoneButton
          text='Save Survey'
          disabled={editMode || childIsEditing.some((e) => e.editing)}
          onClick={() => {
            setShowModal(true); //här skka vi kalla på answerSendInSurvey
          }}
        />
        <Modal show={showModal} onHide={() => setShowModal(false)} centered>
          <Modal.Header closeButton>
            <Modal.Title>Successfully created survey</Modal.Title>
          </Modal.Header>
          <Modal.Body>Add survey to survey bank?</Modal.Body>
          <Modal.Footer>
            <CustomButton text='Yes' onClick={handleAddToSurveyBank} />
            <CustomButton
              text='No'
              onClick={() => {
                setShowModal(false);
                navigate('/mycreatedtemplates');
              }}
            />
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default SurveyCreate;
