import createClient from "../axios";
//Variable "data" is a JSON object - The object consists different things based on the function
//Variable "id" is an ID - The ID depends on either the id of the section you want to get or the survey you want to get.

export type sectionSent ={
    title: string;
    titleEmoji?: string;
    description: string;
    completed: boolean;
    questionOrder: string[];
    surveyId: string;
    staffId?: string;
}

export type sectionSentUpdate = {
    id: string;
    title?: string;
    titleEmoji?: string;
    description?: string;
    completed?: boolean;
    completedDate?: Date | null;
    staffId?: string;
    questionOrder?: string[];
}

export type sectionSentExtract = {
    id: string; 
    title: string;
    titleEmoji?: string;
    description: string;
    complete: boolean;
    completedDate: Date | null;
    staffID?: string;
    questionOrder: string[];
    surveyId: string;
} 

/**
 * Creates a sent section
 * @param data, data for creating the section
 * @returns string, the id of the sent section.
 */
export async function createSectionSent(data: sectionSent):Promise<string> {
    const client = await createClient();
    return client.post('/sectionsent/', data) //data is a json object
        .then((response) => {
            return response.data.id;
        })
        .catch((error) => console.log(error));
}

/**
 * gets a sent section by Id. 
 * @param id the id of the section
 * @returns type sectionSentExtract, a sections data.
 */
export async function getSectionSentById(id: string):Promise<sectionSentExtract> {
    const client = await createClient();
    return client.get('/sectionsent/' + id) //id is the section id
        .then((response) => {
            return response.data;
        })
        .catch((error) => console.log(error));
}

/**
 * Updates a sent section
 * @param data sectionSentUpdate, for updating the section
 * @returns string, the id of the updated section
 */
export async function updateSectionSent(data: sectionSentUpdate):Promise<string> {
    const client = await createClient();
    return client.put('/sectionsent/' + data.id, data) //data is a json object
        .then((response) => {
            return response.data.id;
        })
        .catch((error) => console.log(error));
}

/**
 * Deletes a sent section
 * @param id the id of the section to be deleted
 * @returns string, the id of the deleted section
 */
export async function deleteSectionSent(id: string):Promise<string> {
    const client = await createClient();
    return client.delete('/sectionsent/' + id) //data is a json object
        .then((response) => {
            return response.data.id;
        })
        .catch((error) => console.log(error));
}

/**
 * Gets all sections belonging to a survey.
 * @param surveyId the id of the survey
 * @returns type ssectionSentExtract[], an array containing all sections.
 */
export async function getSectionSentBySurveyId(surveyId: string):Promise<sectionSentExtract[]> {
    const client = await createClient();
    return client.get('/sectionsent/survey/' + surveyId, {params: {
        id: surveyId
    }}) //id is the survey id
        .then((response) => {
            return response.data;
        })
        .catch((error) => console.log(error));
}

/**
 * Gets all finished sent sections belonging to a survey
 * @param surveyId the id of the survey
 * @returns sectionSentExtract[], an array of sections.
 */
export async function getSectionSentFinished(surveyId: string): Promise<sectionSentExtract[]> {
    const client = await createClient();
    return client.get('/sectionsent/complete/' + surveyId, {params: {
        id: surveyId
    }})
    .then((response) => {
        return response.data;
    })
    .catch((error) => console.log(error));
}
