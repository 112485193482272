import React, { useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import './SectionCreate.css';

interface Props {
  title: string;
  edit: boolean;
  toggleChangedTitle: (title: string) => void;
}

const SectionTitleCreate = ({ title, edit, toggleChangedTitle }: Props) => {
  const [sectionTitle, setSectionTitle] = useState(title);
  useEffect(() => {
    if (sectionTitle.length === 0 && edit === false) {
      setSectionTitle('Untitled Section');
      toggleChangedTitle('Untitled Section');
    } else {
      toggleChangedTitle(sectionTitle);
    }
  }, [edit]);
  const enterHandler = (event: any) => {
    if (event.key === 'Enter') {
      toggleChangedTitle(sectionTitle);
    }
  };

  return (
    <Row className={!edit ? 'sectionTitleDiv' : 'sectionTitleDivEdit'}>
      {!edit ? (
        <p>{title}</p>
      ) : (
        <input
          type='text'
          placeholder='Section title'
          value={sectionTitle}
          className='sectionTitleInput'
          onChange={(e) => setSectionTitle(e.target.value)}
          onKeyUp={enterHandler}
        />
      )}
    </Row>
  );
};

export default SectionTitleCreate;
