import Accordion from 'react-bootstrap/Accordion';
import SectionCreate from './SectionCreate';
import { useState, useEffect, useRef } from 'react';
import { plusSignRound } from '../../assets/Icons';
import {
  sectionFromSurveyBank,
  questionFromQuestionBank,
  sectionType,
  questionTypeRetrive,
  childEditing,
} from '../../assets/types';
import {
  createSectionTemp,
  sectionTempCreate,
  updateSectionTemp,
  sectionTempUpdate,
} from '../../functions/Template_functions/sectionTemp';
import { removeSectionTempAndQuestions } from '../../functions/Template_functions/tempUtils';

interface Props {
  surveyId: string;
  created: boolean;
  questionsFromBank?: questionFromQuestionBank;
  setAddNewQuestionFromBank: (addNew: boolean, id: string) => void;
  sectionOrder: string[];
  setSectionOrder: (order: string[]) => void;
  sectionsFromTemplate?: sectionFromSurveyBank[];
  handleFetchQuestionBank: () => void;
  setChildIsEditing: (id: string, isEditing: boolean) => void;
  childrenEditing: childEditing[];
  setNoServerResponse: (noResponse: boolean) => void;
}

const SectionListCreate = ({
  surveyId,
  created,
  questionsFromBank,
  setAddNewQuestionFromBank,
  sectionOrder,
  setSectionOrder,
  sectionsFromTemplate,
  setChildIsEditing,
  handleFetchQuestionBank,
  childrenEditing,
  setNoServerResponse,
}: Props) => {
  //DATA FROM DATABASE
  //states

  const [sections, setSections] = useState<sectionType[]>([]);
  const [sectionsOrdered, setSectionsOrdered] = useState<sectionType[]>([]);

  const [activeKeys, setActiveKeys] = useState<string[]>([]);

  const [addingNewSection, setAddingNewSection] = useState(false);

  const [questionsFromTemplate, setQuestionsFromTemplate] = useState<
    { questions: questionTypeRetrive[]; sectionId: string }[]
  >([]);

  //title & description

  //meta data

  //answer choices

  //collapsible

  //functions

  const handleToggle = (eventKey: string) => {
    if (activeKeys.includes(eventKey)) {
      setActiveKeys(activeKeys.filter((key) => key !== eventKey));
    } else {
      setActiveKeys([...activeKeys, eventKey]);
    }
  };

  const handleUpdateSection = (updatedSection: sectionType) => {
    const id = updatedSection.id;
    updateSectionTemp(updatedSection as sectionTempUpdate).then((updated) => {
      if (updated === undefined) {
        setNoServerResponse(true);
        return;
      }
      if (updated !== id) {
        console.log('something went terribly wrong here!!!!');
      }
    });
    const updatedSectionList = sections.map((section) => {
      if (section.id === id) {
        return updatedSection;
      } else {
        return section;
      }
    });
    setSections(updatedSectionList);
  };

  const handleCreateSection = (
    title?: string,
    titleEmoji?: string,
    description?: string,
    questionOrder?: string[],
    questions?: questionTypeRetrive[]
  ) => {
    const sectionUpdate: sectionTempCreate = {
      title: title ? title : '',
      titleEmoji: titleEmoji ? titleEmoji : '',
      description: description ? description : '',
      surveyId: surveyId,
      questionOrder: [],
    };

    createSectionTemp(sectionUpdate).then((section_id) => {
      if (section_id === undefined) {
        setNoServerResponse(true);
        return;
      }
      setAddingNewSection(true);
      setChildIsEditing(section_id, true);
      const newSection = { ...sectionUpdate, id: section_id } as sectionType;
      setSections([...sections, newSection]);
      setActiveKeys([...activeKeys, newSection.id]);
      setSectionOrder([...sectionOrder, newSection.id]);
      if (questions) {
        setQuestionsFromTemplate([
          ...questionsFromTemplate,
          { questions: questions, sectionId: section_id },
        ]);
      }
    });
  };

  const deleteSection = (sectionId: string) => {
    removeSectionTempAndQuestions(sectionId).then((deleted) => {
      if (deleted === undefined) {
        setNoServerResponse(true);
        return;
      }
      //TODO remove
      if (!deleted) {
        console.log('error deleting section');
      }
    });
    const updatedSection = sections.filter(
      (section) => section.id !== sectionId
    );
    setSections(updatedSection);
    setSectionOrder(sectionOrder.filter((id) => id !== sectionId));
  };

  const moveSection = (sectionId: string, newIndex: number) => {
    const newSectionOrder = sectionOrder.filter((id) => id !== sectionId);
    newSectionOrder.splice(newIndex, 0, sectionId);
    setSectionOrder(newSectionOrder);
  };

  useEffect(() => {
    setSectionsOrdered(
      sections.length
        ? sectionOrder.map(
            (id) => sections.find((obj) => obj.id === id) as sectionType
          )
        : []
    );
  }, [sectionOrder]);

  const handleCreateSectionsFromTemplate = () => {
    if (sectionsFromTemplate === undefined) return;
    const addedSections: sectionType[] = [];
    const orderPairs: { oldId: string; newId: string }[] = [];
    const questionsAdded: {
      questions: questionTypeRetrive[];
      sectionId: string;
    }[] = [];
    const promises = sectionsFromTemplate.map((section) => {
      const sectionUpdate: sectionTempCreate = {
        title: section.title ? section.title : '',
        titleEmoji: section.titleEmoji ? section.titleEmoji : '',
        description: section.description ? section.description : '',
        surveyId: surveyId,
        questionOrder: section.questionOrder ? section.questionOrder : [],
      };
      orderPairs.push({ oldId: section.id, newId: '' });
      return createSectionTemp(sectionUpdate).then((section_id) => {
        if (section_id === undefined) {
          setNoServerResponse(true);
          return;
        }
        const newSection = { ...sectionUpdate, id: section_id } as sectionType;
        addedSections.push(newSection);
        const index = orderPairs.findIndex((pair) => pair.oldId === section.id);
        orderPairs[index] = { oldId: section.id, newId: section_id };
        if (section.questions) {
          questionsAdded.push({
            questions: section.questions,
            sectionId: section_id,
          });
        }
      });
    });
    Promise.all(promises).then(() => {
      const addedOrder: string[] = orderPairs.map((pair) => pair.newId);
      setSections([...sections, ...addedSections]);
      setSectionOrder([...sectionOrder, ...addedOrder]);
      setQuestionsFromTemplate(questionsAdded);
    });
  };

  const hasEffectRun = useRef(false);

  useEffect(() => {
    if (!hasEffectRun.current && sectionsFromTemplate) {
      handleCreateSectionsFromTemplate();
      hasEffectRun.current = true;
    }
  }, []);

  useEffect(() => {}, [sections]);

  useEffect(() => {}, [sectionsOrdered]);

  return (
    <div style={{ overflow: 'hidden' }}>
      <Accordion alwaysOpen activeKey={activeKeys}>
        <div>
          {sectionsOrdered.map((section: sectionType, index) => {
            return (
              <SectionCreate
                setNoServerResponse={setNoServerResponse}
                setChildIsEditing={setChildIsEditing}
                childrenEditing={childrenEditing}
                handleFetchQuestionBank={handleFetchQuestionBank}
                surveyId={surveyId}
                section={section}
                questionsFromTemplate={
                  questionsFromTemplate.find(
                    (obj) => obj.sectionId === section.id
                  )?.questions
                }
                handleUpdateSection={handleUpdateSection}
                moveSection={moveSection}
                currentSectionIndex={index}
                sectionsTotal={sections.length}
                key={index}
                deleteMe={deleteSection}
                handleToggle={handleToggle}
                newSection={
                  addingNewSection && index === sections.length - 1
                    ? true
                    : false
                }
                setAddNewQuestionFromBank={setAddNewQuestionFromBank}
                questionsFromBank={
                  questionsFromBank?.sectionId === section.id
                    ? questionsFromBank
                    : undefined
                }
              />
            );
          })}
          {created && (
            <button
              className='invisibleButton'
              onClick={() => {
                handleCreateSection();
              }}
            >
              {plusSignRound}
            </button>
          )}
        </div>
      </Accordion>
    </div>
  );
};

export default SectionListCreate;
